import {
  useMapGetters,
  useMapMutation,
  useMapActions,
} from 'public/src/pages/goods_detail_v2/compositions/vuex.composition.js'
import ProductRecommendDrawer from 'public/src/pages/goods_detail_v2/components/ProductRecommendDrawer/index.js' // TODO 懒加载
import { useMapState } from '@detail/store/mapHook.js'

const useCheckAddToGroup = () => {
  // 这个 HOOK 后续可以给商详其他场景直接使用
  // 因为数据源大多是推荐相关，所以不可以混入到我们 AddBtn Vuex 中

  const { exposedFilterList, recommendCountData } = useMapState('', [
    'exposedFilterList',
    'recommendCountData',
  ])
  const {
    recommendDrawerProducts,
    currentDrawerProducts,
    isShowAddMore,
    getEstimatedInfo,
    discountPercent,
    isShowCountdown,
    bestEndTime,
    isHitComplianceMode
  } = useMapGetters([
    'recommendDrawerProducts',
    'currentDrawerProducts',
    'isShowAddMore',
    'getEstimatedInfo',
    'discountPercent',
    'isShowCountdown',
    'bestEndTime',
    'isHitComplianceMode'
  ])
  const { updateRecommendCountData } = useMapMutation([
    'updateRecommendCountData',
  ])
  const {
    getAlsoViewRecommendlist,
    autoGetCouponAtAddBag,
    showLoginModal,
    getAddMoreRecommendlist,
  } = useMapActions([
    'showLoginModal',
    'getAlsoViewRecommendlist',
    'autoGetCouponAtAddBag',
    'getAddMoreRecommendlist',
  ])

  const checkAddToGroupCallBack = async (
    { goods_id, showAddWishAfterRec, language, from = 'GoodsLike' },
    { state, next }
  ) => {
    if (!showAddWishAfterRec) return next()
    const isShowAddGroup = state.show
    await getAlsoViewRecommendlistFunc()
    if (!recommendDrawerProducts.value.length) return next()

    ProductRecommendDrawer?.showModal?.({
      from,
      products: recommendDrawerProducts.value,
      language: language.value,
      currentGoodsId: goods_id,
      isShowAddGroup,
      exposedFilterList: exposedFilterList.value,
      fetchRecData: getAlsoViewRecommendlistFunc,
      setNoInteractiveTimes,
      config: {
        isHitComplianceMode: isHitComplianceMode.value,
      },
      handleOpenLogin: addToWish => {
        window?.QuickViewInstance?.hide?.() // 隐藏快速加车
        showLoginModal({
          type: 'qv-add-wish',
          originRef: {
            addToWish,
          },
        })
      },
      // 快加车点击商品后隐藏推荐弹窗
      onProductClick: () => {
        ProductRecommendDrawer?.hideModal?.()
      },
      autoGetCouponAtAddBag: options => {
        autoGetCouponAtAddBag(options)
      },
    })
  }

  const openRecommendDrawer = async (
    { isAddBagPopup, res, language, goods_id, from = 'AddCart' },
    { callbacks }
  ) => {
    const hitAddBagPopup =
      isAddBagPopup && recommendCountData.value.addCartRecommendCount < 3
    if (hitAddBagPopup && !currentDrawerProducts.value?.length) {
      await getRecommendList()
    }
    if (hitAddBagPopup && currentDrawerProducts.value?.length) {
      const addCartItem = res?.info?.info?.carts?.find(
        item => item.id === res?.info?.cart?.id
      )
      ProductRecommendDrawer?.showModal?.({
        from,
        products: currentDrawerProducts.value,
        language: language,
        currentGoodsId: goods_id,
        exposedFilterList: exposedFilterList.value,
        addCartItem,
        config: {
          isShowAddMore: isShowAddMore.value,
          estimatedPrice: getEstimatedInfo.value?.value?.amountWithSymbol,
          thresholdValue: getEstimatedInfo.value?.thresholdValue,
          discountPercent: discountPercent.value,
          isShowCountdown: isShowCountdown.value,
          bestEndTime: bestEndTime.value,
          isHitComplianceMode: isHitComplianceMode.value,
        },
        fetchRecData: getRecommendList,
        handleOpenLogin: addToWish => {
          window?.QuickViewInstance?.hide?.() // 隐藏快速加车
          showLoginModal({
            type: 'qv-add-wish',
            originRef: {
              addToWish,
            },
          })
        },
        setNoInteractiveTimes,
      })
      callbacks?.hasRecommendCallback?.()
    } else {
      callbacks?.notRecommendCallback?.()
    }
    // 通知 recomendList 更新
    import('public/src/pages/goods_detail_v2/utils/eventBus.js').then(
      ({ default: eventBus }) => {
        eventBus.emit('addBagToRefreshRecomendList')
      }
    )
  }

  /**
   * 获取你可能喜欢
   * @param {*} pageNum
   * @returns
   */
  const getAlsoViewRecommendlistFunc = async (pageNum = 1) => {
    return await getAlsoViewRecommendlist(pageNum)
  }

  /**
   * 获取推荐商品
   * @param {*} pageNum
   * @returns
   */
  const getRecommendList = async (pageNum = 1) => {
    if (isShowAddMore.value) {
      return await getAddMoreRecommendlist(pageNum)
    } else {
      return await getAlsoViewRecommendlist(pageNum)
    }
  }

  const setNoInteractiveTimes = (times = 0) => {
    updateRecommendCountData({
      addWishRecommendCount: times,
    })
    return times
  }

  return {
    checkAddToGroupCallBack,
    openRecommendDrawer,
  }
}

export default useCheckAddToGroup
