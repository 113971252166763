<template>
  <div
    class="source-srs__container"
    v-html="content"
  ></div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
export default {
  name: 'ProductIntroCottonSourceSRS',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleOpened() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-89',
      })
    },
  },
}
</script>

<style lang="less">
.source-srs__container {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #222222;
  :deep(a) {
    cursor: pointer;
    color: @sui_color_link;
  }
}
</style>
