<template>
  <div
    v-if="isShow"
    v-expose="{ id: '2-21-1', code: 'outfitCode' }"
    class="outfit-recommend__container"
    :style="renderContentStyle"
  >
    <SPopover
      v-model="showNewUserTips"
      placemen="left"
      :content="language.SHEIN_KEY_PC_23526"
      hide-close-icon
      :append-to-body="false"
      :outside-close="false"
      trigger="unset"
    >
      <template #reference>
        <div
          class="outfit-recommend__btn"
          @click="openOutfits"
        >
          <span class="outfit-recommend__text">{{
            language.SHEIN_KEY_PC_23528
          }}</span>
        </div>
      </template>
    </SPopover>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-21' })
export default {
  name: 'OutfitRecommend',
  directives: { expose },
  data() {
    return {
      showNewUserTips: false,
    }
  },
  computed: {
    ...mapState(['productIntroData', 'language']),
    ...mapGetters(['showBlackFridayBelt']),
    outfitData() {
      return this.productIntroData?.outfitsImg
    },
    isShow() {
      return !!this.outfitData?.labels?.length
    },
    renderContentStyle() {
      const { showBlackFridayBelt: hasCccBeltComponent } = this
      if (hasCccBeltComponent) {
        return {
          bottom: '55px'
        }
      }
      return {}
    }
  },
  mounted() {
    this.outfitData?.newUserTips && this.getNewUserStatus()
  },
  methods: {
    ...mapMutations(['assignState']),
    openOutfits() {
      this.assignState({ showOutfit: true, renderOutfitDialog: true })
      daEventCenter.triggerNotice({ daId: '2-21-3' })
    },
    getNewUserStatus() {
      const hasShow = localStorage.getItem('OUTFIT_NEW_USER')
      if (hasShow) return
      this.showNewUserTips = !!this.outfitData?.newUserTips // abt
      daEventCenter.triggerNotice({ daId: '2-21-2' })
      setTimeout(() => {
        this.showNewUserTips = false
        localStorage.setItem('OUTFIT_NEW_USER', true)
      }, 5000)
    },
  },
}
</script>

<style lang="less">
.outfit-recommend {
  &__container {
    position: absolute;
    bottom: 36px;
    right: 10px;
    white-space: nowrap;
    cursor: pointer;
    z-index: @zindex-hack;
  }
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 19px;
  }
  &__text {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: @sui_color_gray_dark1;
  }
}
</style>
