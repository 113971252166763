<template>
  <div
    ref="wrapBox"
    class="product-intro__attr-wrap"
  >
    <div
      v-if="detailGoodsDesc.html && detailGoodsDesc.pos == idx"
      ref="attrDesBox"
      class="product-intro__attr-des"
      tabindex="0"
      :class="{ more: desMore || desMore === null }"
    >
      <span
        ref="attrDes"
        v-html="detailGoodsDesc.html"
      ></span>
      <span
        v-show="desMore"
        class="product-intro__attr-des-more"
        @click="changeDesMore"
      >...<span>{{ language.SHEIN_KEY_PC_19640 }}</span></span>
    </div>

    <!-- 属性图标集合 -->
    <template v-if="imgAttrSet.length">
      <PrdImageAttrGroup
        v-for="(group, index) in imgAttrSet"
        :key="index"
        :group="group"
        :parent-box-width="wrapBoxWith"
      />
    </template>

    <!-- evoluSHEIN提示框-带图标特殊属性后 -->
    <EvoluSheinBar v-if="showEvoluFloor[0]" />
    <div 
      :class="{
        'product-intro__attr-list-text': true,
        'product-intro__attr-list-textMargin': hasMargin,
      }"
    >
      <div
        v-for="item in content.textAttr"
        :key="item.name"
        class="product-intro__attr-list-textli"
      >
        <div class="product-intro__attr-list-textname">
          {{ item.name }}:
        </div>
        <div class="product-intro__attr-list-textval">
          {{ item.value }}
          <span dir="ltr">{{ calcValue(item) }}</span>
        </div>
      </div>
    </div>
    <!-- evoluSHEIN提示框-纯文本特殊属性后 -->
    <EvoluSheinBar
      v-if="showEvoluFloor[1]"
      class="product-intro__attr-evolu"
    />
    <slot></slot>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import EvoluSheinBar from './EvoluSheinBar'
import PrdImageAttrGroup from './PrdImageAttrGroup'
import { contentIncludeSpecialFeatures } from '../utils/productDetail'

export default {
  name: 'ProductIntroDetailGoodsDesc',
  components: {
    EvoluSheinBar,
    PrdImageAttrGroup,
  },
  props: {
    content: {
      type: Object,
      default() {
        return {}
      },
    },
    idx: {
      type: [Number, String],
      default: -1,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      desMore: null,
      wrapBoxWith: 0,
      lifeHandler: null,
    }
  },
  computed: {
    ...mapState(['productIntroData', 'language', 'showHeelHeight', 'cmInchInfo']),
    ...mapGetters(['pageComponents', 'screenAbtConfig', 'isEvolusheinTag']),
    hasMargin() {
      return this.showEvoluFloor[0] || this.imgAttrSet?.length || (this.detailGoodsDesc.html && this.detailGoodsDesc.pos == this.idx)
    },
    detailGoodsDesc() {
      return this.pageComponents.descPosConf.detailGoodsDesc
    },
    calcValue() {
      return (item) => {
        if(this.showHeelHeight) {
          let dimensionAttrInfo = this.productIntroData?.sizeInfoDes?.dimensionAttrInfo ?? []
          const heelHeightMap = dimensionAttrInfo.find(item => { // {attr_name:"Heel Height"attr_name_en:"Heel Height"},通过attr_name的值（多语言）去尺码表拿数据
            return item?.attr_name_en?.includes('Heel Height')
          })
          if(heelHeightMap?.attr_name?.includes(item.name)) {
            return  this.cmInchInfo
          }
        }
        return ''
      }
    },
    // 判断evolushein楼层展示
    showEvoluFloor() {
      const { content = {} } = this
      const { imgAttr = [], textAttr = [] } = content
      let hasSpecialFeature = contentIncludeSpecialFeatures(imgAttr)
      let hasTextSpecialFeature = contentIncludeSpecialFeatures(textAttr)
      // 数组第一项表示带图标特殊属性
      // 数组第二项表示纯文本的特殊属性
      return [
        hasSpecialFeature && this.isEvolusheinTag,
        !hasSpecialFeature && hasTextSpecialFeature && this.isEvolusheinTag
      ]
    },
    // 转化的属性集合
    imgAttrSet() {
      const { content } = this
      const { imgAttr } = content
      if (Array.isArray(imgAttr)) {
        return imgAttr
      }

      return []
    },
  },
  watch: {
    isShow: {
      immediate: true,

      handler(val) {
        if (val && this.desMore === null) {
          this.checkHeight()
        }
        this.$nextTick(() => {
          this.updateBoxWidth()
        })
      },
    },
  },
  mounted() {
    const { updateBoxWidth } = this
    updateBoxWidth()

    // 句柄不需要更新，用一个不会更新的匿名函数存储
    // 组件注销时只会清除这个匿名函数
    this.lifeHandler = () => {
      updateBoxWidth()
    }
    window.addEventListener('resize', this.lifeHandler = () => {
      updateBoxWidth()
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.lifeHandler)
  },
  methods: {
    changeDesMore() {
      this.desMore = !this.desMore
    },
    checkHeight() {
      this.$nextTick(() => {
        if (this.detailGoodsDesc.html && this.detailGoodsDesc.pos == this.idx) {
          this.desMore =
            this.$refs.attrDes.offsetHeight > this.$refs.attrDesBox.offsetHeight
              ? true
              : null
        }
      })
    },
    updateBoxWidth() {
      const wrapBox = this.$refs?.wrapBox
      if (wrapBox) {
        this.wrapBoxWith = wrapBox.scrollWidth
      }
    },
  },
}
</script>

<style lang="less">
.product-intro {
  &__attr-des {
    position: relative;
    margin-bottom: 15px;
    color: #222;
    font-size: 12px;
    line-height: 14px;
    &.more {
      .clamp(3);
    }
  }
  &__attr-des-more {
    position: absolute;
    background: white;
    bottom: 0;
    right: 0;
    cursor: pointer;
    span {
      color: #2d68a8;
    }
  }

  &__attr-list-text {
    display: table;
  }
  &__attr-list-textMargin {
    margin-top: 16px;
  }
  &__attr-list-textli {
    display: table-row;
    color: #222;
    font-size: 12px;
    line-height: 20px;
  }
  &__attr-list-textname {
    display: table-cell;
    width: 114px;
    padding: 0 20px 0 0;
    vertical-align: bottom;
  }
  &__attr-list-textval {
    display: table-cell;
    vertical-align: bottom;
  }
  &__attr-evolu{
    margin-top: 16px;
  }
}
</style>
