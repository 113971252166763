<template>
  <span
    v-if="flashDiscount"
    class="sign-board-flash__discount"
  >
    <Icon
      name="sui_icon_flashsale_24px"
      size="14"
    />
    {{ flashDiscount }}
  </span>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'FlashProductDiscount',
  components: {
    Icon,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    flashDiscount() {
      return this.item?.pretreatInfo?.discountInfo?.unitDiscountString || 0
    },
    
  },
}
</script>

<style lang="less">
.sign-board-flash {
  &__discount {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FACF19;
    color: #000;
    font-size: 11px;
    font-weight: 400;
  }
  
}
[mir=rtl] {
  .sign-board-flash__discount {
    direction: ltr;
  }
}
</style>
