<template>
  <!-- 顶部氛围信息 -->
  <div
    :id="customerConfig.showBuyNow ? 'ProductDetailAddBtnForFloorPrice' : '' "
    class="product-intro__add"
  >
    <div class="she-clearfix product-intro__add-wrap">
      <div
        class="product-intro__add-status"
        :class="{ fixed: addFixed && customerConfig.needFix }"
        :style="customerConfig.needFix ? renderAddWidth : {}"
      >
        <div
          class="btn-left"
          :style="{ 'max-width': customerConfig.showAddWish ? 'calc(100% - 54px - 17px)' : '' }"
        >
          <template v-if="customerConfig.showTopTips">
            <TopTips
              :top-config="{
                showErrorTips: customerConfig.showErrorTips,
                freeShippingButtonTypeText,
                showFreeshippingButtonType: config.isButtonFreeStyle,
              }"
              :error-tips-combo="errorTipsCombo"
            />
          </template>

          <!-- ADD BTN MAIN -->
          <ProductIntroCommonBtn
            :content-config="contentConfig"
            :content-combo="contentCombo"
            :content-text="contentText"
            :validate-params="validateParams"
            :customer-analysis="customerAnalysis"
            :customer-config="customerConfig"
            :base-info="baseInfo"
            @common-events="btnEventCenterCallback"
          />
        </div>
        <div class="btn-right">
          <template v-if="customerConfig.showAddWish">
            <ProductIntroSaveBtn />
          </template>
        </div>
      </div>
    </div>

    <div
      v-if="isBuyNow && customerConfig.showBuyNow"
      class="product-intro__add-buyNow"
      :style="{ width: customerConfig.showAddWish ? 'calc(100% - 54px - 17px)' : '' }"
      @click="clickBuyNow"
    >
      <div
        v-show="buyNowBtnLoading"
        class="la-ball-pulse la-ball-pulse-black"
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div v-show="!buyNowBtnLoading">
        {{ language.SHEIN_KEY_PC_30206 || 'BUY NOW' }}
      </div>
    </div>

    <template v-if="customerConfig.showBottomTips">
      <BottomTips
        :config="config"
        :language="language"
        :reward-points="rewardPoints"
        :integral-tips="integralTips"
      />
    </template>
    <!-- 按钮底部 -->
    <slot name="bottom"></slot>
  </div>
</template>

<script name="ProductIntroBtnEntry" setup>
import TopTips from './Components/TopTips.vue'
import BottomTips from './Components/BottomTips.vue'
import ProductIntroCommonBtn from './Components/ProductIntroCommonBtn.vue'
import ProductIntroSaveBtn from '../ProductIntroSaveBtn'
import { watch, computed, reactive, ref, onMounted, onBeforeUnmount, nextTick } from 'vue'
import {
  useMapGetters,
  useMapMutation,
  useMapActions,
} from 'public/src/pages/goods_detail_v2/compositions/vuex.composition.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
// import {
//   checkAddToGroupCallBack,
//   openRecommendDrawer,
// } from './Compositions/useCheckAddToGroup.js'
import useCheckAddToGroup from './Compositions/useCheckAddToGroup.js'
import {
  renderAddWidth,
  addFixed,
  initAddFixed,
  destroyedAddFixed,
} from './Compositions/useAddFixStyle'

import { isMobile as isPhone } from '@shein/common-function'

daEventCenter.addSubscriber({ modulecode: '1-7-1' })
daEventCenter.addSubscriber({ modulecode: '1-8-6' })

let isMobile = () => {}
let showAddedTextTimer = null

if (typeof window !== 'undefined') {
  isMobile = isPhone
}

const emits = defineEmits(['addBagSuccess', 'reLoginAndClick', 'switchColor'])
const isIpad = ref(isMobile())

const buyNowBtnLoading = ref(false)
const { goodsSn } = useMapGetters([ 'goodsSn' ])

// 内部数据流
const {
  language,
  config,
  rewardPoints,
  integralTips,
  skuInfo,
  currentGoodsId,
  errorTipsCombo,
  isAddBagStyle,
  oneClickPayBtnStatus,
  contentCombo,
  baseInfo,
  showAddWishAfterRec,
  isAddBagPopup,
  oneClickPayState,
  isAddKeepFixBottom,
  contentTextCombo,
  isBuyNow
} = useMapGetters('AddBtn', [
  'language',
  'config',
  'rewardPoints',
  'integralTips',
  'skuInfo',
  'currentGoodsId',
  'errorTipsCombo',
  'contentCombo',
  'isAddBagStyle',
  'oneClickPayBtnStatus',
  'baseInfo',
  'showAddWishAfterRec',
  'isAddBagPopup',
  'oneClickPayState',
  'isAddKeepFixBottom',
  'contentTextCombo',
  'isBuyNow'
])

const {
  updateAddToBagErrorTips,
  updateCheckNotSelectedTips,
  updateAddToBagLoading,
  updateShowAddedText,
  updateOneClickPayState,
} = useMapMutation('AddBtn', [
  'updateAddToBagErrorTips',
  'updateCheckNotSelectedTips',
  'updateAddToBagLoading',
  'updateShowAddedText',
  'updateOneClickPayState',
])
const { showLoginModal, assignState, autoGetCouponAtAddBag, buyNow } = useMapActions([
  'showLoginModal',
  'assignState',
  'autoGetCouponAtAddBag',
  'buyNow'
])


const { 
  checkAddToGroupCallBack,
  openRecommendDrawer,
} = useCheckAddToGroup()

// 对外提供的配置参数
const props = defineProps({
  customerConfig: {
    type: Object,
    default: () => ({
      showBenefitText: true,
      showErrorTips: true,
      pageFrom: '',
      showFreeShippingButton: true,
      openQuick: false,
      showAddWish: true,
      showTopTips: true,
      showBottomTips: true,
      needFix: true,
      oneClickPayBtn: true,
      isForEstimated: false,
      showBuyNow: true,
      strengthenColor: true,
      isSatisfiedBuyMultipleText: ''
    }),
  },
  customerAnalysis: {
    type: Object,
    default: () => ({
      reportData: {},
    }),
  },
})

const freeShippingButtonTypeText = computed(() => {
  if (!errorTipsCombo.value.showAtmosphere) return language.value.SHEIN_KEY_PC_26553
  return language.value.SHEIN_KEY_PC_15911
})

const showFreeShippingTips = computed(() => {
  return isAddBagStyle.value && props.customerConfig.showFreeShippingButton
})

const showOneClickPayBtn = computed(() => {
  return (
    oneClickPayBtnStatus.value &&
    props.customerConfig.oneClickPayBtn &&
    !props.customerConfig.isForEstimated
  )
})

const contentConfig = reactive({
  isIpad,
  showFreeShippingTips,
  showOneClickPayBtn,
  from: props.customerConfig.pageFrom,
})

const validateParams = computed(() => {
  const { isAllSoldOut, hasGoodsSize, isCurrentSoldOut, isCustomization } =
    config.value
  const { isForEstimated, openQuick, isAllowAutoClaim, forbidAtmosphereFlow } = props.customerConfig

  return {
    isForEstimated,
    isAllSoldOut,
    isAllowAutoClaim,
    isCurrentSoldOut,
    hasGoodsSize,
    openQuick,
    isCustomization,
    forbidAtmosphereFlow,
  }
})

const contentText = computed(() => {
  const {
    isCurrentSoldOut,
    unListed,
    isCustomization,
    isSoldOut,
    mallStock,
    showAddedText,
  } = contentTextCombo.value

  // 先判断是否售罄
  if (isCurrentSoldOut && unListed) {
    return language.value.SHEIN_KEY_PC_17424
  } else if (isCurrentSoldOut && !unListed) {
    if (mallStock == 0 && !isSoldOut) {
      return language.value.SHEIN_KEY_PC_14634
    } else {
      return language.value.SHEIN_KEY_PC_14617
    }
  }

  if (isCustomization) {
    return language.value.SHEIN_KEY_PC_27604
  } else if (props.customerConfig.isSatisfiedBuyMultipleText) {
    return props.customerConfig.isSatisfiedBuyMultipleText
  } else if (props.customerConfig.isAllowAutoClaim) {
    return language.value.SHEIN_KEY_PC_26339
  } else if (showAddedText) {
    return language.value.SHEIN_KEY_PC_14604
  } else {
    return language.value.SHEIN_KEY_PC_14634
  }
})

const triggerExposeBuyNow = () =>  {
  if (!isBuyNow.value || !props.customerConfig.showBuyNow) return
  setTimeout(() => {
    daEventCenter.triggerNotice({
      daId: '1-8-6-202',
      extraData: {
        activity_from: 'main',
        goods_id: currentGoodsId.value,
        location: 'page',
        is_buynow: '1',
        nobuynow_reason: '',
      }
    })
  }, 200)
}

watch([skuInfo, currentGoodsId], ([skuInfo, currentGoodsId]) => {
  if (skuInfo.value || currentGoodsId) {
    updateAddToBagErrorTips('')
  }
})

watch(goodsSn, (val) => {
  val && nextTick(() => triggerExposeBuyNow())
}, { immediate: true })

const btnEventCenterCallback = ({ type, data }) => {
  switch (type) {
    case 'showLogin':
      showLoginModal(data)
      break
    case 'reLoginAndClick':
      emits('reLoginAndClick', data)
      break
    case 'changeLocalCountry':
      assignState({ country: data })
      break
    case 'getCoupon':
      autoGetCouponAtAddBag(data)
      break
    case 'checkNotSelected':
      updateCheckNotSelectedTips(data)
      break
    case 'updateLoading':
      updateAddToBagLoading(data)
      break
    case 'forEstimatedHandle':
      emits('addBagSuccess', data)
      break
    case 'switchColor':
      emits('switchColor', data)
      break
    case 'updatePromotionLimitShow':
      assignState({ promotionLimitShow: data })
      break
    case 'addToBagSuccess':
      addToBagSuccessCallBack(data)
      break
    case 'checkAddToGroup':
      checkAddToGroupCallBack(
        { showAddWishAfterRec: showAddWishAfterRec.value, language, goods_id: currentGoodsId.value },
        data
      )
      break
    case 'updateOCPStage':
      updateOneClickPayState({
        ...(oneClickPayState.value),
        stage: (oneClickPayState.value.stage || 1) + 1,
      })
      break
    case 'updateErrorCodeTips':
      updateAddToBagErrorTips(data)
      break
    case 'updateQuantity':
      assignState({ quantity: data })
      break
  }
}

const addToBagSuccessCallBack = data => {
  addTextAnimation()
  openRecommendDrawer(
    { isAddBagPopup: isAddBagPopup.value, res: data, language: language.value, goods_id: currentGoodsId.value },
    {
      callbacks: {
        hasRecommendCallback: () => {
          window.cart_module && window.cart_module.recart(data, true)
        },
        notRecommendCallback: () => {
          window.cart_module && window.cart_module.recart(data)
        },
      },
    }
  )
}

const addTextAnimation = () => {
  updateAddToBagErrorTips('')
  updateShowAddedText(true)
  updateAddToBagLoading(false)
  clearTimeout(showAddedTextTimer)
  showAddedTextTimer = setTimeout(() => {
    updateShowAddedText(false)
  }, 1500)
}

const clickBuyNow = async () => {
  if (buyNowBtnLoading.value) return
  buyNowBtnLoading.value = true
  buyNow().finally(buyNowBtnLoading.value = false)
}

onMounted(() => {
  initAddFixed(isAddKeepFixBottom.value && props.customerConfig.needFix)
})

onBeforeUnmount(() => {
  destroyedAddFixed()
})
</script>

<style lang="less">
@import "../.././style/common/index.less";
.product-intro {
  &__add {
    margin-top: 15px;
  }
  &__add-wrap {
    min-height: 54px;
    font-size: 0;
  }
  &__add-status {
    display: inline-flex;
    width: 100%; // TODO SSR 直出优化
    &.fixed {
      position: fixed;
      bottom: 0;
      background: #fff;
      z-index: @zindex-hover-tip;
      &::before {
        content: '';
        display: block;
        height: 20px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.73) 58%,
          #ffffff 100%
        );
      }
    }
    .btn-left {
      width: 100%;
      // max-width: calc(100% - 54px - 17px);
      flex: 1;
    }
    .btn-right {
      display: inline-flex;
      align-items: flex-end;
    }
  }

  &__add-buyNow {
    font-family: Arial Black;
    width: calc(100% - 54px - 17px);
    width: 100%;
    border: 1px solid #222;
    font-size: 18px;
    font-weight: 900;
    margin-top: 16px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__add-buyNow:hover {
    color: rgba(34, 34, 34, 0.8)
  }
}
</style>
