import { createApp } from 'vue'
import { registerBaseContext } from 'public/src/pages/common/common_inject.js'

let RecommendDrawerInstance = undefined

export default {
  async showModal(data) {
    // 如果组件已渲染，则返回即可
    if (RecommendDrawerInstance) {
      return RecommendDrawerInstance.showModal(data)
    }
    if (typeof window === 'undefined') return

    try {
      const RecommendDrawer = await import('./RecommendDrawer.vue')
      const RecommendDrawerAPP = createApp(RecommendDrawer.default)
      registerBaseContext(RecommendDrawerAPP)
      const instance = RecommendDrawerAPP.mount(document.createElement('div'))
      // 要挂载的元素
      const parent = document.body
      parent.appendChild(instance.$el)
      // 将组件实例赋值
      // eslint-disable-next-line require-atomic-updates
      RecommendDrawerInstance = instance
      // 展示
      RecommendDrawerInstance.showModal(data)
    } catch (error) {
      console.log('import RecommendDrawer vue error', error)
    }
  },
  hideModal() {
    if (typeof window === 'undefined') return
    RecommendDrawerInstance?.hideModal?.()
  }
}
