<template>
  <div
    v-if="cGoodId"
    class="product-intro__head-share"
  >
    <s-popover
      placemen="bottom"
      trigger="hover"
      :append-to-body="false"
      immediately-render
      :prop-style="customPopStyle"
      @mouseenter="onMouseEnter()"
    >
      <template #reference>
        <i
          v-expose="{
            id: '2-22-1',
            data: {
              share_type: 'page',
              content_id: cGoodId,
            },
          }"
          class="svgicon svgicon-detail-share"
        ></i>
      </template>
      <ul class="product-intro__head-share_list">
        <li
          v-for="item in cShareOptions"
          :key="item.url"
          class="product-intro__head-share_list_item"
        >
          <a
            target="_blank"
            :href="item.url"
            :aria-label="item.name"
          >
            <img
              v-tap="item.sa.tap"
              v-expose="item.sa.expose"
              :src="item.icon"
              :type="item.name"
            />
          </a>
        </li>
      </ul>
    </s-popover>
  </div>
</template>

<script>
export default {
  name: 'ProductIntroHeadSharePopup',
}
</script>
<script setup>
import 'public/src/icon/detail-share.svg'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { transformImg } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { isLogin } from 'public/src/pages/common/utils/index.js'

const store = useStore()
const { PUBLIC_CDN, IS_RW } = gbCommonInfo

const cGoodId = computed(() => store.state.productIntroData.detail?.goods_id)

const customPopStyle = {
  boxShadow: '0 2px 16px 4px rgba(242, 242, 242, 0.8)',
  padding: '10px 15px',
}


function onMouseEnter() {

  getShareUrlByApi()

  daEventCenter.triggerNotice({
    daId: '2-22-2',
    bindData: {
      share_type: 'page',
      content_id: cGoodId.value || '',
    },
  })
}

const shareUrlByApi = ref('')
function getShareUrlByApi() {
  if (!shareUrlByApi.value && !IS_RW && isLogin()) {
    function getShareUrl() {
      let shareUrl = ''
      return new Promise((resolve) => {
        schttp({
          url: '/api/marketing/shareUrl/get',
          method: 'POST',
          data: {
            // eslint-disable-next-line no-undef
            shareUrl: goodslink_share,
            pageType: 1,
          },
        }).then((json) => {
          if (
            json &&
            json.code &&
            json.code == '0' &&
            json.info &&
            json.info.url.indexOf('url_from') > -1
          ) {
            shareUrl = json.info.url + '&share_from=' + store.state.SiteUID
            resolve(shareUrl)
          }
        })
      })
    }

    getShareUrl().then((shareUrl) => {
      shareUrlByApi.value = shareUrl
    })
  }
}

const cShareOptions = computed(() => {
  if (!cGoodId.value) return []
  const _link = shareUrlByApi.value || window?.goodslink_share || ''
  if (!_link) return []


  const origin_image = store.state.productIntroData.goods_imgs?.detail_image?.[0]?.origin_image
  const pinterestImg = origin_image ? transformImg({ img: origin_image?.replace('.jpg', '_thumbnail_405x552.jpg') }) : ''

  return [
    {
      url: `https://twitter.com/intent/tweet?url=${_link}`,
      icon: `${PUBLIC_CDN}/she_dist/images/prime/prime_twitter-aa9d79c020.png`,
      name: 'twitter',
      sa: {
        tap: getChannelAnalysis({ type: 'click', channel: 'twitter' }),
        expose: getChannelAnalysis({ type: 'expose', channel: 'twitter' }),
      },
    },
    {
      url: `http://pinterest.com/pin/create/button/?url=${_link}&media=${pinterestImg}`,
      icon: `${PUBLIC_CDN}/she_dist/images/prime/prime_pinterest-a0ffafeb51.png`,
      sa: {
        tap: getChannelAnalysis({ type: 'click', channel: 'pinterest' }),
        expose: getChannelAnalysis({ type: 'expose', channel: 'pinterest' }),
      },
    },
    {
      url: `http://www.facebook.com/sharer/sharer.php?u=${_link}`,
      icon: `${PUBLIC_CDN}/she_dist/images/prime/prime_facebook-8256d81317.png`,
      name: 'facebook',
      sa: {
        tap: getChannelAnalysis({ type: 'click', channel: 'facebook' }),
        expose: getChannelAnalysis({ type: 'expose', channel: 'facebook' }),
      },
    },
  ]
})

function getChannelAnalysis({ type, channel }) {
  const data = {
    share_type: 'page',
    content_id: cGoodId.value,
    channel,
  }
  return {
    id: type === 'click' ? '2-22-4' : '2-22-3',
    code: 'ShareCommonChannel',
    once: false,
    data,
  }
}


</script>
<style lang="less">
.product-intro__head-share {
  position: absolute;
  right: 0;
  top: 0;

  .svgicon-detail-share {
    width: 24px;
    height: 24px;
  }

  &_list {
    display: flex;
    align-items: center;
    &_item {
      width: 28px;
      height: 28px;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
