<template>
  <div>
    <div
      v-for="(item, index) in content"
      :key="index"
      class="product-intro__attr j-expose__accordion-att-con"
      :data-expose-id="'detailops' + index"
    >
      <div
        v-enterkey
        class="product-intro__description-head j-expose__accordion-att-tar"
        tabindex="0"
        :aria-label="item.moduleTitleName"
        da-event-click="1-8-6-81"
        :data-position="index + 2"
        :data-status="item.showModule == '1' ? 'open' : 'close'"
        @click="handleFold('DetailOPos' + index)"
      >
        <span>{{ item.moduleTitleName }}</span>

        <Icon
          v-if="unfold.includes(`DetailOPos${index}`)"
          name="sui_icon_min_16px"
          size="16px"
          class="head-icon"
        />
        <Icon
          v-else
          name="sui_icon_add_16px"
          size="16px"
          class="head-icon"
        />
      </div>
      <ClientOnly>
        <ProductIntroDetailGoodsDesc
          v-show="unfold.includes(`DetailOPos${index}`)"
          :is-show="unfold.includes(`DetailOPos${index}`)"
          :content="item"
          :idx="index + 2"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import ProductIntroDetailGoodsDesc from './ProductIntroDetailGoodsDesc'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  components: {
    ProductIntroDetailGoodsDesc,
    ClientOnly,
    Icon
  },
  data() {
    return {
      list: [],
      title: '',
    }
  },
  computed: {
    ...mapState(['productIntroData', 'unfold', 'language']),
    ...mapGetters(['pageComponents']),
    detailGoodsDesc() {
      return this.pageComponents.descPosConf.detailGoodsDesc
    },
    content() {
      return this.pageComponents.descPosConf.oPosData
    },
  },
  methods: {
    ...mapMutations(['assignState']),
    handleFold(foldName) {
      let updatedArray = []
      if (this.unfold.includes(foldName)) {
        updatedArray = this.unfold.filter(item => item !== foldName)
      } else {
        updatedArray = [...this.unfold, foldName]
      }
      this.assignState({
        unfold: updatedArray
      })
    },
  },
}
</script>
