<template>
  <div
    v-if="content.textAttr && content.textAttr.length || content.imgAttr && content.imgAttr.length"
    class="product-intro__attr j-expose__accordion-att-con"
    data-expose-id="detailfps"
  >
    <div
      v-enterkey
      class="product-intro__description-head j-expose__accordion-att-tar"
      tabindex="0"
      :aria-label="content.moduleTitleName"
      da-event-click="1-8-6-81"
      data-position="1"
      :data-status="content.showModule == '1' ? 'open' : 'close'"
      @click="handleFold"
    >
      <span>{{ content.moduleTitleName }}</span>
      <Icon
        v-if="unfold.includes('DetailFPos')"
        name="sui_icon_min_16px"
        size="16px"
        class="head-icon"
      />
      <Icon
        v-else
        name="sui_icon_add_16px"
        size="16px"
        class="head-icon"
      />
    </div>
    <ClientOnly>
      <!-- 里面有副作用代码 要放出来记得检查 -->
      <ProductIntroDetailGoodsDesc
        v-show="unfold.includes('DetailFPos')"
        :is-show="unfold.includes('DetailFPos')"
        :content="content"
        :idx="1"
      />
    </ClientOnly>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import ProductIntroDetailGoodsDesc from './ProductIntroDetailGoodsDesc'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { Icon } from '@shein-aidc/icon-vue3'
import { CVAttrId } from 'public/src/pages/goods_detail_v2/utils/productDetail.js'
export default {
  components: {
    ProductIntroDetailGoodsDesc,
    ClientOnly,
    Icon
  },
  computed: {
    ...mapState(['productIntroData', 'unfold', 'language']),
    ...mapGetters(['pageComponents', 'originFPosContent', 'isNeedFilterCVAttr']),
    content() {
      let content = this.originFPosContent
      if(this.isNeedFilterCVAttr) {
        if(content?.textAttr?.length) {
          content.textAttr = content.textAttr.filter(item => !CVAttrId.includes(Number(item.attr_id || 0)))
        }
        if(content?.imgAttr?.length) {
          content.imgAttr = content.imgAttr.filter(item => {
            const attr_id = item?.[0]?.attr_id || 0
            return !CVAttrId.includes(Number(attr_id))
          })
        }
      }
      return content
    },
    detailGoodsDesc() {
      return this.pageComponents.descPosConf.detailGoodsDesc
    },
  },
  methods: {
    ...mapMutations(['assignState']),
    handleFold() {
      let updatedArray = []
      if (this.unfold.includes('DetailFPos')) {
        updatedArray = this.unfold.filter(item => item !== 'DetailFPos')
      } else {
        updatedArray = [...this.unfold, 'DetailFPos']
      }
      this.assignState({
        unfold: updatedArray
      })
    },
  },
}
</script>

<style lang="less">
.product-intro {
  &__attr-wrap {
    padding: 15px 0 15px;
    max-height: 480px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #fff;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(224, 224, 224, 1);
      }
    }
  }
}
</style>
