import { get } from 'lodash'
import cccTempalteDeal from '../utils/cccTemplateDeal'
import { souceFormat } from 'public/src/pages/components/coupon/mall/utils'
import { getRetentionConfig } from '../utils/common'
import getEstimatedInfo from 'public/src/pages/goods_detail_v2/utils/getEstimatedInfo'
import { template, transformImg, timeTransformer, parseQueryString, stringifyQueryString, cloneDeep } from '@shein/common-function'
import { abtUserAnalysis } from '@shein-aidc/basis-abt-router'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { formatTime } from 'public/src/pages/goods_detail_v2/utils/shippingInfoUtil.js'
import { CVAttrId } from 'public/src/pages/goods_detail_v2/utils/productDetail.js'
import { getFormatBuyboxProductList } from 'public/src/pages/common/BuyBoxPopup/utils.js'
const compareNewuserCoupon = list => {
  let newUserCoupon = {}
  const percentCouponList = list.filter(
    item => item.rule_dimension == 2 || item.rule_dimension == 5
  )
  if (percentCouponList.length) {
    //存在满折券，优先取满折
    percentCouponList.sort((a, b) => {
      const amountA = a?.rule?.[0]?.value
      const amountB = b?.rule?.[0]?.value
      return amountA - amountB // 根据降价比例排序，升序排序(0.8折扣是20%；0.6折扣是40%，折扣更多)
    })
    newUserCoupon = percentCouponList[0]
  } else {
    //没有满折券，都是满减券
    list.sort((a, b) => {
      const amountA = a?.rule?.[0]?.value_amount?.amount
      const amountB = b?.rule?.[0]?.value_amount?.amount
      return amountB - amountA // 根据降价金额，降序排序
    })
    newUserCoupon = list[0]
  }
  return newUserCoupon
}


const getters = {
  isMatchCateId(state, getters) {
    const { isSatisfied } = getters.getEstimatedInfo || {}
    const { isMatchCateId } = state.productIntroData?.addOnItemCate?.result || {}
    return isSatisfied ? true : isMatchCateId
  },
  showBuyBox(state, getters) {
    const notMainMark = '4525' // 主推款商品
    const { tsp = {} } = state.productIntroData
    return getters.needBuyBoxData && !tsp[notMainMark]
  },
  needBuyBoxData(state, getters) {
    const sameStyleMark = '4528' // 有同款商品
    const noProductBranch = 'A'
    const hasProductBranch = 'B'
    const hasProductAndShowImgBranch = 'C'

    const { BuyBoxSwitch } = getters.screenAbtConfig?.BuyBoxSwitch?.param || {}
    const { tsp = {} } = state.productIntroData
    return [hasProductBranch, noProductBranch, hasProductAndShowImgBranch].includes(BuyBoxSwitch) && tsp[sameStyleMark]
  },
  buyBoxGoodsDisplay(state, getters) {
    const abt = getters.screenAbtConfig?.BuyBoxSwitch?.param || {}
    const hasProductBranch = 'B'
    const hasProductAndShowImgBranch = 'C'

    return [hasProductBranch, hasProductAndShowImgBranch].includes(abt.BuyBoxSwitch)
  },
  buyBoxSameId(state, getters) {
    const { tsp = {} } = state.productIntroData
    return tsp['4528'] || ''
  },
  isMainGoodsLowestPrice(state, getters) {
    // 1.是否是保留款商品, 是则结束
    const { tsp = {} } = state.productIntroData
    const isReservedGood = tsp?.['3598'] || ''
    if(isReservedGood) return 0

    // 2. 是否命中buyboxABT, 否即结束
    const { BuyBoxSwitch } = getters.screenAbtConfig?.BuyBoxSwitch?.param || {}
    const isBuyBoxSwitch = ['A', 'B', 'C'].includes(BuyBoxSwitch)
    if(!isBuyBoxSwitch) return 0

    // 3. 是否命中评论拼接ABT, 否即结束
    const { Comment_Group } = getters.screenAbtConfig?.Reviewchange?.param || {}
    const isCommentJoin = Comment_Group == 'A'
    if(!isCommentJoin) return 0

    // 4. 当前商品是否有同款组, TSP标签 4528有值，有同款组, 否即结束
    const buyBoxSameId = getters.buyBoxSameId
    if(!buyBoxSameId) return 0

    // 判断当前商品是否为lowest price
    const { mainGoodsId } = getters
    const buyBoxRecInfo = state.buyBoxRecInfo
    const { _newList } = getFormatBuyboxProductList(buyBoxRecInfo, false, {})
    const lowerPriceGoodId = _newList?.find(item => item._serFormatInfo?.isLowerPice)?.goods_id || ''
    return lowerPriceGoodId == mainGoodsId ? 1 : 0
  },
  mainGoodsId(state){
    return state.productIntroData?.detail?.goods_id || ''
  },
  onlyShowSameItem(state){
    const { allReportNum, commentInfoNum, buyBoxTotalNum } = state || {}
    return allReportNum == 0 && commentInfoNum == 0 && buyBoxTotalNum > 0
  },
  couponList(state) {
    return state.couponList
  },
  couponListFormated(state, getters) {
    let formated = getters.couponList
      ? getters.couponList.map((item) => {
        return souceFormat(item)
      })
      : []
    return formated || []
  },
  isEstimatedMatch(state, getters){
    let E_Price_Cal = getters.screenAbtConfig?.EstimatedPrice?.p?.E_Price_Cal
    return ['A', 'B', 'C', 'D', 'Non_Threshold', 'Have_Threshold'].includes(E_Price_Cal)
  },
  originCouponList(state, getters){
    const newCouponList = getters.getEstimatedInfo?.coupons || []
    const hasValue = getters.getEstimatedInfo?.value // 存在有效到手价
    let getEstimatedInfo = getters.getEstimatedInfo
    if(hasValue && newCouponList.length){
      const optimalCouponList = getters.getEstimatedInfo?.optimalCouponList || []
      optimalCouponList.forEach(coupon => {
        let idx = newCouponList?.findIndex(
          item => item.coupon_code === coupon?.coupon_code
        )
        if (idx > -1) {
          if(optimalCouponList.length > 1){
            if (getEstimatedInfo?.isAbCoexist || (getEstimatedInfo?.isAb && getEstimatedInfo?.isEstimatedstrategyNone)) {
              if (optimalCouponList.length > 2) {
                newCouponList[idx].is_stacked_best_coupon = true
              } else {
                newCouponList[idx].is_estimated_best_coupon = true
              }
            } else {
              newCouponList[idx].is_stacked_best_coupon = true
            }
          } else {
            newCouponList[idx].is_estimated_best_coupon = true
          }
        }
      })
    }
    let formatedNewCouponList = newCouponList ? newCouponList.map((item) => {
      return souceFormat(item)
    }) : []
    // 兜底取值
    let result = (getters.isEstimatedMatch || getters.getEstimatedInfo?.isAb) ? formatedNewCouponList : (getters.couponListFormated || [])
    return result
  },
  currCouponList(state, getters){
    let result = getters.originCouponList

    let freestylecouponAbtValue = state.productIntroData.screenAbtConfig?.itemfreeshipping?.p?.freestylecoupon || 'Notshow'
    const isProductFreeShipping = getters.isProductFreeShipping
    const isPromotionFreeShipping = getters.isPromotionFreeShipping
    if (
      freestylecouponAbtValue === 'Notshow' ||
      isProductFreeShipping ||
      isPromotionFreeShipping
    ) {
      result = result.filter(item => ![5, 6, 9].includes(Number(item.apply_for)))
    }

    return result
  },
  mallCode: (state) => {
    return state.productIntroData.mallCode
  },
  mallStock(state, getters) {
    return state.productIntroData?.detail?.mall?.[getters.mallCode]?.stock
  },
  siteMallInfo: (state) => {
    return state.productIntroData.siteMallInfo || []
  },
  promotionInfo(state, getters) {
    let needShieldTypeIdsOnInversion = [3, 24, 30, 31]
    let result = getters.skuPromotionInfo || getters.skcPromotionInfo || []
    if (getters.isInversion || getters.isInversionDe) return result.filter(item => !needShieldTypeIdsOnInversion.includes(Number(item.typeId)))
    return result
  },
  skcPromotionInfo(state, getters) {
    return state.productIntroData?.detail?.mall?.[getters.mallCode]?.promotionInfo
  },
  skuPromotionInfo(state, getters) {
    return state.skuInfo?.mall?.[getters.mallCode]?.promotionInfo
  },
  flashGoods (state, getters) {
    if (getters.isInversion || getters.isInversionDe) return null
    return getters.promotionInfo?.find?.(item => item.typeId == 10)
  },
  flashType(state, getters) {
    return getters.flashGoods?.flashType
  },
  isBrandFlash(state, getters) {
    return getters?.flashType == 6
  },
  showPriceBeltColor(state, getters) {
    const { IS_RW } = gbCommonInfo
    const { flashType, newFlashPromotion, isInversion, isInversionDe } = getters
    if (isInversion) return false
    if (isInversionDe) return false
    if (getters.isBrandFlash) return true
    if (IS_RW) return false
    // if (isSpecialFlash) return true
    if ([1, 5].includes(Number(flashType)) || ([4].includes(Number(flashType)) && newFlashPromotion)) return true
    return false
  },
  newFlashPromotion(state, getters) {
    let result = cloneDeep(state.newFlashPromotion)
    if (!result?.price?.amountWithSymbol) return null
    let skuInfoMallInfo = state.skuInfo?.mall?.[getters.mallCode]
    if (skuInfoMallInfo?.showTypeId == 10) {
      result.suggested_sale_price = skuInfoMallInfo.suggestedSalePrice
    }
    let discountPercent = state.newFlashPromotion?.discount_percent || result?.discountValue
    let discountValue = state.newFlashPromotion?.discount_value || (result?.discountValue ? ((1 - result?.discountValue) * 100).toFixed(0) : '')

    return {
      ...result,
      discountPercent,
      discountValue
    }
  },
  exclusivePromotionPrice(state, getters) {
    let { complianceMode, complianceTipsMode } = getters
    if (complianceMode || complianceTipsMode ) return {}
    if (!getters.promotionInfo?.find?.(item => item.typeId == 12)) return {}
    return state.exclusivePromotionPrice
  },
  // 当前评分
  currentRate: (state) => {
    return (
      (state.productIntroData &&
        state.productIntroData.detail &&
        state.productIntroData.detail.comment &&
        state.productIntroData.detail.comment.comment_rank) ||
      1
    )
  },
  // 当前尺寸的国家标识
  country: (state) => {
    // state.productIntroData && state.productIntroData.localSizeList && state.productIntroData.localSizeList.country_code || ''
    return state.country
  },
  goodsSn: (state) => {
    return get(state, 'productIntroData.detail.goods_sn', '')
  },
  catId: (state) => {
    return (
      (state.productIntroData &&
        state.productIntroData.detail &&
        state.productIntroData.detail.cat_id) ||
      ''
    )
  },
  // 判断是否在售以及是否空库存
  isAllSoldOut: (state) => {
    return (
      Number(state.productIntroData.detail.is_on_sale) === 0 ||
      Number(state.productIntroData.detail.stock) === 0
    )
  },
  isCurrentSoldOut: (state, getters) => {
    return (
      Number(state.skuInfo.stock) === 0 ||
      getters.isAllSoldOut ||
      getters.mallStock == 0
    )
  },
  isForwardCategory: (state) => {
    return state.productIntroData.isForwardCategory
  },
  // 是否是定制商品
  isCustomization: (state) => { 
    return state.productIntroData.detail?.customization_flag == 1
  },
  tipModal: (state) => {
    return state.tipModal
  },
  loginModal: (state) => {
    return state.loginModal
  },
  language: (state) => {
    return state.language
  },
  fitMap: (state) => {
    return {
      1: state.language.SHEIN_KEY_PC_14650,
      2: state.language.SHEIN_KEY_PC_14652,
      3: state.language.SHEIN_KEY_PC_14651,
    }
  },
  unListed: (state) => {
    return state.productIntroData.gradeState.length
      ? state.productIntroData.gradeState[0].new_product_unsale === 1
      : false
  },
  priceDetect(state) {
    // skc下的多价格检测
    let sizePrice = -1
    for (let i = 0; i < state.saleAttrList.sku_list?.length; i++) {
      if (sizePrice === -1) {
        sizePrice = state.saleAttrList.sku_list?.[i]?.price?.salePrice?.amount
        continue
      }
      if (
        sizePrice !== state.saleAttrList.sku_list?.[i]?.price?.salePrice?.amount
      ) {
        return true
      }
    }
    return false
  },

  lowPriceAndHighPrice(state) {
    let priceList = state.saleAttrList.sku_list.map((item) => {
      return item.price.salePrice.amount
    })
    let minPrice = Math.min(...priceList)
    let maxPrice = Math.max(...priceList)
    return {
      minPrice,
      maxPrice,
    }
  },

  pageComponents(state, getters) {
    return cccTempalteDeal(state, getters)
  },
  /**
   * 
   * 针对固定标签的商品，展示该商品的品牌信息 wiki:pageId=1200449474
   */
  isBrandHasTargetTag(state) {
    /**
    * 本逻辑跟原来有单独品牌模块的逻辑不冲突，相当于是该标签商品就有单独的品牌模块，原来品牌集成店的商品走了某abt分支也有单独的品牌模块，互不相干
     */
    const TAG_ID = '600012606'
    const tspLabelPool = state.productIntroData?.tsp || {}
    return !!tspLabelPool[TAG_ID]
  },
  hasBrandSeries(state) {
    const isNotEmpty = (obj) => {
      return obj && Object.keys(obj).length
    }
    const getSeriesAndBrand =  state.productIntroData?.getSeriesAndBrand || {}
    const { brandSeriesDetail = {}, IS_RW } = state
    return isNotEmpty(brandSeriesDetail) && isNotEmpty(getSeriesAndBrand) && (!IS_RW && getSeriesAndBrand?.brand?.brand_logo || getSeriesAndBrand?.series?.id || getSeriesAndBrand?.series_list?.length)
  },
  isStore(state) {
    return state.detailSignBoardData?.brandData?.type === 'store' || state.detailSignBoardData?.storeData?.type === 'store'
  },
  isBrandStore(state) {
    const { localStoreInfo = {} } = state
    return +localStoreInfo?.storeBusinessType === 2 
  },
  /**
   * 针对固定标签的商品，展示商品的banner TODO del
  */
  // isBannerHasTargetTag(state) {
  //   const TAG_ID = '600018490'
  //   const tspLabelPool = state.productIntroData?.tsp || {}
  //   return !!tspLabelPool[TAG_ID]
  // },
  // 展示优先级 品牌集成店 > 品牌系列 > 店铺
  detailSignBoardData(state) {
    const isNotEmpty = (obj) => {
      return obj && JSON.stringify(obj) !== '{}'
    }
    // 品牌系列
    const getSeriesAndBrand =  state.productIntroData?.getSeriesAndBrand || {}
    const { brandSeriesDetail = {},  localStoreInfo = {} } = state
    const showBrandSeries = isNotEmpty(brandSeriesDetail) && isNotEmpty(getSeriesAndBrand) && (!gbCommonInfo.IS_RW && getSeriesAndBrand?.brand?.brand_logo || getSeriesAndBrand?.series?.id || getSeriesAndBrand?.series_list?.length)

    // 店铺
    const { storeStatus, storeType, storeBusinessType, extAbt, } = localStoreInfo
    const showStore = !!([1, 2, 6].includes(+storeType) && storeStatus) // 1: 平台店铺, 2: 自营店铺, 6: 1P
    let result = {}
    // 店铺跟品牌同时存在时都显示
    const showStoreAndBrand = !gbCommonInfo.IS_RW && extAbt?.BrandCollectionAdjust?.p?.BrandCollectionAdjust === 'new' && showStore && brandSeriesDetail?.type === 'brand'
    if (storeBusinessType == 2 || showStoreAndBrand) {
      result = {
        showStoreAndBrand,
        // 数据结构统一
        storeData: localStoreInfo, 
        brandData: brandSeriesDetail && { 
          ...brandSeriesDetail,
          logoTips: storeBusinessType == 2,
        },
        showStore
      }
    }else {
      result = showBrandSeries
        ? { brandData: brandSeriesDetail }
        : { storeData: localStoreInfo, showStore }
    }
    return result
  },
  // 一店铺多品牌实验
  brandCollectionAdjustNew(state) {
    const { localStoreInfo = {} } = state
    const { extAbt = {} } = localStoreInfo
    return extAbt?.BrandCollectionAdjust?.p?.BrandCollectionAdjust === 'new'
  },
  // 统一处理accordion默认展开逻辑
  accordionDefaultUnfold(state, getters) {
    let defaultUnfold = []
    
    if (
      getters.pageComponents.descPosConf.fPosData.isShow &&
      getters.pageComponents.descPosConf.fPosData.showModule == 1
    ) {
      defaultUnfold = ['DetailFPos'] // fPosDesc
    } else if (
      getters.pageComponents.descPosConf.fPosData.isShow === false &&
      getters.pageComponents.materialConf.isShow &&
      getters.pageComponents.materialConf.materialList.length
    ) {
      defaultUnfold = ['Material'] // material
    }
    defaultUnfold = [...defaultUnfold, 'matchingStyles'] // matchingStyles 栏 默认展开

    if (getters.pageStructureAbtStyleInfo.isDecriptionShow) {
      defaultUnfold.push('Description')
    }
    const handlerSBS = (arr) => {
      arr.push('brand', 'brand_zone') // 展开品牌 brand_zone为展示第一个楼层 如品牌馆
      // 命中abt 默认展示 / 默认不展示 店铺、系列
      let { screenAbtConfig: { pcstoreopen = {} } = {} } = state.productIntroData || {}
      const { param: { pcstoreopen: pcstoreopenValue = 'close' } = {} } = pcstoreopen
      const keySBS = ['store', 'series']
      if (pcstoreopenValue === 'open' || getters.isRecommendSatisfy) {
        arr = [...new Set([...arr, ...keySBS])]
      } else {
        arr = arr.filter(item => !keySBS.includes(item))
      }
      return arr
    }
    defaultUnfold = handlerSBS(defaultUnfold)
    return defaultUnfold
  },
  recommendProducts(state, getters, rootState, rootGetters) {
    // let recommendProducts = state.recProducts.filter((item, index, arr)=>{
    //   const lastIdx = arr.findIndex(it=> it.goods_id === item.goods_id) // 去除重复数据
    //   return lastIdx === index
    // })
    let recProducts = state.recProducts
    // 人工置顶
    const cccStoreGoodsRecIntervention = rootGetters['Recommend/cccStoreGoodsRecIntervention']?.slice(0, 3)
    
    // 找的非闪购商品的第一个位置（闪购商品，通常都是排在最前面的）
    let index = recProducts.findIndex(item => item.is_flash_sale != 1)
    index = index > -1 ? index : 0
    // 有闪购商品时，人工置顶插入在闪购商品后面
    cccStoreGoodsRecIntervention?.length && recProducts.splice(index, 0, ...cccStoreGoodsRecIntervention)

    // 同时存在时按这个闪购-人工配置-推荐顺序排序，有重复商品时，保留前者
    let recommendProducts = []
    let ids = new Set()
    for (const item of recProducts) {
      if (!ids.has(item.goods_id)) {
        ids.add(item.goods_id)
        recommendProducts.push(item)
      }
    }
    if(recommendProducts.length > 10){
      recommendProducts = recommendProducts.slice(0, 11)
    }
    return recommendProducts || []
  },
  recommendDrawerProducts(state) {
    const recProducts = state.recDrawerProducts 
    let recommendProducts = recProducts.filter((item, index, arr)=>{
      const idx = arr.findIndex(it=> it.goods_id === item.goods_id) // 去除重复数据
      return idx === index
    })
    return recommendProducts || []
  },
  recDrawerAddmoreProducts(state) {
    const recProducts = state.addMoreProducts
    let recommendProducts = recProducts.filter((item, index, arr)=>{
      const idx = arr.findIndex(it=> it.goods_id === item.goods_id) // 去除重复数据
      return idx === index
    })
    return recommendProducts || []
  },
  isRecommendAbt(state, getters){
    const { IS_RW, productIntroData } = state
    const { isStore, isBrandStore, hasBrandSeries } = getters
    const abt = getters?.screenAbtConfig?.storerecommend?.param?.storerecommend
    if (IS_RW) return abt === 'show'
    if (abt === 'show-all') return true
    else if (abt === 'show-limited') {
      const isInFashionStore = productIntroData?.isInFashionStore
      if (isBrandStore || isInFashionStore) return true
      if (!isStore && hasBrandSeries) return true
    }
    return false
  },
  isRecommendSatisfy(state, getters) {
    return getters.isRecommendAbt && getters.recommendProducts.length >= 10
  },
  sheinClubPromotionInfo(state, getters) {
    const { skuInfo } = state
    const { detail, mallCode } = state.productIntroData
    const promotionInfo = getters.promotionInfo || []
    const { showPrice: skuShowPrice, showTypeId: skuShowTypeId } = skuInfo?.mall?.[mallCode] || {}
    const isSkuMallDetail = !!(skuShowPrice && skuShowTypeId === 29)
    const { showPrice = {}, vipDiscountPrice = {}, discountValue = 0, showTypeId } = isSkuMallDetail ? skuInfo?.mall?.[mallCode] : detail?.mall?.[mallCode] || {}
    const isSheinClub = promotionInfo.find(({ typeId }) => +typeId === 29) && showTypeId === 29
    if (!!!isSheinClub) return null
    return {
      price: showPrice,
      discountValue: ((1 - discountValue) * 100).toFixed(0),
      discount_amount: vipDiscountPrice,
      sku_code: skuInfo?.sku_code || null
    }
  },
  localSizeRule(state) {
    return (
      state.productIntroData?.multiLocalSize?.size_rule_list?.[
        state.country
      ] || []
    )
  },
  hasLocalSize(state, getters) {
    const { polyAttrSize } = state
    const { localSizeRule } = getters
    if (!localSizeRule.length) {
      return false
    } else {
      for (const attrSizeItem of polyAttrSize) {
        for (const localSizeItem of localSizeRule) {
          if (attrSizeItem.attr_value_name_en === localSizeItem.name) {
            return true
          }
        }
      }
      return false
    }
  },
  optionsData(state) {
    return state?.productIntroData?.otherOptionsItems || []
  },
  // 促销腰带-黑五腰带
  showBlackFridayBelt (state, getters) {
    const { isAllSoldOut, isCurrentSoldOut } = getters
    const isSoldOut = isAllSoldOut || isCurrentSoldOut // 是否售空
    const BlackFridayTypes = ['discountAbShaped', 'willSaveAbShaped', 'countdownAbShaped']
    const NormalPromotionBelt = ['none', 'discount', 'willSave', 'countdown']
    const shapedPromotionBelt = ['priceDiscount']
    const hasBeltCompoent = [...NormalPromotionBelt, ...BlackFridayTypes, ...shapedPromotionBelt].includes(state.beltInfo?.component)
    return hasBeltCompoent && !isSoldOut
  },
  // 跟价款商品展示
  isShowFollowBelt(state, getters) {
    const FOLLOW_GOODS_TAGID = 3658
    const { isAllSoldOut, isCurrentSoldOut } = getters
    const { tsp = {}, screenAbtConfig = {} } = state.productIntroData || {}
    const { FollowLabel: { param: { FollowLabel = 'NO' } = {} } = {} } = screenAbtConfig
    const isShowFollowBeltByAbt = FollowLabel === 'banner' // abt展示banner
    const isFollowGoods = !!tsp?.[FOLLOW_GOODS_TAGID] || false // 跟价款商品
    const isSoldOut = isAllSoldOut || isCurrentSoldOut // 是否售空
    return isShowFollowBeltByAbt && isFollowGoods && !isSoldOut
  },
  // 首屏abt
  screenAbtConfig(state) {
    const { screenAbtConfig = {}, closeEstimatedAndAbPrice } = state.productIntroData || {}
    const result = screenAbtConfig
    if (closeEstimatedAndAbPrice) result.EstimatedPrice = {} // 屏蔽到手价
    return result
  },
  // 水印配置
  watermarkConfig(state, getters) {
    const tspLabelPool = state.productIntroData?.tsp || {}
    const WATERMARK_TSP_TAG_ID = '100000112' // 需要加水印的商品TSP 标签
    const IS_WATER_GOODS = !!tspLabelPool?.[WATERMARK_TSP_TAG_ID] || false // 是否需要加水印
    const { Mastertest = {}, Imagewatermark = {} } = getters?.screenAbtConfig || {}
    const carouselsAbt = ['SHOW'].includes(Mastertest?.p?.Mastertest || 'no')
    const commentAbt = ['show'].includes(Imagewatermark?.p?.Imagewatermark || 'no')
    // 只针对商品主图做降级开关
    const shouldWatermarkHidden = getters?.detailWatermarkSwitch === 'off'
    const config = {
      showCarouselsWatermark: IS_WATER_GOODS && carouselsAbt && !shouldWatermarkHidden, // 主图水印
      showCommentWatermark: commentAbt, // 评论区水印
      debugger: {
        Mastertest,
        Imagewatermark,
        tspLabelPool,
      }
    }
    return config
  },
  // 保留商品配置信息
  retentionPositionConfig(state, getters) {
    const { tsp = {} } = state.productIntroData
    const { isAllSoldOut, isCurrentSoldOut, screenAbtConfig, language } = getters
    const { SameLabel = {} } = screenAbtConfig || {}
    const contentText = SameLabel?.param?.labeltype == 'label2' ? language.SHEIN_KEY_PC_28750 : language.SHEIN_KEY_PC_25737
    return getRetentionConfig({
      position: SameLabel?.param?.SameLabel || '',
      tsp,
      isSoldOut: isAllSoldOut || isCurrentSoldOut,
      contentText
    })
  },
  // quickship强化实验
  quickshipStrongAbt(state, getters) {
    const { screenAbtConfig } = getters
    let { Quickshipdetailbannershow = '', Quickshipdetailfloorshow = '', Buynowquickship = '' } = screenAbtConfig?.quickshipdetailshow?.p || {}
    const showQuick = screenAbtConfig?.DetailPageQuickShipShow?.param == 'DetailShowQuick'
    if(!showQuick) {
      return {
        beltQuickshipStyle: false,
        beltNDeliveryStyle: false,
        floorQuickshipStyle: false,
        floorNDeliveryStyle: false,
        showBuynowquickship: false,
        floorNDeliveryNumber: 0,
        beltNDeliveryNumber: 0
      }
    }
    const floorQuickshipStyle = Quickshipdetailfloorshow === 'quickship' // 物流quickship强化样式
    const floorNDeliveryStyle = +(Quickshipdetailfloorshow) > 0 // 物流楼层n天达
    const showBuynowquickship = Buynowquickship === 'Show'
    // 初始化实验值  腰带N达具体值||腰带强化qs实验命中结果 和独立qs楼层互斥||腰带N达实验命中结果 和独立qs楼层互斥||腰带强化qs实验命中结果 和独立qs楼层共存||腰带N达实验命中结果 和独立qs楼层共存
    let beltNDeliveryNumber = 0, beltQuickshipInde = false, beltQuickshipNindex = false, beltQuickshipCoex = false, beltQuickshipNCoex = false
    // 实验结果提取
    beltQuickshipInde = Quickshipdetailbannershow === 'quickshipinde'
    beltQuickshipCoex = Quickshipdetailbannershow === 'quickshipcoex'
    const beltQuickshipIndeArr = Quickshipdetailbannershow.split('_index')
    if(beltQuickshipIndeArr.length > 1) {
      beltQuickshipNindex = true
      beltNDeliveryNumber = +(beltQuickshipIndeArr[0])
    }
    const beltQuickshipcoexArr = Quickshipdetailbannershow.split('_coex')
    if(beltQuickshipcoexArr.length > 1) {
      beltQuickshipNCoex = true
      beltNDeliveryNumber = +(beltQuickshipcoexArr[0])
    }

    return {
      beltQuickshipStyle: beltQuickshipInde ||  beltQuickshipCoex, // 腰带 qs强化最终结果
      beltNDeliveryStyle: beltQuickshipNindex || beltQuickshipNCoex, // 腰带 N达最终结果
      beltNDeliveryNumber, // 腰带 N达 最终具体值
      floorQuickshipStyle, // 物流楼层qs 实验命中结果
      floorNDeliveryStyle, // 物流楼层 N达 实验命中结果
      showBuynowquickship,
      floorNDeliveryNumber: +(Quickshipdetailfloorshow) ? +(Quickshipdetailfloorshow) : 0, // 物流楼层 N达 具体值
    }
  },
  qsTimelinessText(state, getters) {
    const isEmpty = (value) => {
      return (
        value === '' ||
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0)
      )
    }   
    const { language } = getters
    let shippingDetail = state.productIntroData?.shippingDetail || {}
    const { screenAbtConfig } = getters
    let { QSaddinformation } = screenAbtConfig?.QSinformation?.p || {}
    const { qs_type, qs_days, qs_total_aging_date, qs_date_type, qs_percent = '', quick_ship_time, qs_aging_date } = shippingDetail
    let qsAbtType = QSaddinformation
    if(!['A', 'B', 'C'].includes(qsAbtType)) return ''
    let qsTimelinessInfo = ''
    switch (qsAbtType) {
      // {0} arrive in {1} business days / {0} arrive in {1} days
      case 'A':
        if (isEmpty(qs_date_type) || isEmpty(qs_percent) || isEmpty(qs_days)) return ''
        qsTimelinessInfo = qs_date_type == 1
          ? template(`${qs_percent}%`, qs_days, language.SHEIN_KEY_PC_31366)
          : template(`${qs_percent}%`, qs_days, language.SHEIN_KEY_PC_31367)
        break
      // {0} arrive in {1}
      case 'B':
        if (isEmpty(qs_percent) || isEmpty(qs_total_aging_date)) return ''
        qsTimelinessInfo = template(`${qs_percent}%`, qs_total_aging_date, language.SHEIN_KEY_PC_31368)
        break
      // Arrive in {0} business days / Arrive in {0} days / Arrive in {0}
      case 'C':
        if (
          isEmpty(qs_type) ||
          (qs_type == 1 && (isEmpty(qs_date_type) || isEmpty(quick_ship_time))) ||
          (qs_type == 2 && isEmpty(qs_aging_date))
        ) return ''
        qsTimelinessInfo = qs_type == 1
          ? qs_date_type == 1
            ? template(quick_ship_time, language.SHEIN_KEY_PC_31369)
            : template(quick_ship_time, language.SHEIN_KEY_PC_31370)
          : template(qs_aging_date, language.SHEIN_KEY_PC_31371)
        break
    }
    return qsTimelinessInfo
  },
  // 运输时效 yyyy-mm-dd 
  quickshipAgingText(state, getters) {
    const { language } = getters
    let shippingDetail = state.productIntroData?.shippingDetail || {}
    const { qs_type, qs_date_type, quick_ship_time = '', shippingMethods = [] } = shippingDetail
    let quickShipTime = ''
    if(!quick_ship_time) return quickShipTime
    if(qs_type == 1) {
      const langText = qs_date_type == 0 ? language.SHEIN_KEY_PC_28439 : language.SHEIN_KEY_PC_29464
      quickShipTime = template(quick_ship_time, langText)
    } else {
      const current_time = shippingMethods?.[0]?.current_time || new Date().getTime() // 当前时间
      quickShipTime = formatTime({ expect_time: quick_ship_time, current_time }, false, '', true) // 处理日期
    }
    return quickShipTime
  },
  // 选中sku的状态信息
  currentSkuStatus(state, getters) {
    const { skuInfo } = state
    let { mallCode } = getters
    const skuCode = skuInfo?.sku_code
    return {
      hasSelectedSku: !!skuCode, // 是否选中sku
      hasSelectedSkuSurpportQuickship: skuInfo?.mall?.[mallCode]?.sku_quick_ship  // 选中的sku是否支持quickship
    }
  },
  // sku是否支持quickship
  isSupportQuickship(state, getters, rootState, rootGetters) {
    const { quickshipAgingText, currentSkuStatus } = getters
    const { quickShip } = state
    const { hasSelectedSku, hasSelectedSkuSurpportQuickship } = currentSkuStatus
    const sizeComposeData = rootGetters['Size/sizeComposeData'] || {}
    const { mallCode } = getters
    const skuList = sizeComposeData?.saleAttrList?.skuList || []
    // 部分sku支持quickship
    const isSomeSupportQuickshipBase = skuList.some(sku => sku?.mall?.[mallCode]?.sku_quick_ship)
    // 部分sku支持quickship 同时物流接口要返回qs时效
    // 二期qs时效取值跟一期不一样，业务上保证时效值一致,此时quickshipAgingText和qsTimelinessText都会有值
    const isSomeSupportQuickship = skuList.some(sku => sku?.mall?.[mallCode]?.sku_quick_ship) && quickshipAgingText
    // 所有sku支持quickship
    const isAllSupportQuickship = skuList.length ? skuList.every(sku => sku?.mall?.[mallCode]?.sku_quick_ship) : false
    // 精确到sku是否支持quickship
    const supportQuickshipSence = quickshipAgingText && (isAllSupportQuickship || hasSelectedSkuSurpportQuickship || (isSomeSupportQuickship && !hasSelectedSku))
    return {
      isSomeSupportQuickshipBase,
      isSomeSupportQuickship,
      isAllSupportQuickship,
      hasSelectedSku,
      hasSelectedSkuSurpportQuickship,
      supportQuickshipSence,
      hasPartSupportQuickship: isSomeSupportQuickship && !isAllSupportQuickship, // 此字段满足首次落地 skc下的qs状态不一样，并且未选中sku的业务场景判断
      currentQuickship: quickShip == 1 // 这个字段首次落地就是1 不能满足 skc下的qs状态不一样， 并且未选中sku的业务场景判断
    }
  },
  // 腰带quickship强化
  beltQuickshipStrongConfig(state, getters) {
    const quickshipStrongConfigResult = {
      strongStyle: false,
      strongText: '',
      onlyFreeShiping: false,
      keyinformation: '' // 埋点用
    }
    const { language, isSupportQuickship, freeShippingInfo, quickshipStrongAbt, qsTimelinessText } = getters
    const { freeShippingStyle = {} } = freeShippingInfo || {}
    const isOnlyStrip = freeShippingStyle.isOnlyStrip || false // 腰带免邮出来最终逻辑
    const { supportQuickshipSence } = isSupportQuickship
    const { beltQuickshipStyle, beltNDeliveryStyle, beltNDeliveryNumber } = quickshipStrongAbt // 腰带quickship强化样式 || 腰带n天达
    const dateText = supportQuickshipSence && qsTimelinessText ? `(${qsTimelinessText})` : ''
    // quickship强化样式 || n天达 文案处理
    if(beltQuickshipStyle || beltNDeliveryStyle) {
      const commonText = beltQuickshipStyle ? language.SHEIN_KEY_PC_30412 : template(beltNDeliveryNumber, language.SHEIN_KEY_PC_30413 || '{0} Days Delivery')
      if(isOnlyStrip && supportQuickshipSence) { // quickship和免邮共存
        quickshipStrongConfigResult.strongStyle = true
        quickshipStrongConfigResult.strongText = `${language.SHEIN_KEY_PC_28938} & ${commonText}`
        quickshipStrongConfigResult.strongText = dateText ? `${language.SHEIN_KEY_PC_28938} & ${commonText} ${dateText}` : `${language.SHEIN_KEY_PC_28938} & ${commonText}`
        quickshipStrongConfigResult.keyinformation = beltQuickshipStyle ? 4 : 5
      } else if(supportQuickshipSence) { // 只有quickship
        quickshipStrongConfigResult.strongStyle = true
        quickshipStrongConfigResult.strongText = `${commonText} ${dateText}`
        quickshipStrongConfigResult.keyinformation = beltQuickshipStyle ? 2 : 3
      } else if(isOnlyStrip) { // 只有免邮
        quickshipStrongConfigResult.strongStyle = true
        quickshipStrongConfigResult.onlyFreeShiping = true
        quickshipStrongConfigResult.strongText = language.SHEIN_KEY_PC_30411
        quickshipStrongConfigResult.keyinformation = 1
      }
    }
    return quickshipStrongConfigResult
  },
  // 物流楼层 quickship强化 通用文案  可供size quickship tips使用
  deliveryFloorQuickshipTitle(state, getters) {
    const { language, quickshipStrongAbt, isSupportQuickship } = getters
    const { isSomeSupportQuickship } = isSupportQuickship
    const { floorQuickshipStyle, floorNDeliveryStyle, floorNDeliveryNumber } = quickshipStrongAbt // 物流楼层quickship强化样式 || 物流楼层n天达
    const { SHEIN_KEY_PC_30412, SHEIN_KEY_PC_30413 } = language
    let text = ''
    if(isSomeSupportQuickship && (floorQuickshipStyle || floorNDeliveryStyle)) { // 命中quickship增强样式
      text = floorQuickshipStyle ? SHEIN_KEY_PC_30412 : template(floorNDeliveryNumber, SHEIN_KEY_PC_30413 || '{0} Days Delivery')
    }
    return text
  },
  // buy box quickship强化 通用文案 
  buyBoxFloorQuickshipTitle(state, getters) {
    const { language, quickshipStrongAbt } = getters
    const { floorNDeliveryStyle, floorNDeliveryNumber } = quickshipStrongAbt // buy box quickship强化样式 || 物流楼层n天达
    const { SHEIN_KEY_PC_30412, SHEIN_KEY_PC_30413 } = language
    let text = SHEIN_KEY_PC_30412
    if( floorNDeliveryStyle) { // 命中quickship增强样式
      text = template(floorNDeliveryNumber, SHEIN_KEY_PC_30413 || '{0} Days Delivery')
    }
    return text
  },
  // 物流弹窗 quickship强化 N达文案 N命中状态
  deliveryDialogNInfo(state, getters) {
    const { language, quickshipStrongAbt, isSupportQuickship } = getters
    const { isSomeSupportQuickship } = isSupportQuickship
    const { floorNDeliveryStyle, floorNDeliveryNumber } = quickshipStrongAbt // 物流楼层quickship强化样式 || 物流楼层n天达
    const { SHEIN_KEY_PC_30412, SHEIN_KEY_PC_30413, SHEIN_KEY_PC_30661, SHEIN_KEY_PC_31298 } = language
    let deliveryDialogNTitle = SHEIN_KEY_PC_30412
    let deliveryDialogNTips = template(`<span style="color:#198055">${SHEIN_KEY_PC_30412}</span>`, SHEIN_KEY_PC_31298)
    if(isSomeSupportQuickship && floorNDeliveryStyle) { // 是否命中n天达 也要有qs时效
      deliveryDialogNTitle = template(floorNDeliveryNumber, SHEIN_KEY_PC_30413 || '{0} Days Delivery')
      deliveryDialogNTips = template(`<span style="color:#198055">${deliveryDialogNTitle}</span>`, SHEIN_KEY_PC_30661 || 'This item is eligible for {0}')
    }
    return {
      deliveryDialogNTitle,
      deliveryDialogNTips, 
    }
  },
  deliveryStrongTitleSection(state, getters){ // 物流楼层quickship强化 免邮文案title
    const { deliveryFloorQuickshipTitle } = getters
    const { quickShip } = state
    return deliveryFloorQuickshipTitle && quickShip == 1 ? deliveryFloorQuickshipTitle : ''
  },
  // 商品免邮
  isProductFreeShipping(state) {
    const goodsFreeShippingInfo = state.hotModules.shipping?.goodsFreeShippingInfo || state.productIntroData?.goodsFreeShippingInfo || {}
    let { rule_id = 0 } = goodsFreeShippingInfo
    return rule_id > 0
  },
  // 活动免邮
  isPromotionFreeShipping(state, getters) {
    // 当 is_support_free_shipping 不为 0  且  aging_is_show 不为0  ，则代表命中免邮，其中：
    // 当 has_zero_threshold = 1 并且 fall_amount=0表示无门槛免邮，
    // 当 has_zero_threshold = 0，则判断运费，starting_price=0 且 step_price=0，则代表无门槛包邮。
    // 当 has_zero_threshold = 0 且 fall_amount不等0 则代表有门槛免邮，免邮门槛金额=fall_amount
    let promotionFreeShippingFlag = false
    let shippingMethods = state.productIntroData?.shippingDetail?.shippingMethods || []
    if (shippingMethods.length) {
      for (let i of shippingMethods) {
        let { is_support_free_shipping, aging_is_show, has_zero_threshold, full_amount, starting_price, step_price } = i
        if (is_support_free_shipping != 0 && aging_is_show != 0) {
          if (has_zero_threshold == 1 && full_amount?.amount == 0) {
            i.isPromotionFreeShipping = true
            promotionFreeShippingFlag = true
          }
          if (has_zero_threshold == 0 && starting_price == 0 && step_price == 0) {
            i.isPromotionFreeShipping = true
            promotionFreeShippingFlag = true
          }
          if (has_zero_threshold == 0 && full_amount?.amount != 0) {
            let estiamtedPrice = getters.getEstimatedInfo?.value?.amount
            // let salePrice = 0
            // if (state.productIntroData?.isPaidUser) {
            //   salePrice = getters.sheinClubPromotionInfo?.price?.amount
            // } else if (state.newFlashPromotion?.price?.amount) {
            //   salePrice = state.newFlashPromotion?.price?.amount
            // } else {
            //   salePrice = state.skuInfo?.mall?.[getters.mallCode]?.salePrice?.amount || state.productIntroData?.detail?.mall?.[getters.mallCode]?.salePrice?.amount
            // }
            let price = estiamtedPrice || getters.currSalePrice
            // 免邮活动有门槛，并且存在满减券，并且商品符合满减，那么不展示免邮
            if(!getters.hasDiscountCouponMatch && Number(full_amount?.amount) <= Number(price)){
              promotionFreeShippingFlag = true
            }
            i.isPromotionFreeShipping = !getters.hasDiscountCouponMatch && Number(full_amount?.amount) <= Number(price)
          }
        }else {
          i.isPromotionFreeShipping = false
        }
      }
    }
    // flag用于判断只要某一种运输方式满足活动免邮，就返回true
    return promotionFreeShippingFlag
  },
  currSalePrice(state, getters){
    let salePrice = 0
    if (getters.isPaidUser && getters.sheinClubPromotionInfo?.price?.amountWithSymbol) {
      salePrice = getters.sheinClubPromotionInfo?.price?.amount
    } else if (state.newFlashPromotion?.price?.amount) {
      salePrice = state.newFlashPromotion?.price?.amount
    } else {
      salePrice = state.skuInfo?.mall?.[getters.mallCode]?.salePrice?.amount || state.productIntroData?.detail?.mall?.[getters.mallCode]?.salePrice?.amount
    }
    return salePrice
  },
  hasDiscountCouponMatch(state, getters){
    let couponList = getters.originCouponList || []
    let hasMatch = false
    couponList.find(item => {
      if (
        ![5, 6, 9].includes(Number(item.apply_for)) &&
        item.coupon_type_id == 1 &&
        item.coupon_rule?.[0].min?.amount &&
        Number(item.coupon_rule?.[0].min?.amount) <= Number(getters.currSalePrice)
      ) {
        hasMatch = true
        return true
      }
    })
    return hasMatch
  },
  // 包邮展示配置 ABT 信息
  freeShippingInfo(state, getters) {
    let { screenAbtConfig = {}, goodsFreeShippingInfo } = state.productIntroData
    // 兜底取值
    let { itemfreeshipping: { param: { freestyle = 'strip', itemfreeshipping = 'none', freestyleactivity = 'Notshow' } = {} } = {} } = screenAbtConfig || {}

    /**
     * 免邮逻辑 start
     * 1. 包邮接口命中 - 直接免邮
     * 2. shippingMethods 免邮
     * 3. 免邮券
     *   a. freestylecoupon abt 命中不展示就不用判断券情况下的免邮了不展示，在券逻辑处已过滤免邮券
     *   b. 无门槛免邮
     *   c. 金额免邮
     *     - 到手价金额对比
     *     - 销售价金额对比
    */
    let isFreeShippingCouponForAutoGet = false // 自动领券
    let matchFreeShippingCoupons = []

    let freeShippingCouponsMethods = []
    let shippingMethods = state.productIntroData?.shippingDetail?.shippingMethods || []
    let allMethods = shippingMethods.map(item => item.transport_type)
    let { isProductFreeShipping, isPromotionFreeShipping, quickshipStrongAbt, isSupportQuickship  } = getters
    const { beltQuickshipStyle, beltNDeliveryStyle } = quickshipStrongAbt // 腰带quickship强化样式 || 腰带n天达
    const { supportQuickshipSence } = isSupportQuickship
    const quickshipStrongShow = (beltQuickshipStyle || beltNDeliveryStyle) && supportQuickshipSence
    let isCouponFreeShipping = false
    let estiamtedPrice = getters.getEstimatedInfo?.value?.amount
    let currentPrice = estiamtedPrice || getters.currSalePrice
    if (!isProductFreeShipping && !isPromotionFreeShipping && !getters.hasDiscountCouponMatch) {
      // 免邮券券的免邮要自动领券
      let couponList = getters.currCouponList || []
      if (couponList.length) {
        /* 
          券类型：wiki.pageId=1311701036
          减免最低邮费券 apply_for=5
          邮费全免 apply_for=6
          指定运输方式减运费券 apply_for=9
        */
        let freeShippingCoupons = couponList.filter(coupon => [5, 6].includes(Number(coupon.apply_for)) || (coupon.apply_for == 9 && coupon.shipping_discount_type == 0))
        if (freeShippingCoupons.length) {
          for (let coupon of freeShippingCoupons) {

            // 免邮价格门槛
            let couponPriceThreshold = coupon.coupon_rule?.[0].min?.amount

            // 免邮券上限
            let couponPriceUpper = coupon.coupon_rule?.[0].max?.amount || Infinity
            // 判断免邮券是否可叠加
            let isStackable = coupon.apply_for == 9 && [53, 55, 61].includes(+coupon.real_type_id)  
            if(!isStackable && estiamtedPrice) continue // 如果不可以叠加 且有到手价时，当作不是优惠券免邮。 没有到手价，取售价为Y
            // 如果可以叠加,则有到手价时取到手价为Y,没有时取售价为Y
            // let salePrice = 0
            // if (state.productIntroData?.isPaidUser) {
            //   salePrice = getters.sheinClubPromotionInfo?.price?.amount
            // } else if (state.newFlashPromotion?.price?.amount) {
            //   salePrice = state.newFlashPromotion?.price?.amount
            // } else {
            //   salePrice = state.skuInfo?.mall?.[getters.mallCode]?.salePrice?.amount || state.productIntroData?.detail?.mall?.[getters.mallCode]?.salePrice?.amount
            // }
            // 当前商品价格（到手价 > 销售价） 有到手价一定达不到免邮券的价格门槛
            if (Number(currentPrice) >= Number(couponPriceThreshold) && Number(currentPrice) <= couponPriceUpper) {
              isCouponFreeShipping = true
              isFreeShippingCouponForAutoGet = true
              matchFreeShippingCoupons.push(coupon)
              if(coupon.apply_for == 5 && getters.lowestPricesShippingMethods[0]?.transport_type){
                // 减免最低运费券 取运费最低的运输方式
                freeShippingCouponsMethods.push(getters.lowestPricesShippingMethods[0].transport_type)
              }else if(coupon?.shipping_list && coupon?.shipping_list.length > 0){
                // shipping_list 获取券对应的运输方式
                coupon?.shipping_list.forEach(item => {
                  freeShippingCouponsMethods.push(item.shipping_method_code)
                })
              }else{
                // 该券没有指定运输方式，则默认所有运输方式都可以使用
                freeShippingCouponsMethods = allMethods
              }
            }
          }
          // 运输方式去重
          freeShippingCouponsMethods = [...new Set(freeShippingCouponsMethods)]
        }
      }
    }
    let isFreeShippin = isProductFreeShipping || isPromotionFreeShipping || isCouponFreeShipping
    let isShowFreeShippingTag = ['freeremind', 'free'].includes(itemfreeshipping) && isFreeShippin

    const isFreeStyleActivity = getters.isProductFreeShipping ? true : freestyleactivity === 'Show'

    let freeShippingStyle = {
      isStrip: (isShowFreeShippingTag && freestyle === 'strip' && isFreeStyleActivity) || quickshipStrongShow,
      isOnlyStrip: isShowFreeShippingTag && freestyle === 'strip' && isFreeStyleActivity, // 腰带免邮出来逻辑，供quickship强化判断使用
      isAddbag: isShowFreeShippingTag && freestyle === 'addbag' && isFreeStyleActivity,
      isButton: isShowFreeShippingTag && freestyle === 'button' && isFreeStyleActivity
    }

    // 商品免邮&券免邮对应的运输方式
    let freeShippingMethods = isCouponFreeShipping ? freeShippingCouponsMethods.join(',') : goodsFreeShippingInfo?.join_transport_types || ''

    /** 免邮逻辑 end */
    const res = {
      // 是否展示延迟发货提示
      isShowDelayWarn: ['freeremind', 'remind'].includes(itemfreeshipping),
      currentPrice,
      isFreeShippin,
      freeShippingStyle,
      isFreeShippingCouponForAutoGet,
      matchFreeShippingCoupons,
      isProductFreeShipping,
      isPromotionFreeShipping,
      freeShippingMethods,
      isCouponFreeShipping
    }
    return res
  },
  // 按照最低运费排序物流方式
  lowestPricesShippingMethods (state) {
    let shippingMethods = state.productIntroData?.shippingDetail?.shippingMethods || []
    let result = JSON.parse(JSON.stringify(shippingMethods))
    result.forEach(item => {
      if (item.starting_price_obj && item.last_price_obj) {
        item.lowestShippingPrices = Number(item.starting_price_obj.amount) > Number(item.last_price_obj.amount) ? item.last_price_obj : item.starting_price_obj
      }
    })
    /* 
      待解决TODO：
      1. 外露使用shippingMethods，物流详情使用shippingPolicy，但是他们俩的排序方式有差异，需要统一
      2. 如果存在多个运费相同的，app获取时效最快的，但是这里没有做处理，需要统一
    */
    let createComparionFun = (propertyName, amount) => {
      return (object1, object2) => {
        const value1 =
            (object1[propertyName] && object1[propertyName][amount]) || 0
        const value2 =
            (object2[propertyName] && object2[propertyName][amount]) || 0
        if (value1 < value2) {
          return -1
        } else if (value1 > value2) {
          return 1
        } else {
          return 0
        }
      }
    } 
    result.sort(createComparionFun('lowestShippingPrices', 'amount'))
    return result
  },
  // 店铺关注额外埋点信息
  storeFollowExtAnalysisInfo(state) {
    const { storeRate, storeFollowInfo } = state
    const { salesVolume, followCount } = storeFollowInfo || {}
    const brandInfo = [
      `ratings_${(storeRate * 1 || 0).toFixed?.(2)}`,
      (salesVolume && salesVolume !== '0')
        ? `sold_in_30d_${salesVolume}`
        : '-',
      (followCount && followCount !== '0')
        ? `followers_${followCount}`
        : '-',
    ]
    return {
      abtAnaInfo: storeFollowInfo?.abtAnaInfo || '',
      brandInfo: brandInfo.join('`') || '',
      // 默认入口不存在本地卖家和优质卖家标签
      brandLabel: '',
    }
  },
  getEstimatedInfo (state, getters) {
    let { screenAbtConfig, mallCode, promotionInfo, flashType, sheinClubPromotionInfo, isCurrentSoldOut, complianceMode, exclusivePromotionPrice, complianceModeDe, isMatchCateId } = getters
    let { productIntroData, SiteUID, skuInfo, newFlashPromotion } = state
    if (!skuInfo?.sku_code && state.skcEstimatedInfo) return state.skcEstimatedInfo
    const { estimatedPriceConfig = [] } = productIntroData?.apolloConfig?.sizeTipsConfig || {}
    let showNoSatisfied = true
    if (!isMatchCateId) showNoSatisfied = false
    if (screenAbtConfig.collestrategy?.p?.colldisplayrest === 'popup') showNoSatisfied = false
    let result = getEstimatedInfo({
      abtValue: {
        EstimatedPrice: screenAbtConfig?.EstimatedPrice,
        EstimatedShowType: screenAbtConfig?.EstimatedShowType?.p?.EstimatedShowType,
        EstimatedNothreShowType: screenAbtConfig?.EstimatedNothreShowType?.p?.EstimatedNothreShowType,
        Estimatedclubshow: screenAbtConfig?.Estimatedclubshow?.p?.Estimatedclubshow,
        Estimatedstrategy: screenAbtConfig?.EstimatedNothreShowType?.p?.Estimatedstrategy,
        sheinappwordsize: screenAbtConfig?.sheinappwordsize?.p?.sheinappwordsize,
      },
      mallCode: mallCode,
      detail: productIntroData.detail,
      skuInfo: skuInfo,
      isPaidUser: productIntroData.isPaidUser,
      showSheinClub: getters.showSheinClub,
      exclusivePromotionPrice: exclusivePromotionPrice,
      flashType: flashType,
      newFlashPromotion,
      promotionInfo,
      sheinClubPromotionInfo,
      estimatedPriceConfig,
      SiteUID,
      isSoldOut: isCurrentSoldOut,
      showNoSatisfied,
      closeEstimatedAndAbPrice: productIntroData.closeEstimatedAndAbPrice,
      complianceMode,
      complianceModeDe,
      parentCats: state.productIntroData?.parentCats
    })
    return result
  },
  // 评论是否有筛选行为
  // hasCommentFilter(state) {
  //   // 触发筛选行为 指的是 用户筛选skc size 图片 星级 关键词
  //   const { commentInfo } = state
  //   if (commentInfo.currSizeIndex != -1) return true
  //   if (commentInfo.reviewsIndex != -1) return true
  //   return ['color_id', 'comment_rank', 'tag_id'].some(k => !!commentInfo[k])
  // },
  newUserCouponInfo(state, getters){
    const { screenAbtConfig } = getters
    const abtNewCoupon = screenAbtConfig?.NewCoupon?.p?.NewCoupon
    if(!['New1', 'New2'].includes(abtNewCoupon)){
      return {}
    } 

    let newUserCoupon = null
    const newUserRange = [2, 17, 23, 56, 65]
    const hasEstimated = getters.getEstimatedInfo?.value?.amountWithSymbol
    if(hasEstimated){
      // 如果计入了到手价，那就判断计入到手价的优惠券，是否是新人专享券
      const optimalCouponList = getters.getEstimatedInfo?.optimalCouponList || []
      const newUserOptimalList = optimalCouponList.filter(it =>{
        return newUserRange.includes(+it.type_id)
      })
      if(newUserOptimalList.length){
        // 如果是，则强化新人专享券
        const bestNewuserCoupon = compareNewuserCoupon(newUserOptimalList)  // 如果超过1张新人全被计入到手价，则去降价比例大的优化券进行强化
        const couponInfo = getters.getEstimatedInfo?.coupons.find(
          item => item.coupon_code === bestNewuserCoupon?.coupon_code
        )
        newUserCoupon = couponInfo ? {
          ...bestNewuserCoupon,
          ...couponInfo,
          optimal_rule: bestNewuserCoupon?.rule,
          isForEstimated: true
        } : {}
      }else{
        // 如果不是，则不强化新人专享券（即使此时商品有新人券专享券，也不需要强化处理）
        newUserCoupon = null
      }
    }else{
      // 如果没有计入到手价
      // 就判断商品是否有新人专享券，如果有就进行强化；如果没有就不做处理
      const couponList = getters.getEstimatedInfo?.coupons?.filter(it => newUserRange.includes(+it.type_id)).sort((a, b)=>{
        const ruleA = a?.rule?.[0]?.min?.amount
        const ruleB = b?.rule?.[0]?.min?.amount
        return ruleA - ruleB  // 根据门槛排序
      }) || []
      // 强化门槛最低的
      newUserCoupon = couponList?.[0]
      if(newUserCoupon){
        // 异常情况兜底：
        // 如果有多张门槛一样的，取降价比例/金额最多的
        // 如果有多张门槛一样的，并且一张是满减一张是满折，取满折
        const minThreshold = newUserCoupon?.rule?.[0]?.min?.amount
        let sameThresholdList = couponList.filter(item => item?.rule?.[0]?.min?.amount == minThreshold)
        if(sameThresholdList.length > 1){
          newUserCoupon = compareNewuserCoupon(sameThresholdList)
        }  
      }
    }

    // 1: 减元券，2/5:折扣券
    let isMoney = newUserCoupon?.rule_dimension == 1
    const newUserCouponRule = newUserCoupon?.optimal_rule ? newUserCoupon?.optimal_rule : newUserCoupon?.rule?.[0]
    const newUserDiscount = isMoney ? newUserCouponRule?.value_amount?.amountWithSymbol : (((1 - newUserCouponRule?.value) * 100).toFixed(0) + '%' )
    return {
      newUserCoupon,
      newUserCouponRule,
      newUserDiscount
    }
  },
  // 隐藏one-size
  hideOneSize(state, getters) {
    const { parentCats } = state.productIntroData || {}
    const { Onesizeno } = getters.screenAbtConfig || {}
    if (Onesizeno?.param?.Onesizeno === 'noshow') {
      // 屏蔽one-size的商城ID
      const shieldOneSizeCatId = [1864, 2032, 2273, 3195, 3634, 3637, 3657]
      const arr = [parentCats]
      for (let i = 0; i < arr.length; i++) {
        const { cat_id, children } = arr[i] || {}
        if (children?.length) arr.push(...children)
        const catId = +cat_id
        if (shieldOneSizeCatId.includes(catId)) {
          return true
        }
      }
    }
    return false
  },
  tspConfigCombo(state, getters) {
    return {
      hitLowestPrice: getters.getLowestPrice
    }
  },
  getLowestPrice(state) {
    const { tsp = {}, getPrice = {} } = state.productIntroData
    // 需求文档: FR-15507
    // 近14天调价: 4018
    // 近30天最低价: 3864 | 近60天最低价: 3865 | 近90天最低价: 3866
    // 近30天最高价: 4015 | 近60天最高价: 4016 | 近90天最高价: 4017
    const d1 = tsp?.['4018'] || ''
    const x1 = +(tsp?.['3864'] || ''),
          z1 = +(tsp?.['4015'] || '')
    const x2 = +(tsp?.['3865'] || ''),
          z2 = +(tsp?.['4016'] || '')
    const x3 = +(tsp?.['3866'] || ''),
          z3 = +(tsp?.['4017'] || '')
    const y = +getPrice?.salePrice?.usdAmount || ''

    const hitThirtyLowest = !!(y <= x1 && y < z1 && d1 > 1)
    const hitSixtyLowest = !!(y <= x2 && y < z2 && d1 > 1)
    const hitNinetyLowest = !!(y <= x3 && y < z3 && d1 > 1)

    if (hitNinetyLowest) return '90'
    if (hitSixtyLowest) return '60'
    if (hitThirtyLowest) return '30'
    return ''
  },
  lowestPriceTipsConfig(state, getters) {
    const { screenAbtConfig, language, tspConfigCombo } = getters
    const { pricestatues = {} } = screenAbtConfig
    const { lowestprice } = pricestatues?.p || {}
    const { hitLowestPrice = '' } = tspConfigCombo || {}
    if (!hitLowestPrice) return { show: false }
    return {
      show: lowestprice == 'show',
      hitLowestPrice,
      contentText: template(hitLowestPrice, language.SHEIN_KEY_PC_27675),
    }
  },
  /**
     * @returns { Object }
     *  @param { Boolean } show 是否显示
     *  @param { String } text 文案内容
     *  @param { String } type 文案类型 
     *                          [retentionBelt 保留款腰带 / lowestBelt x天最低价腰带 / followBelt 近期低价腰带] 
     *                          [retentionText 保留款文本 / lowestText x天最低价文本 / followText 近期低价文本] 
     */
  bestDealConfig(state, getters) {
    // 这里聚合了 保留款｜x天最低价｜近期低价  优先级： 「保留款」>「x天最低价」 >「近期降价」
    const { screenAbtConfig = {}, retentionPositionConfig = {}, isShowFollowBelt = false, lowestPriceTipsConfig = {}, language = {}, isAllSoldOut = false, isCurrentSoldOut = false } = getters  
    const { pricestatues = {} } = screenAbtConfig
    const { showtype = 'banner' } = pricestatues?.p || {}
    if (isAllSoldOut || isCurrentSoldOut) return { show: false }
    let text = '',
        tipsType = '', // 保留款 2｜x天最低价 1｜近期低价 3
        rightText = '',
        type = null
  
    if (retentionPositionConfig.show) {
      text = retentionPositionConfig.contentText
      rightText = retentionPositionConfig.rightText
      tipsType = 2
      type = 'retention'
    } else if (lowestPriceTipsConfig.show) {
      text = lowestPriceTipsConfig.contentText
      tipsType = 1
      type = 'lowest'
    } else if (isShowFollowBelt) {
      text = language.SHEIN_KEY_PC_26629
      tipsType = 3
      type = 'follow'
    } else return { show: false }
  
    if ((showtype == 'banner' || !showtype) && tipsType !== 1) type = type + 'Belt'
    if (showtype == 'pricebottom' || tipsType == 1) type = type + 'Text'
  
  
    return {
      text,
      type,
      tipsType,
      rightText
    }
  },
  estimatedAbtest(){ // TODO 为什么要放这里
    return abtUserAnalysis({ posKeys: 'EstimatedPrice' })?.sa
  },
  isShowAddMore(state, getters){
    // 实验参数E_Price_Cal = Non_Threshold；
    // 实验参数E_Price_AddOnItem = AddOnItem；
    // 商品价格未达到参与计算到手价的券的使用门槛. satisfied == 0；
    // 当前券是否符合下面的三种券 （或IS_USE_NEW_ADD_MORE开关为true，其他条件也满足时，此时全都跳凑单弹窗）
    const { screenAbtConfig } = getters
    const { E_Price_AddOnItem, E_Price_Cal } = screenAbtConfig?.EstimatedPrice?.p
    const {
      apply_for,
      rule_dimension,
      satisfied,
      coupon_dimension
    } = getters.optimalCoupon || {}
    const isRightRange = coupon_dimension == 1 && 
      (
        ( apply_for == 1 && rule_dimension == 5) || 
        ((apply_for == 2 || apply_for == 3) && (rule_dimension == 2 || rule_dimension == 5))
      )
    return E_Price_AddOnItem === 'AddOnItem'
      && E_Price_Cal === 'Non_Threshold' 
      && satisfied == 0
      && (state.isUseNewAddMore || isRightRange)
  },
  optimalCoupon(state, getters){
    let couponList = getters.getEstimatedInfo?.coupons
    let bestCoupon = couponList?.find(item=>{
      return item.coupon_code === getters.getEstimatedInfo?.optimalCoupon?.coupon_code
    })
    return bestCoupon ? {
      ...getters.getEstimatedInfo?.optimalCoupon,
      ...bestCoupon,
      optimal_rule: getters.getEstimatedInfo?.optimalCoupon?.rule
    } : {}
  },
  optimalCouponList(state, getters){
    return getters.getEstimatedInfo?.optimalCouponList || []
  },
  discountPercent(state, getters){
    // 1: 减元券，2/5:折扣券
    let isMoney = getters.optimalCoupon?.rule_dimension == 1
    return isMoney ? getters.optimalCoupon?.optimal_rule?.value_amount?.amountWithSymbol : (((1 - getters.optimalCoupon?.optimal_rule?.value) * 100).toFixed(0) + '%' ) 
  },
  isShowCountdown(state, getters){
    const flashType = getters.getEstimatedInfo?.promotionInfo?.flashType
    const normalFlashStyle = [1, 5, 6].includes(flashType) || (flashType == 4 && state.newFlashPromotion)
    const activityTypeId = getters.getEstimatedInfo?.promotionInfo?.typeId
    const E_Time_Limit = getters.screenAbtConfig?.EstimatedPrice?.p?.E_Time_Limit
    const estimatedPrice = getters.getEstimatedInfo?.value?.amountWithSymbol
    return E_Time_Limit === 'Show' && estimatedPrice && !(activityTypeId == 10 && normalFlashStyle) && getters.isEndTimeIn24 && !getters.getEstimatedInfo?.isAb
  },
  bestActivity(state, getters){
    const bestId = getters.getEstimatedInfo?.promotionInfo?.id
    return getters.promotionInfo?.find(item=>item.id === bestId)
  },
  bestEndTime(state, getters){
    const tenDaysLater = new Date().getTime() / 1000 + 864000
    // const couponEnd = getters.optimalCoupon?.end_time || tenDaysLater
    const couponEndList = getters.optimalCouponList?.map(coupon => {
      return coupon?.end_time
    })
    const couponEnd = Math.min.apply(null, couponEndList) || tenDaysLater
    const activityEnd = getters.bestActivity?.endTimestamp || tenDaysLater
    return couponEnd < activityEnd ? couponEnd : activityEnd
  },
  isEndTimeIn24(state, getters){
    const nowStamp = new Date().getTime()
    const endTimestamp = getters.bestEndTime * 1000
    return endTimestamp - nowStamp > 0 && endTimestamp - nowStamp  < 60 * 60 * 24 * 1000
  },
  taxIncludedText(state, getters) {
    const { cccAllTaxesIncluded } = state.productIntroData || {}
    const { screenAbtConfig = {} } = getters
    const { Taxshowtype } = screenAbtConfig
    return !!['A', 'B'].includes(Taxshowtype?.param?.Taxshowtype) && cccAllTaxesIncluded
  },
  showQuickShip(state, getters) {
    const { productIntroData } = state
    const { screenAbtConfig } = getters
    const { mallCode, detail } = productIntroData
    const status =
      screenAbtConfig?.DetailPageQuickShipShow?.param == 'DetailShowQuick'
    return status && detail?.mall?.[mallCode]?.skcQuickShip
  },
  /**
   * 基于abt显示新品牌模块
   */
  isAbtShowNewStoreBrand(state, getters) {
    return getters.screenAbtConfig.Authenticbrand?.param?.Authenticbrand === 'show'
  },
  // 展示收藏后弹窗推荐
  // isAddWishAfterRec(state, getters){
  //   return getters.screenAbtConfig?.wishsimilar?.p?.wishsimilar === 'show'
  // },
  /**
   * 展示新商卡样式
   */
  newProductCard: (state, getters) => {
    return getters?.screenAbtConfig?.pclistnewCard?.p?.pclistnewCard === 'pcCard'
  },
  sheinClubShippingSiteConfig: (state, getters) => {
    const { privilege_code_list = [], privilege_value_detail = {} } = state.primeShipping
    return {
      FREE_SHIPPING_TIMES: privilege_code_list?.includes('FREE_SHIPPING_TIMES'),
      MULTIPLE_POINTS: privilege_code_list?.includes('MULTIPLE_POINTS'),
      IS_NEW_SHEIN_CLUB_SHIPPING: !!getters.getEstimatedInfo?.isNewSheinClubShipping,
      VALUE_DETAIL: privilege_value_detail
    }
  },
  sheinClubShppingText(state, getters) {
    const { language, screenAbtConfig, sheinClubShippingSiteConfig } = getters
    const { VALUE_DETAIL = {} } = sheinClubShippingSiteConfig
    if (screenAbtConfig.Estimatedclubshow?.p?.shippingclubshow == 'New') {
      const { total_coupon_sheets = 0, coupon_value = 0, coupon_value_with_symbol = '' } = VALUE_DETAIL
      if (total_coupon_sheets && coupon_value) return template(total_coupon_sheets, coupon_value_with_symbol, language.SHEIN_KEY_PC_29257)
      if (total_coupon_sheets) return template(total_coupon_sheets, language.SHEIN_KEY_PC_29258)
    } else {
      return template(language.SHEIN_KEY_PC_22937, language.SHEIN_KEY_PC_23592)
    }
  },
  showSheinClub(state, getters) {
    return getters.screenAbtConfig?.sheinclubprice?.p == 'exposure_prime_price'
  },
  currentMallDetail(state, getters) {
    return state.productIntroData.detail?.mall?.[getters.mallCode]
  },
  isSoldOut(state, getters) {
    return getters.isAllSoldOut || getters.isCurrentSoldOut
  },
  isAllowAutoClaim(state, getters){
    // 非绑定券，则肯定是“加车”，不触发领券；
    // 绑定券，未登录，就是“领取&加车”，帮跳转登陆页；
    // 绑定券，已登录时，券是已领取的，则展示“加车”文案；
    // 绑定券，已登录时，券待领取，则展示“领取 & 加车”。
    // 可能有两张最优券叠加，此时只要一张可以领取，就展示领取按钮
    const { isAfterSsr } = state

    let loginStatus = false
    if (isAfterSsr) {
      loginStatus = isLogin()
    }

    return getters.optimalCouponList.some(coupon => {
      const isBindCoupon = coupon?.is_bind === 1
      const notReceived = coupon?.bind_status != 1
      return isBindCoupon && (!loginStatus || (loginStatus && notReceived))
    })
  },
  currentDrawerProducts(state, getters) {
    const { isShowAddMore, recDrawerAddmoreProducts, recommendDrawerProducts } = getters
    return isShowAddMore ? recDrawerAddmoreProducts : recommendDrawerProducts
  },
  /**
   * 商详pc交互结构优化
   */
  pageStructureAbtStyleInfo(state, getters) {
    const { screenAbtConfig } = getters

    // // mock
    // return {
    //   isNew: true,
    //   isStoreLeft: true,
    //   isDecriptionShow: true,
    // }

    const { IS_RW } = gbCommonInfo
    const { Structureoptiz, storeleftright, decriptionshow } = screenAbtConfig?.Structureoptiz?.p || {}
    /**
     * no
     * 非双屏，线上直线滚动浏览样式 此方案默认
      new
      双屏，仅评论展示在左屏（1期需求样式）
     */
    const isNew = !IS_RW && Structureoptiz === 'new'
    /**
     * left
      双屏，店铺及推荐商品展示在左侧（不管其他模块，反正Structureoptiz=new的情况下，店铺在左侧；Structureoptiz=no的情况则在右侧不变）
      right
      店铺及推荐商品展示在右侧（不管Structureoptiz=new还是no，店铺都在右侧）
     */
    const isStoreLeft = isNew && storeleftright === 'left'
    /**
     * noshow
      描述模块默认收起（不管Structureoptiz=new还是no，描述默认收起）
      show
      双屏，描述模块默认展开（不管其他模块，Structureoptiz=new的情况下，描述展开；Structureoptiz=no的情况则默认收起）
     */
    const isDecriptionShow = isNew && decriptionshow === 'show'
    return {
      isNew,
      isStoreLeft,
      isDecriptionShow,
    }
  },
  isSpecialFlash(state, getters) {
    return [2, 3].includes(getters.flashType) && state.newFlashPromotion?.price?.amountWithSymbol
  },
  // googleSEO 用的
  currentPriceInfo(state, getters) {
    const { skuInfo, newFlashPromotion } = state
    const { flashGoods, isSpecialFlash } = getters
    const normalPrice = skuInfo?.mall?.[getters.mallCode]?.salePrice?.amount || state.productIntroData?.detail?.mall?.[getters.mallCode]?.salePrice?.amount
    if (flashGoods) {
      if (isSpecialFlash) {
        return {
          promotion: newFlashPromotion,
          price: newFlashPromotion?.price?.amountWithSymbol
        }
      } else {
        return {
          promotion: flashGoods,
          price: normalPrice
        }
      }
    } else {
      return {
        promotion: null,
        price: normalPrice
      }
    }
  },
  initSchemaSEO(state, getters) {
    const { productIntroData, googleSEO, hotModules } = state
    const { currentPriceInfo, priceDetect } = getters
    if (!currentPriceInfo.price) return googleSEO.find(seo => seo.name == 'goodsDetailSchema').innerHTML

    const baseOffers = {
      '@type': 'Offer',
      priceCurrency: gbCommonInfo.currency,
      price: currentPriceInfo.price,
      url: window.location.href,
      availability:
      productIntroData.detail.stock > 0 &&
      productIntroData.detail.is_on_sale != 0
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock',
    }

    const initSchemaSEO = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      description: productIntroData?.metaInfo?.meta_description,
      name: productIntroData.detail.goods_name,
      offers: baseOffers,
      ...(productIntroData.detail.brandInfo?.name
        ? {
          brand: {
            '@type': 'Brand',
            name: productIntroData.detail.brandInfo.name,
          },
        }
        : {}),
      image: transformImg({ img: productIntroData.detail.goods_img }),
      sku: productIntroData.detail.goods_sn,
    }
    const commentInfo = hotModules.comment.commentInfo 
    if (+(commentInfo.comment_num)) {
      initSchemaSEO.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: commentInfo?.comment_rank_average || 0,
        reviewCount: commentInfo?.comment_num || 0,
      }
    }

    if (currentPriceInfo.promotion) {
      initSchemaSEO.priceValidUntil = timeTransformer({ time: currentPriceInfo.promotion.endTimestamp * 1000, defaultCode: 'A-5' })
    }

    if (priceDetect && state.saleAttrList.sku_list?.length) {
      const initSchemaArraySEO = state.saleAttrList.sku_list?.map?.(sku => {
        const item = sku?.mall?.[getters.mallCode]
        const queryObj = { ...parseQueryString(location.search), skucode: sku?.sku_code }
        const url = `${location.origin}${location.pathname}?${stringifyQueryString({ queryObj })}`
        const product = {
          ...initSchemaSEO,
          sku: sku?.sku_code,
          offers: {
            ...baseOffers,
            price: item?.salePrice?.amount,
            url,
            availability:
            item?.stock > 0
              ? 'https://schema.org/InStock'
              : 'https://schema.org/OutOfStock'
          }
        }
        return product
      })

      return JSON.stringify(initSchemaArraySEO)
    }

    return JSON.stringify(initSchemaSEO)
  },
  shippingStyleV1(state) {
    return state.productIntroData.screenAbtConfig?.shipping?.p == 'new'
  },
  // 配置人气氛围展示时机
  forbidAtmosphereFlow(state, getters){
    const Displaytiming = getters?.screenAbtConfig?.Displaytiming?.param?.Displaytiming || 0
    if(Displaytiming){
      let pageView = JSON.parse(localStorage.getItem('goods_detail_page_view'))?.value || 0
      // 用户24小时内访问商详次数大于等于X次，返回false，展示人气氛围
      return Displaytiming > pageView
    }else{
      return false
    }
  },
  // 时尚店铺图标
  inFashionStoreImg(){
    const { PUBLIC_CDN, appLanguage } = gbCommonInfo
    const imgs = {
      ar: 'ar-1a6afd3593',
      en: 'en_de-260162bcf8',
      de: 'en_de-260162bcf8',
      es: 'es-19bc1c51c3',
      fr: 'fr-a3957093ab',
      it: 'it-dc49d096fe',
      pt: 'pt-cdef19a189',
      pl: 'pl-3298aec821',
      ja: 'ja-4a86279057',
      'el-gr': 'el-gr-63a37ceb29',
      'cs-cz': 'cs-cz-5a94650048',
      'ro': 'ro-58c99bc2e2',
    }
    if(imgs[appLanguage]){
      return `${PUBLIC_CDN}/she_dist/images/store/infashion_label/${imgs[appLanguage]}.svg`
    }
    return ''
  },
  // 商品权益保障利益点
  picInterestInfo(state) {
    const { brandSeriesDetail } = state
    const { PUBLIC_CDN = '' } = gbCommonInfo
    return {
      ...state.productIntroData.getPicInterestInfo,
      brandSeriesDetail,
      PUBLIC_CDN,
    }
  },

  /**
   * 当前是否为付费会员
   */
  isPaidUser(state) {
    if (typeof state.hotModules.user.isPaidUser === 'boolean') return state.hotModules.user.isPaidUser
    return state.productIntroData?.isPaidUser
  },
  complianceMode(state) {
    return state.productIntroData?.apolloConfig?.complianceMode
  },
  complianceModeDe(state) {
    return state.productIntroData?.apolloConfig?.complianceModeDe
  },
  complianceTipsMode(state) {
    return state.productIntroData?.apolloConfig?.complianceTipsMode
  },
  isHitComplianceMode(state) {
    return state.productIntroData?.apolloConfig?.isHitComplianceMode
  },
  // 价格倒挂
  isInversion(state, getters) {
    if (!getters.complianceMode) return false
    const newFlashPromotion = state.newFlashPromotion
    if (newFlashPromotion?.price?.amountWithSymbol) {
      if (newFlashPromotion.is_inversion || !newFlashPromotion.suggested_sale_price?.amountWithSymbol || parseFloat(newFlashPromotion.suggested_sale_price?.amount) === 0) return true
      return false
    }
    let { skuInfo } = state
    let { mallCode, currentMallDetail } = getters
    let price = skuInfo?.mall?.[mallCode] || currentMallDetail
    if (price.isInversion || !price.suggestedSalePrice?.amountWithSymbol || parseFloat(price.suggestedSalePrice?.amount) === 0) return true
    return false
  },
  suggestedSalePrice(state, getters) {
    let { newFlashPromotion, skuInfo } = state
    let { currentMallDetail, isInversion, complianceTipsMode, complianceMode, mallCode, language } = getters
    let price = skuInfo?.mall?.[mallCode] || currentMallDetail
    let suggestedValue = ''
    if (price?.suggestedSalePrice?.amountWithSymbol) {
      suggestedValue = parseFloat(price.suggestedSalePrice?.amount) === 0 ? '' : price?.suggestedSalePrice?.amountWithSymbol
    }
    if (newFlashPromotion?.suggested_sale_price?.amountWithSymbol) {
      suggestedValue = parseFloat(newFlashPromotion.suggested_sale_price?.amount) === 0 ? '' : newFlashPromotion?.suggested_sale_price?.amountWithSymbol
    }
    let suggestedShow = complianceMode && !!suggestedValue && !isInversion
    let tip = complianceTipsMode && suggestedShow ? language.SHEIN_KEY_PC_30542 : ''
    return {
      show: suggestedShow,
      value: suggestedValue,
      tip,
    }
  },
  complianceCurrentPrice(state, getters){
    return state.skuInfo?.mall?.[getters.mallCode]?.salePrice || state.productIntroData?.detail?.mall?.[getters.mallCode]?.salePrice
  },
  deDiscountInfo(state, getters) {
    let { newFlashPromotion, skuInfo } = state
    let { currentMallDetail, mallCode, sheinClubPromotionInfo, isPaidUser } = getters
    let price = skuInfo?.mall?.[mallCode] || currentMallDetail

    let retailPriceAmount = parseFloat(price?.retailPrice?.amount || 0)
    let suggestedSalePriceAmount = parseFloat(newFlashPromotion?.suggested_sale_price?.amount || price?.suggestedSalePrice?.amount || 0)
    let mainPriceAmount = parseFloat((isPaidUser && sheinClubPromotionInfo) ? sheinClubPromotionInfo?.price?.amount : newFlashPromotion?.price?.amount || price?.salePrice?.amount) || 0

    let retailShow = false
    let suggestedShowDe = false
    let suggestedDiscountShow = false
    if (suggestedSalePriceAmount > 0) {
      if (suggestedSalePriceAmount > mainPriceAmount) {
        suggestedShowDe = true
        suggestedDiscountShow = ((isPaidUser && sheinClubPromotionInfo) ? price.discountValue : (newFlashPromotion?.discountPercent || price?.unit_discount)) > 0 // 30 天最低价没倒挂展示折扣率，倒挂不展示折扣率
        if (retailPriceAmount != mainPriceAmount && retailPriceAmount > suggestedSalePriceAmount) {
          retailShow = true
          suggestedShowDe = true
        }
      } else { // 30 天最低价倒挂
        if (retailPriceAmount != mainPriceAmount && retailPriceAmount != suggestedSalePriceAmount) {
          retailShow = true // 30 天最低价倒挂仍然可以展示原价
          suggestedShowDe = true
        }
      }
    }

    let suggestedDiscountPercentValue = newFlashPromotion?.discountPercent || price?.unit_discount
    let retailDiscountPercentValue = newFlashPromotion?.retail_discount_percent || price?.retailDiscountPercent
    if (isPaidUser && sheinClubPromotionInfo) {
      suggestedDiscountPercentValue = price.discountValue
      retailDiscountPercentValue = price?.retailDiscountValue
    }
    let result = {
      retailPrice: {
        show: retailShow,
        value: price?.retailPrice?.amountWithSymbol
      },
      retailDiscountPercent: {
        show: retailShow,
        value: retailDiscountPercentValue
      },
      retailDiscountPrice: {
        show: retailShow,
        value: newFlashPromotion?.retail_discount_price?.amountWithSymbol || price?.retailDiscountPrice?.amountWithSymbol || '',
      },
      suggestedSalePrice: {
        show: suggestedShowDe,
        value: newFlashPromotion?.suggested_sale_price?.amountWithSymbol || price?.suggestedSalePrice?.amountWithSymbol || ''
      },
      suggestedDiscountPercent: {
        show: suggestedDiscountShow,
        value: suggestedDiscountPercentValue
      },
      isInversionDe: !retailShow && !suggestedShowDe
    }
    return result
  },
  isInversionDe(state, getters) {
    if (getters.complianceModeDe) return getters.deDiscountInfo?.isInversionDe
    return false
  },
  activityTotalDiscount(state, getters){
    const isDeMode = getters.complianceModeDe
    const retailDiscount = getters.deDiscountInfo?.retailDiscountPrice.value
    return isDeMode ? retailDiscount : state.skuInfo?.mall?.[getters.mallCode]?.discountPrice?.amountWithSymbol || getters.currentMallDetail?.discountPrice?.amountWithSymbol
  },
  // deShowOriginalDetails(state, getters){ 
  //   const retailPrice = getters.getEstimatedInfo?.retailPrice?.amount || 0
  //   const salePrice = getters.complianceCurrentPrice?.amount || 0
  //   return getters.complianceModeDe && Number(retailPrice) > Number(salePrice)
  // },
  complianceDiscountInfo(state, getters){
    const { deDiscountInfo, currentMallDetail, complianceModeDe, isPaidUser } = getters
    let priceObj = {}
    const isByRetail = complianceModeDe && deDiscountInfo?.suggestedSalePrice?.show && deDiscountInfo?.retailDiscountPrice?.show
    if (isPaidUser && getters.sheinClubPromotionInfo?.price?.amountWithSymbol) {
      if(isByRetail){
        // 打开德国合规价开关时，如果30天最低价 和原价同时展示，那么腰带折扣率/折扣金额 取主价格和原价的折扣率 或者折扣金额展示
        priceObj.price = currentMallDetail?.retailVipDiscountPrice?.amountWithSymbol ?? ''
        priceObj.percent = String(currentMallDetail?.retailDiscountValue)
      } else {
        // 如果只有30天最低价，那么腰带的折扣率取主价格和30天最低价的折扣率 或者折扣金额展示
        priceObj.price = currentMallDetail?.vipDiscountPrice?.amountWithSymbol
        priceObj.percent = String(currentMallDetail?.discountValue)
      }
    } else {
      if(isByRetail){
        priceObj.price = deDiscountInfo?.retailDiscountPrice?.value ?? ''
        priceObj.percent = String(deDiscountInfo?.retailDiscountPercent?.value)
      } else {
        priceObj.price = currentMallDetail?.discountPrice?.amountWithSymbol
        priceObj.percent = String(currentMallDetail?.unit_discount)
      }
    }
    return priceObj
  },
  // 平移description 计算属性
  multiDescription(state) {
    const { productIntroData } = state
    let partNameList = {}
    let attrNameList = {}
    let attrSortList = {}
    let sameKeyList = {}
    let sameKeyListEvolve = {}
    let multiList = []
    if (
      productIntroData?.detail?.isMultiPartProduct &&
      productIntroData?.detail?.multiPartInfo
    ) {
      // 1. 同一部件相同属性项合并
      productIntroData.detail.multiPartInfo.forEach((item) => {
        ;(item.attributeList || []).forEach((multiItem) => {
          if (!sameKeyList[item.multiPartCode]) {
            sameKeyList[item.multiPartCode] = {}
          }
          if (!sameKeyList[item.multiPartCode][multiItem.attr_id]) {
            sameKeyList[item.multiPartCode][multiItem.attr_id] = []
          }
          partNameList[item.multiPartCode] = item.multiPartName
          attrNameList[multiItem.attr_id] = multiItem.attr_name
          if (
            !attrSortList[multiItem.attr_id] ||
            attrSortList[multiItem.attr_id] > multiItem.attr_sort
          ) {
            attrSortList[multiItem.attr_id] = multiItem.attr_sort
          }
          sameKeyList[item.multiPartCode][multiItem.attr_id].push(
            multiItem.attr_value
          )
        })
      })
      // 2. 不同部件相同属性项属性值合并
      Object.keys(sameKeyList).forEach((partItem) => {
        for (var partItemKey in sameKeyList[partItem]) {
          if (sameKeyListEvolve[partItemKey]) {
            let combineIndex
            sameKeyListEvolve[partItemKey].forEach(
              (multiItem, multiIndex) => {
                if (combineIndex !== undefined) return
                let bakValArrEvolve = multiItem.value
                if (
                  sameKeyList[partItem][partItemKey].length ==
                  bakValArrEvolve.length
                ) {
                  combineIndex = multiIndex
                  sameKeyList[partItem][partItemKey].forEach((item) => {
                    if (!bakValArrEvolve.includes(item)) {
                      combineIndex = undefined
                    }
                  })
                } else {
                  combineIndex = undefined
                }
              }
            )
            if (combineIndex !== undefined) {
              // 合并
              if (
                !sameKeyListEvolve[partItemKey][
                  combineIndex
                ].combinePartName.includes(partNameList[partItem])
              ) {
                sameKeyListEvolve[partItemKey][
                  combineIndex
                ].combinePartName.push(partNameList[partItem])
              }
            } else {
              // 不合并
              sameKeyListEvolve[partItemKey].push({
                combinePartName: [partNameList[partItem]],
                value: sameKeyList[partItem][partItemKey],
              })
            }
          } else {
            // 第一个
            sameKeyListEvolve[partItemKey] = []
            sameKeyListEvolve[partItemKey].push({
              combinePartName: [partNameList[partItem]],
              value: sameKeyList[partItem][partItemKey],
            })
          }
        }
      })
      for (var evolAttrId in sameKeyListEvolve) {
        sameKeyListEvolve[evolAttrId].forEach((evolMulti) => {
          multiList.push({
            attr_name:
              evolMulti.combinePartName.join(' & ') +
              ' ' +
              attrNameList[evolAttrId],
            attr_value: evolMulti.value.join(', '),
            attr_sort: attrSortList[evolAttrId],
          })
        })
      }
    }
    return multiList
  },
  isNeedFilterCVAttr(state, getters) {
    const { screenAbtConfig = {} } = getters
    return screenAbtConfig?.cvdescription?.p?.cvdescription === 'noshow'
  },
  originDescriptionList(state, getters) {
    const { showHeelHeight, heelHeight } = state
    const { pageComponents, multiDescription = [] } = getters
    const { descriptionConf = {} } = pageComponents
    const filterId = pageComponents.descPosConf.filterId
    const list = descriptionConf.descriptionList.slice(
      Number(descriptionConf.descriptionColor)
    )
    const levelledList = []
    let result = []
    for (let k in list) {
      if (!filterId.includes(String(list[k].attr_id))) {
        let itDescp = list[k]
        if (!levelledList[itDescp.attr_sort]) {
          levelledList[itDescp.attr_sort] = []
        }
        levelledList[itDescp.attr_sort].push(itDescp)
      }
    }
    multiDescription.forEach((item) => {
      if (!levelledList[item.attr_sort]) {
        levelledList[item.attr_sort] = []
      }
      levelledList[item.attr_sort].push(item)
    })
    levelledList.forEach((item) => {
      if (item) {
        result.push(...item)
      }
    })
    result.forEach(item => {
      // 跟高属性
      if(item.attr_id == '45' && showHeelHeight) {
        item.attr_value = ' ' + heelHeight
      }
    })
    return result
  },
  originDescriptionImgList(state, getters) {
    const { pageComponents } = getters
    return pageComponents?.descPosConf?.descriptionImg || []
  },
  originFPosContent(state, getters) {
    const { pageComponents } = getters
    const { showHeelHeight, productIntroData, heelHeight } = state
    let content = pageComponents.descPosConf.fPosData
    if(showHeelHeight) {
      let dimensionAttrInfo = productIntroData?.sizeInfoDes?.dimensionAttrInfo ?? []
      const heelHeightMap = dimensionAttrInfo.find(item => { // {attr_name:"Heel Height"attr_name_en:"Heel Height"},通过attr_name的值（多语言）去尺码表拿数据
        return item?.attr_name_en?.includes('Heel Height')
      })
      // 外露场景heel height
      const textAttr = content?.textAttr ?? []
      textAttr.forEach(item =>{
        if(heelHeightMap?.attr_name?.includes(item.name)) {
          item.value = heelHeight
        }
      })
    }
    return content
  },
  hasCVAttrStatus(state, getters) { // 获取各个数据源有没有cv属性
    const { originDescriptionList, originDescriptionImgList, originFPosContent } = getters
    const hasOriginDescriptionListCVAttr = originDescriptionList.some(item => CVAttrId.includes(Number(item.attr_id || 0)))
    const hasOriginDescriptionImgListCVAttr = originDescriptionImgList.some(item => {
      const attr_id = item?.[0]?.attr_id || 0
      return CVAttrId.includes(Number(attr_id))
    })
    const { textAttr = [], imgAttr = [] } = originFPosContent
    const hasOriginFPosContentTextCVAttr = textAttr.some(item => CVAttrId.includes(Number(item.attr_id || 0)))
    const hasOriginFPosContentImgCVAttr = imgAttr.some(item => {
      const attr_id = item?.[0]?.attr_id || 0
      return CVAttrId.includes(Number(attr_id))
    })
    return hasOriginDescriptionListCVAttr || hasOriginDescriptionImgListCVAttr || hasOriginFPosContentTextCVAttr || hasOriginFPosContentImgCVAttr
  },
  isEvolusheinTag(state, getters){
    // Evolushein楼层TSP标签
    const TAG_ID = '60008057'
    const { screenAbtConfig } = getters
    return state.productIntroData?.tspLabels?.tag?.includes?.(TAG_ID) && screenAbtConfig?.evolushein?.p?.evolushein === 'show'
  },
  paymentInfoContent(state){
    const { productIntroData } = state
    return productIntroData?.paymentInfo
  },
  detailWatermarkSwitch(state){
    return state.productIntroData?.apolloConfig?.DETAIL_SECOND_CONFIG?.DETAIL_WATERMARK_SWITCH 
  },
  extraVoucherPromotionInfo(state, getters) {
    let detialcatevorch = getters.screenAbtConfig?.detialcatevorch?.p?.detialcatevorch
    let extraVoucherPromotion = getters.promotionInfo.find(item => item.typeId == 33)
    let discountAmountWithSymbol = extraVoucherPromotion?.voucherAmount?.amountWithSymbol
    let catNameMulti = extraVoucherPromotion?.categoryInfo?.[0]?.catNameMulti
    let { language } = getters
    let {
      SHEIN_KEY_PC_31004,
      SHEIN_KEY_PC_31005,
      SHEIN_KEY_PC_31030,
      SHEIN_KEY_PC_31006,
      SHEIN_KEY_PC_31007,
      SHEIN_KEY_PC_31008,
      SHEIN_KEY_PC_31028,
      SHEIN_KEY_PC_31029,
      SHEIN_KEY_PC_31010,
      SHEIN_KEY_PC_28175
    } = language

    if (!extraVoucherPromotion || !discountAmountWithSymbol || !catNameMulti) return null
    if (!['show1', 'show2'].includes(detialcatevorch)) return null
    let isExtraVoucherFloorType = detialcatevorch === 'show1'
    let isExtraVoucherTextType = detialcatevorch === 'show2'
    let extraVoucherFloorTypeTexts = {
      extraVoucher: SHEIN_KEY_PC_31007 || 'Extra voucher',
      forNewUeserInCatNameMulti: template(catNameMulti, SHEIN_KEY_PC_31030 || 'for new ueser in {0}'),
    }
    let extraVoucherTextTypeTexts = {
      extraVoucher: SHEIN_KEY_PC_31004 || 'Extra voucher {0}',
    }

    let extraVoucherPromotionTexts = {
      title: SHEIN_KEY_PC_31010 || 'Voucher',
      contentTitle: template(catNameMulti, SHEIN_KEY_PC_31005 || 'For new user in {0}'),
      contentItem1: SHEIN_KEY_PC_31006,
      contentItem2EndTimeLanguage: SHEIN_KEY_PC_28175 || 'Expires in {0}',
      contentItem2EndTimestamp: extraVoucherPromotion?.endTimestamp,
      floorTip: SHEIN_KEY_PC_31007 || 'Extra Voucher',
      // contentItem2EndTimestamp: 1715961599,
      tipTitle: SHEIN_KEY_PC_31008 || 'How to get Vouchers?',
      tipContent1: SHEIN_KEY_PC_31028 || 'The Category Voucher for New (the "Voucher(s)") will be issued to a new category user. The Voucher value vary among users; A new category user refers to a SHEIN user who has never placed an order or purchased any product from a certain category.',
      tipContent2: SHEIN_KEY_PC_31029 || `Obtaining Voucher(s) is subject to compliance with SHEIN's terms and conditions. Users who have been restricted due to violations of SHEIN's policies or whose accounts exhibit unusual activities will be ineligible to obtain the Voucher(s). SHEIN reserves the right to withhold Voucher(s) from users who, in SHEIN's reasonable judgment, have engaged in improper or illicit activities to collect Voucher(s). SHEIN's determination of such activities will be final and binding.`,
    }

    return {
      isExtraVoucherFloorType,
      isExtraVoucherTextType,
      extraVoucherFloorTypeTexts,
      extraVoucherTextTypeTexts,
      extraVoucherPromotionTexts,
      discountAmountWithSymbol
    }

  },
  cccAddCartPrompt(state) {
    const { productIntroData } = state
    const { cccAddCartPrompt } = productIntroData || {}
    const items = cccAddCartPrompt?.content?.items ?? []
    return items
  },
  // 视频链接地址
  videoSourceData(state) {
    const { goods_imgs = {} } = state.productIntroData || {}
    const { link, link_expiration_time_ts } = goods_imgs?._videoSourceData || {}
    if (!link || !link_expiration_time_ts) return {}
    // 在客户端判断视频是否过期
    const _isValid = Number(link_expiration_time_ts) >= Number(new Date())
    if (!_isValid) return {}

    return { link }
  },
  // 是否展示店铺的闪购活动入口
  isShowFlashEntry(state, getters) {
    // return true
    return getters.screenAbtConfig ?.storeflash?.param?.storeflash !== 'none' && state.storeFlashSaleInfo?.whetherEligibleForTheStore == 1
  },
  flashEntryType(state, getters){
    return (getters.isShowFlashEntry && getters.screenAbtConfig?.storeflash?.param?.storeflash) || ''
  },
  flashEntryCountdown(state, getters){
    // 如果一行展示不下（怎么判断？）
    const timeStamp = state.storeFlashSaleInfo?.countdownToTheStore || 0
    const nowStamp = new Date().getTime()
    const endTimestamp = timeStamp * 1000
    const isEndTimeIn24 = endTimestamp - nowStamp > 0 && endTimestamp - nowStamp < 60 * 60 * 24 * 1000
       // 展示得下但abt【storeflashtime=none】命中倒计时不展示，或倒计时大于24h，则右侧均不展示倒计时
    if(getters.screenAbtConfig?.FlashSaleCountDown?.p?.FlashSaleCountDown !== 'Show' || !isEndTimeIn24) {
      return 0
    }
    return endTimestamp - nowStamp
  }
}
export default getters
