<template>
  <div>
    <div
      v-expose="{id: '1-8-6-171'}"
      class="goods-price__new-user-content"
      @click="getNewUserCoupon"
    >
      <div class="goods-price__new-user-off">
        {{ template(newUserDiscount, couponLanguage.SHEIN_KEY_PC_15957) }}
      </div>
      <div class="goods-price__new-user-divide"></div>
      <div class="goods-price__new-user-right">
        <p class="goods-price__new-user-tip">
          {{ newUserCoupon?.satisfied == 1 && newUserCoupon?.isForEstimated ? couponLanguage.SHEIN_KEY_PC_30943 : template(newUserCouponRule?.min?.amountWithSymbol, couponLanguage.SHEIN_KEY_PC_30944) }}
        </p>
        <!-- <Icon 
          class="goods-newUserCounpon__right-icon"
          name="sui_icon_more_right_18px" 
          size="12px" 
        /> -->
      </div>
    </div>
  </div>
</template>
<script>
import { template } from '@shein/common-function'
// import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'NewUserCoupon',
  props: {
    newUserCoupon: {
      type: Object,
      default: () => ({}),
    },
    couponLanguage: {
      type: Object,
      default: () => ({}),
    },
    newUserDiscount: {
      type: String,
      default: '',
    },
    newUserCouponRule: {
      type: Object,
      default: () => ({}),
    }
  },
  // components: {
  //   Icon
  // },
  methods: {
    template,
    getNewUserCoupon(){
      // 点击新人券
      this.$emit('get-coupon')
    }
  },
  emits: ['get-coupon']
}
</script>
<style lang="less" scoped>
.goods-price {
  &__new-user-content {
    display: inline-flex;
    align-items: center;
    color: #FF3F3F;
    height: 28px;
    border-radius: 2px;
    // background-image: linear-gradient(90deg, rgba(254, 220, 223, 0.40) 0%, rgba(254, 237, 183, 0.40) 100%);
    background-image: linear-gradient(271deg, #FFF5F3 -7.16%, rgba(255, 232, 226, 0.90) 16.94%, rgba(255, 185, 169, 0.50) 99.54%);
    padding: 0 8px;
    margin-top: 8px;
    cursor: pointer;
    border: 0.5px solid rgba(255, 209, 198, 0.80);
    width: 100%;
  }
  &__new-user-off{
    font-size: 14px;
    font-weight: 700;
    flex: 0 0 auto;
  }
  &__new-user-divide{
    width: 8px;
    height: 28px;
    background-image: url('/she_dist/images/coupon/new-user-coupon-e3527822b2.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    flex: 0 0 auto;
    margin: 0 10px;
  }
  &__new-user-right{
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    font-weight: bold;
    flex: 1;
    display: flex;
    align-items: center;
    // justify-content: space-between
  }
  &__new-user-tip{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }
  // &__right-icon{
  //   font-size: 12px;
  //   transform: rotate(0deg) /* rtl:rotate(180deg) */;
  // }
}
</style>
