<template>
  <div class="sourceImage__container">
    <img
      class="lazyload"
      :src="getLazyImg(500, 620)"
      :data-src="transformImg({ img: splitImage(certificationImage) })"
    />
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transformImg } from '@shein/common-function'
export default {
  name: 'ProductIntroCottonSourceImage',
  props: {
    certificationImage: String
  },
  methods: {
    transformImg,
    splitImage(img) {
      const index = img.lastIndexOf('.')
      if (index !== -1) {
        return img.slice(0, index) + '_thumbnail_x963' + img.slice(index)
      }
      return img
    },
    handleOpened() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-87'
      })
    },
    getLazyImg(width, height) {
      return `//imgholder.ltwebstatic.com/${width}x${height}.png?text=shein`
    }
  }
}
</script>

<style lang="less">
.sourceImage__container {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
