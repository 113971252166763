import { getClickPosition, isCollision, checkBoundary, checkCollision, getExposePoints, getPointRect, checkClose } from './common'
/**
  * config
  * @param {object} ctx: canvas 上下文
  * @param {number} ratio: 分辨率 默认 1 （倍）
  * @param {String} key: 当前锚点唯一标识 ｜ 不传默认返回当前 index
  * @param {Boolean} setActive: 点击后是否显示切换样式
  * @param {number} collisionDistance: 碰撞后偏移量 默认 5（px）
  * @param {object} boundary 安全边界 top / right / bottom / left (padding)
  * @param {number} points 锚点坐标集合
  * @param {number} tips 气泡大小参数
  *
  * tips
  * @param {number} width: 气泡提示框宽度
  * @param {number} height: 气泡提示框高度
  * @param {number} margin: 气泡边距
  * 
  * fonts
  * @param {String} font: 字体大小样式
  * @param {String} text: 文字
  * @param {String} color: 文字颜色
  * 
  * icon
  * @param {number} line: 边长
  * @param {String} color: 图标颜色
  * @param {String} padding: 图标间距
  * 
  * triangle
  * @param {number} width: 宽
  * @param {number} height: 高
  */
class DrawPoints {
  constructor({ config, callback }) {
    if (!config?.ctx) {
      console.error('canvas context is required!')
      return false
    }
    if (!config?.points?.length) {
      console.error('points is required!')
      return false
    }
    this.config = config
    this.callback = callback
    this.init()
  }

  init() {
    const { config, callback } = this
    const CONFIG = Object.assign({}, config)
    const { fonts, tips, icon, ctx, radius, collisionDistance = 2, boundary } = CONFIG

    CONFIG.points = CONFIG.points.sort((a, b) => a.y - b.y)

    this.config = CONFIG
    this.rects = []
    this.pointsEx = []
    this.el = CONFIG.ctx.canvas

    this.ctx = ctx
    this.fonts = { ...fonts }
    this.tips = tips

    this.icon = icon
    this.radius = radius
    this.collisionDistance = collisionDistance
    this.canvasWidth = +this.el.getAttribute('width')
    this.canvasHeight = +this.el.getAttribute('height')
    this.boundary = this.getBoundary(boundary)

    this.active = null // 记录
    this.addPointLayer() // 新建一个 point 隔离图层
    this.initRectsAndPointsEx()
    this.initCallback(callback)
  }

  // 销毁后
  destroyAll() {
    this.pointsEx.forEach((point) => point.destroy())
  }

  reset({ points }) {
    this.config.points = points
    this.init()
  }

  initRectsAndPointsEx() {
    const { ctx, fonts, icon = null, ratio = 1, points, radius, triangle } = this.config
    const { boundary, canvasHeight } = this

    const { directs, tipsWidthMap } = this.getTipsDefaultType()

    // 生成矩形
    this.rects = points.map(({ x, y }, index) => {
      const rect = this.getRect({ x, y, type: directs[index], width: tipsWidthMap[index].width })
      // this.ctx.strokeRect(rect.x, rect.y, rect.width, rect.height)
      return rect
    })

    // 边界检测 & 边界限制
    this.points = checkBoundary(this.rects, points, boundary)
    this.rects = this.points.map(({ x, y }, index) => {
      const rect = this.getRect({ x, y, type: directs[index], width: tipsWidthMap[index].width })
      return rect
    })

    checkCollision(this.rects, this.points, this.collisionDistance, canvasHeight / 2)
      .forEach(({ x, y, text, key }, index) => {
        if (typeof key == 'undefined') {
          console.error('key is required!')
        }
        const rect = this.getRect({ x, y, type: directs[index], width: tipsWidthMap[index].width })
        // this.ctx.strokeRect(rect.x, rect.y, rect.width, rect.height)
        const tips = tipsWidthMap[index]
        const type = directs[index]
        const pointEx = new MakePoint({
          config: {
            ctx,
            tips: tips,
            ratio,
            key,
            pointLayer: this.pointLayer,
            type,
            fonts: {
              ...fonts,
              text
            },
            rect,
            icon,
            triangle
          },
          point: {
            radius,
            x,
            y
          }
        })
        this.pointsEx.push(pointEx)
      })
    // 绘画
    this.pointsEx.forEach((point, index) => point.draw(!index))

  }

  getRect({ x, y, type, width }) {
    const { tips, radius } = this
    const { height, margin } = tips
    let rect = {
      x,
      y
    }
    if (type === 'right') {
      rect = {
        x: rect.x - radius,
        y: rect.y - height / 2,
        width: 2 * radius + margin + width,
        height
      }
    } else if (type === 'left') {
      rect = {
        x: rect.x - radius - margin - width,
        y: rect.y - height / 2,
        width: 2 * radius + margin + width,
        height
      }
    } else if (type === 'top') {
      rect = {
        x: rect.x - width / 2,
        y: rect.y - radius - margin - height,
        width: width,
        height: 2 * radius + margin + height
      }
    } else {
      rect = {
        x: rect.x - width / 2,
        y: rect.y - radius,
        width: width,
        height: 2 * radius + margin + height
      }
    }
    return rect
  }

  getTipsDefaultType() {
    const { points, radius } = this.config
    const { canvasWidth, canvasHeight, tips } = this
    const { height: tipsHeight, margin } = tips
    const mid = canvasWidth / 2
    const offset = 2 * radius + margin
    const directs = {}
    const tipsWidthMap = {}

    points.forEach(({ x, y, width }, i, arr) => {
      const _A = getPointRect({ x, y, width })
      const tipsWidth = this.setTipsWidth(arr[i].text)
      arr[i].text = tipsWidth.text
      const textWidthA = tipsWidth.width
      tipsWidthMap[i] = tipsWidth
      const closeA = checkClose(_A.x, _A.y, textWidthA + offset, tipsHeight + offset, canvasWidth, canvasHeight)
      for (let j = i + 1; j < arr.length; j++) {
        const { x, y, width } = arr[j]
        const _B = getPointRect({ x, y, width })
        const status = isCollision(_A, _B)
        if (status) {
          const textWidthB = this.setTipsWidth(arr[j].text).width
          const closeB = checkClose(_B.x, _B.y, textWidthB + offset, tipsHeight + offset, canvasWidth, canvasHeight)
          if (_A.x < _B.x && _A.y < _B.y) {
            directs[i] = !closeA.includes('left') ? 'left' : (!closeA.includes('top') ? 'top' : 'right')
            directs[j] = !closeB.includes('right') ? 'right' : (!closeA.includes('bottom') ? 'bottom' : 'left')
          } else if (_A.x < _B.x && _A.y > _B.y) {
            directs[i] = !closeA.includes('left') ? 'left' : (!closeA.includes('bottom') ? 'bottom' : 'right')
            directs[j] = !closeB.includes('right') ? 'right' : (!closeA.includes('top') ? 'top' : 'left')
          } else if (_A.x > _B.x && _A.y > _B.y) {
            directs[i] = !closeA.includes('right') ? 'right' : (!closeA.includes('bottom') ? 'bottom' : 'left')
            directs[j] = !closeB.includes('left') ? 'left' : (!closeA.includes('top') ? 'top' : 'right')
          } else if (_A.x > _B.x && _A.y < _B.y) {
            directs[i] = !closeA.includes('right') ? 'right' : (!closeA.includes('top') ? 'top' : 'left')
            directs[j] = !closeB.includes('left') ? 'left' : (!closeA.includes('bottom') ? 'bottom' : 'right')
          }
        }
      }
      // 普通的就处理左右
      if (!directs[i]) {
        if (_A.x < mid) {
          if (!closeA.includes('left')) {
            directs[i] = 'left'
          } else {
            directs[i] = 'right'
          }
        } else {
          if (!closeA.includes('right')) {
            directs[i] = 'right'
          } else {
            directs[i] = 'left'
          }
        }
      }
    })
    return { directs, tipsWidthMap }
  }

  addPointLayer() {
    const pointLayer = this.el.parentNode.querySelector('.outfit-layer__animation')
    if (pointLayer) {
      this.pointLayer = pointLayer.getContext('2d')
      this.pointLayer.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
      return false
    }

    const layer = document.createElement('canvas')
    layer.classList = this.el.classList
    layer.classList.add('outfit-layer__animation')
    layer.width = this.canvasWidth
    layer.height = this.canvasHeight
    layer.style.zIndex = 1
    this.el.style.zIndex = 2
    this.el.parentNode.appendChild(layer)
    this.pointLayer = layer.getContext('2d')
  }
  initCallback(callback = {}) {
    const { ctx } = this.config
    const canvas = this.el
    const pointsEx = this.pointsEx
    const canvasDom = document.querySelector('canvas');

    canvasDom.addEventListener('click', (event) => {
      let index = null, flag = false
      const { x: clickX, y: clickY } = getClickPosition(canvas, event)
      for (let i = 0; i < pointsEx.length; i++) {
        const point = pointsEx[i]
        const { x, y, width, height } = point.rect
        ctx.beginPath()
        ctx.rect(x, y, width, height)
        if (ctx.isPointInPath(clickX, clickY)) {
          index = i
          flag = true
        }
      }
      if (flag && callback?.click) {
        callback.click(pointsEx?.[index]?.key, pointsEx?.[index])
        this.setActivePoint(pointsEx?.[index].key)
      }
    })
  }

  setTipsWidth(text) {
    const { ctx, fonts, icon, tips, canvasWidth } = this
    const { font, padding } = fonts
    const { line = 0, padding: iconPadding = 0 } = icon || {}

    ctx.font = font
    const maxWidth = canvasWidth / 2
    const iconWidth = icon ? Math.acos((45 * Math.PI) / 180) * line + iconPadding * 2 : 0
    let textWidth = ctx.measureText(text).width
    let tipsWidth = textWidth + iconWidth + padding * 2 // 文字宽度 + 填充 + 箭头 + 填充

    if (tipsWidth > maxWidth) {
      text = text.slice(0, 10) + '...'
      textWidth = ctx.measureText(text).width
      tipsWidth = textWidth + iconWidth + padding * 2
    }

    return { ...tips, width: tipsWidth, textWidth, text }
  }

  getPointsRect({ x, y, width }) {
    return {
      x: x - width / 2,
      y: y - width / 2,
      width,
      height: width
    }
  }

  // 获取边界限制
  getBoundary(boundary) {
    const { canvasWidth, canvasHeight, tips } = this
    const { top = 0, right = 0, bottom = 0, left = 0 } = boundary

    const result = {
      top: top < 0 ? 0 : (top > canvasHeight - tips.height ? canvasHeight - tips.height : top),
      bottom: canvasHeight - bottom < tips.height ? tips.height : canvasHeight - bottom,
      left: left < 0 ? 0 : left,
      right: canvasWidth - right < 0 ? 0 : canvasWidth - right,
    }

    return result
  }

  setActivePoint(key) {
    const { pointsEx, active, config } = this
    const { needActive, ctx } = config
    if (!needActive || active === key) return
    this.active = key
    const width = this.canvasWidth
    const height = this.canvasHeight
    ctx.clearRect(0, 0, width, height)
    pointsEx.forEach(point => {
      point.drawTips(needActive, key)
      point.drawText(needActive, key)
      point.drawPoint()
    })
  }

  getExpose(rate = 0.7) {
    const { pointsEx, config } = this
    const { ratio } = config
    return getExposePoints(this.el, pointsEx, rate, ratio)
  }
}
class MakePoint {
  constructor({ config, point }) {
    const { x, y, radius } = point
    const { ctx, tips, fonts, icon, key, ratio, pointLayer, type, rect, triangle = { width: 6, height: 10 } } = config
    if (!ctx) { console.error('ctx is required!') }
    // init
    this.pointX = x
    this.pointY = y
    this.radius = radius
    this.ratio = ratio
    this.key = key

    this.ctx = ctx
    this.pointLayer = pointLayer
    this.fonts = fonts
    this.icon = icon
    this.type = type
    this.tips = tips
    this.rect = rect
    this.triangle = triangle

    // animate
    this.animate = 0

    // 销毁状态
    this.destroyStatus = false
  }

  destroy() {
    this.destroyStatus = true
  }

  // 绘画
  draw(status) {

    this.isFirstPoint = status // 识别是否第一个点

    this.drawTips()
    this.drawText()
    this.drawPoint()
    this.drawAnimatePoint()
  }

  // 用于边距计算的
  drawRect() {
    const { ctx, rect } = this
    ctx.strokeRect(rect.x, rect.y, rect.width, rect.height)
  }

  // 圆心
  drawPoint() {
    const { ctx, pointX, pointY, radius } = this
    // 内圆 ===

    ctx.beginPath()
    ctx.arc(pointX, pointY, radius * 0.2857, 0, (360 * Math.PI) / 180, true)
    ctx.closePath()

    // 描边
    ctx.fillStyle = '#FFF'
    ctx.fill()
  }

  // 动画效果
  drawAnimatePoint() {
    const { pointLayer, pointX, pointY, radius, isFirstPoint } = this
    const layerWidth = pointLayer.canvas.getAttribute('width')
    const layerHeight = pointLayer.canvas.getAttribute('height')

    if (this.animate >= 1) {
      this.animate = 0
    } else {
      this.animate += 0.02
    }

    isFirstPoint && pointLayer.clearRect(0, 0, layerWidth, layerHeight) // 最后一个再清除画布
    pointLayer.save()

    // 外圆 ===

    pointLayer.beginPath()
    pointLayer.fillStyle = `rgba(0, 0, 0, ${(1 - this.animate)})`
    pointLayer.arc(pointX, pointY, this.animate * radius * 1.5, 0, (360 * Math.PI) / 180, true)
    pointLayer.fill()
    pointLayer.closePath()
    pointLayer.restore()
    if (this.destroyStatus) return // 防止内存泄漏
    window.requestAnimationFrame(() => this.drawAnimatePoint())
  }

  // 气泡
  drawTips(needActive, activeKey) {
    const { ctx, type, tips, key, ratio = 1, triangle } = this
    const { width, height } = tips
    const { x, y } = this.getTipsPositionPoint()
    const radian = 2 * ratio // 边角弧度 ｜ 不可配置
    const a = triangle.height // 三角形高
    const b = triangle.width // 三角形宽
    let point
    // 开始
    ctx.beginPath()
    // 画笔移动到起始位置
    ctx.moveTo(x + radian, y)

    // 顶部三角形
    if (type === 'bottom') {
      point = x + width / 2
      // 先到三角形左边 point + line / 2 (边长 / 2)
      ctx.lineTo(point + a / 2, y)
      // 到三角形顶点
      ctx.lineTo(point, y - b)
      // 到三角形另一点
      ctx.lineTo(point - a / 2, y)
    }

    // 移动到开始点
    // xEnd - radian
    ctx.lineTo(x + (width - radian), y)
    // 画圆角
    // (控制点/结束点/半径)
    ctx.arcTo(x + width, y, x + width, y + radian, radian)

    if (type === 'left') {
      point = y + height / 2
      ctx.lineTo(x + width, point - a / 2)
      ctx.lineTo(x + width + b, point)
      ctx.lineTo(x + width, point + a / 2)
    }

    // 链接至右下角
    ctx.lineTo(x + width, y + height - 2 * radian)
    ctx.arcTo(x + width, y + height, x + width - radian, y + height, radian)

    if (type === 'top') {
      // 底部三角形
      point = x + width / 2
      // 先到三角形左边 point + line / 2 (边长 / 2)
      ctx.lineTo(point + a / 2, y + height)
      // 到三角形顶点
      ctx.lineTo(point, y + height + b)
      // 到三角形另一点
      ctx.lineTo(point - a / 2, y + height)
    }

    // 到底部左边转角
    ctx.lineTo(x + radian, y + height)

    // 转到左上
    ctx.arcTo(x, y + height, x, y + height - radian, radian)

    if (type === 'right') {
      point = y + height / 2
      ctx.lineTo(x, point - a / 2)
      ctx.lineTo(x - b, point)
      ctx.lineTo(x, point + a / 2)
    }

    // 连去左上角起点
    ctx.lineTo(x, y + radian)

    // 闭环
    ctx.arcTo(x, y, x + radian, y, radian)
    // 阴影
    ctx.shadowBlur = 8
    ctx.shadowColor = 'rgba(0,0,0,0.16)'
    ctx.closePath()
    // 填充
    if (needActive && activeKey === key) {
      ctx.fillStyle = 'rgba(34, 34, 34, 0.94)'
    } else {
      ctx.fillStyle = 'rgba(255, 255, 255, 0.94)'
    }
    ctx.fill()
  }

  drawText(needActive, activeKey) {
    const { ctx, fonts, key } = this
    const { text, color = '#666666' } = fonts
    const { x, y } = this.getTextPoint()
    ctx.beginPath()
    ctx.shadowBlur = 0
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'left'
    if (needActive && activeKey === key) {
      ctx.fillStyle = '#FFFFFF'
    } else {
      ctx.fillStyle = color
    }
    ctx.fillText(text, x, y)
    ctx.shadowBlur = 0

    this.drawIcon(needActive, activeKey)

    ctx.closePath()
  }

  drawIcon(needActive, activeKey) {
    if (!this.icon) return
    const { ctx, icon, tips, key, ratio } = this
    const { padding, line, color } = icon
    const { textWidth } = tips
    const { x, y } = this.getTextPoint()
    const a = (42.5 * Math.PI) / 180
    const lineX = Math.asin(a) * line
    const lineY = Math.acos(a) * line

    const iconPoint = {
      x: x + textWidth + padding,
      y: y
    }

    ctx.beginPath()

    if (needActive && activeKey === key) {
      ctx.strokeStyle = '#FFFFFF'
    } else {
      ctx.strokeStyle = color
    }

    ctx.lineWidth = 1.2 * ratio
    ctx.moveTo(iconPoint.x, iconPoint.y - lineX)
    ctx.lineTo(iconPoint.x + lineY, iconPoint.y)
    ctx.lineTo(iconPoint.x, iconPoint.y + lineX)
    ctx.stroke()
    ctx.closePath()
  }
  // 获取文字起点
  getTextPoint() {
    const { fonts, radius, pointX, pointY, type, tips } = this
    const { padding } = fonts
    const { margin, width, height } = tips
    let x, y
    if (type === 'right') {
      x = pointX + margin + radius + padding
      y = pointY
    }
    if (type === 'left') {
      x = pointX - margin - radius - width + padding
      y = pointY
    }
    if (type === 'top') {
      x = pointX - width / 2 + padding
      y = pointY - radius - margin - height / 2
    }
    if (type === 'bottom') {
      x = pointX - width / 2 + padding
      y = pointY + radius + margin + height / 2
    }
    return { x, y }
  }
  // 获取提示绘画起点
  getTipsPositionPoint() {
    const { type, pointX, pointY, radius, tips } = this
    const { margin, height, width } = tips
    let x, y
    if (type === 'right') {
      x = pointX + margin + radius
      y = pointY - height / 2
    }
    if (type === 'left') {
      x = pointX - margin - radius - width
      y = pointY - height / 2
    }
    if (type === 'top') {
      x = pointX - width / 2
      y = pointY - radius - margin - height
    }
    if (type === 'bottom') {
      x = pointX - width / 2
      y = pointY + radius + margin
    }

    return { x, y, width, height }
  }
}

export {
  DrawPoints
}
