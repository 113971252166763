<template>
  <!-- ROMWE双主图 -->
  <GalleryRw
    v-if="isDouble"
    :is-sh="pageComponents.productIntroGalleryConf.isRwGallery"
    :goods-name="goodsName"
    :is-sticky-style="isStickyStyle"
  >
    <template #underGallery>
      <slot name="underGallery"></slot>
    </template>
  </GalleryRw>
  <GalleryNormal
    v-else
    :goods-name="goodsName"
    :is-sticky-style="isStickyStyle"
  >
    <template #underGallery>
      <slot name="underGallery"></slot>
    </template>
  </GalleryNormal>
</template>

<script>
import GalleryRw from './Gallery/GalleryRw'
import GalleryNormal from './Gallery/GalleryNormal'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ProductIntroGalleryEnter',
  components: {
    GalleryRw,
    GalleryNormal,
  },
  computed: {
    ...mapState(['IS_RW', 'productIntroData']),
    ...mapGetters(['pageComponents', 'pageStructureAbtStyleInfo']),
    isStickyStyle(){
      return this.pageStructureAbtStyleInfo.isNew
    },
    goodsName() {
      return this.productIntroData?.detail?.goods_name || ''
    },
    isDouble() {
      return this.IS_RW || this.pageComponents?.productIntroGalleryConf?.isRwGallery
    }
  }
}
</script>
