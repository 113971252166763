<template>
  <div
    :class="{
      'product-intro__price-under': true,
      'product-intro__price-under_de': deDiscountTip,
    }"
  >
    <BestDealTips
      v-if="bestDealCombo.show"
      :text="bestDealCombo.content"
      :analysis="bestDealCombo.analysis"
    />
    <span
      v-if="bestDealCombo.show && taxIncludedCombo.show && !deDiscountTip"
      class="split-dot"
    >·</span>
    <div
      :class="{
        'product-intro__head-tax': true,
        'product-intro__head-tax_de': deDiscountTip,
      }"
    >
      <span
        v-if="taxIncludedCombo.show"
        v-expose="{ id: '1-8-6-186' }"
      >{{ taxIncludedCombo.content }}&ensp;</span> 
      <span v-if="deDiscountTip">{{ deDiscountTip }}</span> 
    </div>
  </div>
</template>

<script>
import BestDealTips from './BestDealTips'
import { template } from '@shein/common-function'
export default {
  name: 'ProductIntroPriceTips',
  components: { BestDealTips },
  props: {
    priceTipsConfig: {
      type: Object,
      default: () => ({})
    },
    deDiscountInfo: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    complianceModeDe: {
      type: Boolean,
      default: false
    },
    
  },
  computed: {
    bestDealCombo() {
      return this.priceTipsConfig.best_deal
    },
    taxIncludedCombo() {
      return this.priceTipsConfig.tax_included
    },
    deDiscountTip() {
      if (this.deDiscountInfo?.suggestedSalePrice?.show && this.complianceModeDe) {
        return template(this.deDiscountInfo?.suggestedSalePrice.value, this.language.SHEIN_KEY_PC_31023)
      }
      return null
    }
  }
}
</script>

<style lang="less">
.product-intro {
  &__price-under {
    display: inline-flex;
    align-items: center;
    margin-top: 8px;
    .split-dot {
      margin: 4px 0;
    }
  }
  // 德国超长文案兼容适配
  &__price-under_de {
    align-items: flex-start;
    flex-direction: column;
  }
  &__head-tax {
    font-size: 12px;
    color: @sui_color_gray_dark2;
  }
  &__head-tax {
    margin-top: 4px;
  }
}
</style>
