<template>
  <div
    ref="sheinVideoPlayer"
    class="shein-video-player"
  >
    <div
      v-show="isLoading"
      class="shein-video-player__loading"
    >
      <ProductIntroLoading />
      <img
        class="lazyload shein-video-player__loading_poster"
        :src="poster"
        :alt="posterAlt"
      />
    </div>

    <div
      v-show="!isLoading"
      class="shein-video-player__content"
    >
      <client-only>
        <VimeoPlayer
          v-if="playerType === 'old'"
          ref="vimeoPlayer"
          :show="true"
          :url="videoUrl"
          @is-cilck-full-screen="fullScreenChange"
          @fail-loaded="isFail => handleLoaded(!isFail)"
        />

        <CommonVideoPlayer
          v-else
          ref="commonVideoPlayer"
          :url="videoSourceData?.link"
          :controls="false"
          @loaded="handleLoaded"
          @error="handleError"
        >
          <template
            #player-ui="{
              paused,
              progress,
              info,
              setProgress,
              handleProgressDragStart,
              handleProgressDragEnd
            }"
          >
            <div
              class="shein-video-player__ui"
              @click="paused ? '' : pauseVideo"
            >
              <div class="shein-video-player__ui_play-button">
                <Icon
                  :style="`display: ${paused ? 'block' : 'none'}`"
                  name="sui_icon_store_video_play_96px"
                  size="48px"
                  @click="playVideo"
                />
                <Icon
                  :style="`display: ${paused ? 'none' : 'block'}`"
                  name="sui_icon_store_video_pause_96px"
                  size="48px"
                  @click="pauseVideo"
                />
              </div>

              <div class="shein-video-player__ui_controls">
                <div class="shein-video-player__ui_progress-bar">
                  <input
                    class="shein-video-player__ui_progress-bar_input"
                    :value="info.currentTime"
                    type="range"
                    min="0"
                    :max="info.duration"
                    step="0.1"
                    @mousedown.stop="handleProgressDragStart"
                    @mouseup.stop="handleProgressDragEnd"
                    @input="e => setProgress(e.target.value)"
                  />
                  <div
                    class="shein-video-player__ui_progress-bar_played"
                    :style="`width: ${progress}%`"
                  ></div>
                </div>
                <div
                  class="shein-video-player__ui_full-screen"
                  @click="handleFullScreen"
                >
                  <Icon
                    :style="`display: ${isFullScreen ? 'none' : 'block'}`"
                    name="sui_icon_expand_16px"
                    size="16px"
                  />
                  <Icon
                    :style="`display: ${isFullScreen ? 'block' : 'none'}`"
                    name="sui_icon_close_16px"
                    size="16px"
                  />
                </div>
              </div>
            </div>
          </template>
        </CommonVideoPlayer>
      </client-only>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, defineAsyncComponent, computed, onMounted } from 'vue'
import CommonVideoPlayer from './CommonVideoPlayer.vue'
import ProductIntroLoading from '../ProductIntroLoading.vue'
import { Icon } from '@shein-aidc/icon-vue3'

/**
 * @description shein视频播放器
 * @description 为了兼容以前的vimeo组件的大部分业务逻辑，所以这里的属性，事件名字尽量保持一致
 */
export default defineComponent({
  name: 'SheinVideoPlayer',
  components: {
    VimeoPlayer: defineAsyncComponent(() =>
      import('public/src/pages/goods_detail_v2/components/VimeoPlayer.vue')
    ),
    CommonVideoPlayer,
    ProductIntroLoading,
    Icon
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    /**
     * vimeo视频地址
     */
    videoUrl: {
      type: String,
      default: ''
    },
    videoPlayerType: {
      type: String,
      default: 'old'
    },
    /**
     * 视频源数据
     * @description h5播放器需要的视频源数据
     */
    videoSourceData: {
      type: Object,
      default: () => ({})
    },
    /**
     * 视频封面
     * @description 用于视频loading时显示
     */
    poster: {
      type: String,
      default: ''
    },
    posterAlt: {
      type: String,
      default: ''
    }
  },
  emits: ['full-screen-change', 'loaded', 'fail-loaded'],
  setup(props, { emit }) {
    const vimeoPlayer = ref(null)
    const commonVideoPlayer = ref(null)
    const sheinVideoPlayer = ref(null)
    // 强制使用的播放器类型
    const forcePlayerType = ref(null)
    // 视频是否加载中
    const isLoading = ref(true)
    const isFullScreen = ref(false)

    const playerType = computed(() => {
      let type = props.videoPlayerType
      if(!props?.videoSourceData?.link) {
        // 如果没有视频源数据，强制使用旧的播放器
        type = 'old'
      }
      return forcePlayerType.value || type
    })

    const videoEl = computed(() => {
      return playerType.value === 'old' 
        ? vimeoPlayer.value
        : commonVideoPlayer.value
    })

    onMounted(() => {
      sheinVideoPlayer.value?.addEventListener('fullscreenchange', () => {
        fullScreenChange(document.fullscreenElement !== null)
      })
    })

    function fullScreenChange(_isFullScreen) {
      isFullScreen.value = _isFullScreen
      emit('full-screen-change', _isFullScreen)
    }

    /**
     * 处理视频加载
     * @description 为了兼容以前的vimeo组件,所以写了一些很奇怪的取反逻辑
     * @param isLoaded
     */
    function handleLoaded(isLoaded) {
      isLoading.value = !isLoaded
      emit('fail-loaded', !isLoaded) // 为了兼容以前的vimeo组件, 以后这个事件可以去掉，尽量采用loaded事件
      emit('loaded', isLoaded)
    }

    function playVideo() {
      return videoEl.value?.playVideo()
    }

    function pauseVideo() {
      return videoEl.value?.pauseVideo()
    }

    function handleError() {
      // 新播放器加载视频失败，切换到旧的播放器
      forcePlayerType.value = 'old'
    }

    function handleFullScreen() {
      if (isFullScreen.value) {
        document.exitFullscreen()
      } else {
        sheinVideoPlayer.value?.requestFullscreen()
      }
    }

    return {
      props,
      fullScreenChange,
      handleLoaded,
      playVideo,
      pauseVideo,
      vimeoPlayer,
      commonVideoPlayer,
      isLoading,
      handleError,
      handleFullScreen,
      isFullScreen,
      sheinVideoPlayer,
      playerType
    }
  }
})
</script>

<style lang="less">
.shein-video-player {
    width: 100%;
    height: 100%;

    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;

        &_poster {
            position: absolute;
            width: 100%;
            top: 0;
        }
    }

    .common-video-player {
        bottom: 0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;
        background: #000;

        video::-webkit-media-controls {
            display: none;
        }
    }

    &__ui {
        width: 100%;
        height: 100%;
        z-index: 5;
        position: relative;
        opacity: 0;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        cursor: auto;

        &:hover {
            opacity: 1;
            transition: opacity 0.5s ease-out;
        }

        &_play-button {
            cursor: pointer;
            color: #fff;
        }

        &_controls {
            position: absolute;
            width: 100%;
            bottom: 0px;
            left: 0px;
            padding: 8px 2%;
            display: flex;
            align-items: center;
        }

        &_progress-bar {
            width: 100%;
            height: 8px;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.45);
            position: relative;
            overflow: hidden;

            &_input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }

            &_played {
                height: 100%;
                background: #fff;
                width: 0;
                transition: width 0.1s ease-in-out;
            }
        }

        &_full-screen {
            cursor: pointer;
            color: #fff;
            margin-left: 8px;
        }
    }
}
</style>
