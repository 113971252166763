<template>
  <s-popover
    v-model="isShowAddGroup"
    class="product-intro__add-like-ctn"
    placemen="top"
    trigger="unset"
    :hide-close-icon="true"
    :append-to-body="false"
  >
    <template #reference>
      <div
        class="product-intro__add-like"
        :class="isLike ? 'like-active': ''"
        tabindex="0"
        :aria-label="language.SHEIN_KEY_PC_17382"
        role="button"
        @click="handleLikeBtn"
        @touchstart="handleLikeBtnTouch"
      >
        <ProductLoading
          v-if="isRecLoading"
          class="product-intro__loading"
        />
        <template v-else-if="!isLike">
          <sui_icon_save_32px
            size="32"
            class="hovertips"
          />
        </template>
        <template v-else>
          <sui_icon_save_completed_32px 
            class="hovertips mian-color"
            size="32"
            :class="{ anim: isAnim }"
          />
        </template>
      </div>
    </template>
    <!-- 收藏成功加组提示 -->
    <div
      class="product-intro__add-group"
      @touchstart.stop
      @click.stop="addGroup"
    >
      <Icon
        class="icon"
        name="sui_icon_addtoboard_28px_1"
        size="28px"
      />
      <span>{{ language.SHEIN_KEY_PC_17581 }}</span>
    </div>
  </s-popover>
</template>
<script setup>
import { ref, computed, watch, onMounted, toRefs } from 'vue'
import { useStore } from 'vuex'
import schttp from 'public/src/services/schttp'
import { isMobile, template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { SMessage } from '@shein-aidc/sui-message'
import { groupDialog } from 'public/src/pages/components/wishlist'
import { abtservice } from 'public/src/services/abt'
import ProductRecommendDrawer from 'public/src/pages/goods_detail_v2/components/ProductRecommendDrawer/index.js'
import ProductLoading from 'public/src/pages/goods_detail_v2/components/Loading/product-loading.vue'
import { useMapGetters } from '@detail/store/mapHook'
import { sui_icon_save_32px, sui_icon_save_completed_32px, Icon } from '@shein-aidc/icon-vue3'
daEventCenter.addSubscriber({ modulecode: '1-8-6' })

const props = defineProps({
  showGroup: {
    type: Boolean,
    default: true
  }
})
const { showGroup } = toRefs(props)
const store = useStore()
const language = computed(() => store.state.language)
const skuAttrs = computed(() => store.state.skuAttrs)
const currentGoodsId = computed(() => store.state.currentGoodsId)
const beforeLoginLikeType = computed(() => store.state.beforeLoginLikeType)
const skuInfo = computed(() => store.state.skuInfo)

const productIntroData = computed(() => store.state.productIntroData)
const isLike = computed(() => store.state.isLike)
const quickShip = computed(() => store.state.quickShip)

const isShowAddGroup = ref(false) // 是否显示加组提示
const isDrawerAddBoard = ref(false) // 推荐弹窗是否显示加组按钮
const isLoad = ref(false)
const isAnim = ref(false) //收藏动画标识位
const isRecLoading = ref(false)
const mallCode = computed(() => store.getters.mallCode)
const attrIsSize = computed(() => skuAttrs.value.find((_) => _.attr_id == 87) || {})
const recommendDrawerProducts = computed(() => store.getters.recommendDrawerProducts)
const isHitComplianceMode = computed(() => store.getters.isHitComplianceMode)
const exposedFilterList = computed(() => store.state.exposedFilterList)
const { isAddWishAfterRec } = useMapGetters('AddBtn', ['isAddWishAfterRec'])
const isFetchRec = computed(() => {
  return !recommendDrawerProducts.value?.length && isAddWishAfterRec.value
})
const isOpenDrawer = computed(() => {
  return recommendDrawerProducts.value?.length && isAddWishAfterRec.value && store.state.recommendCountData.addWishRecommendCount < 3
})

// 查询收藏状态
const checkLikeStatus = () => {
  schttp({
    url: '/api/user/wishlist/check/good',
    params: {
      goods_id: currentGoodsId.value,
    },
  }).then((res) => {
    if (
      res &&
      res.data &&
      Number(res.data.code) === 0 &&
      res.data.info &&
      Number(res.data.info.result) === 1
    ) {
      store.commit('assignState', {
        isLike: true
      })
    }
  })
}
watch(currentGoodsId, () => {
  store.commit('assignState', {
    isLike: false
  })
  checkLikeStatus()
})

// 点击收藏按钮
const handleLikeBtn = () => {
  if (isLike.value) {
    setGoodsLike('del')
  } else {
    setGoodsLike('add')
  }
}

const handleLikeBtnTouch = () => {
  if (isMobile()) {
    handleLikeBtn()
  }
}

const getAttrParams = () => {
  let skuCode = null
  let skcName = null
  let attrValueId = null
  if (skuInfo.value && Object.keys(skuInfo.value).length) {
    skuCode = skuInfo.value.sku_code
    skcName = skuInfo.value.skc_name
    if (attrIsSize.value.attr_value_id) {
      attrValueId = attrIsSize.value.attr_value_id // 尺码值id
    }
  }
  return { skuCode, skcName, attrValueId }
}

const fetchRecData = async (pageNum = 1) =>{
  return await store.dispatch('getAlsoViewRecommendlist', pageNum)
}
const setNoInteractiveTimes = (times = 0) =>{
  store.commit('updateRecommendCountData', {
    addWishRecommendCount: times,
  })
  return store.state.recommendCountData.addWishRecommendCount
}

// 收藏逻辑
const setGoodsLike = async(type) => { 
  let params = {}
  if (isLoad.value) return
  isLoad.value = true
  if (type === 'add') {
    params = { goods_id: currentGoodsId.value }
    const { skuCode, skcName, attrValueId } = getAttrParams()
    if (skuCode) params.sku_code = skuCode
    if (skcName) params.skc_name = skcName
    if (attrValueId) params.attrValueId = attrValueId
    if (mallCode.value) params.mall_code = mallCode.value
  } else if (type === 'del') {
    params = {
      goods_id: currentGoodsId.value,
    }
  }
  schttp({
    url: `/api/user/wishlist/${type}`,
    method: type === 'del' ? 'GET' : 'POST',
    ...(type === 'del' ? { params } : { data: params }),
  }).then(async (res) => {
    isLoad.value = false
    if (
      res &&
      res.data &&
      Number(res.data.code) === 0 &&
      res.data.info &&
      Number(res.data.info.result) === 1
    ) {
      if (type === 'add') {
        store.commit('assignState', {
          isLike: true
        })
        isAnim.value = true

        if(isFetchRec.value){
          isRecLoading.value = true
          await fetchRecData()
          isRecLoading.value = false
        }
        await checkNeedShowAddGroup()
        if(isOpenDrawer.value){
          ProductRecommendDrawer?.showModal?.({
            from: 'GoodsLike',
            products: recommendDrawerProducts.value,
            language: language.value,
            currentGoodsId: currentGoodsId.value,
            isShowAddGroup: isDrawerAddBoard.value,
            exposedFilterList: exposedFilterList.value,
            fetchRecData,
            setNoInteractiveTimes,
            config: {
              isHitComplianceMode: isHitComplianceMode.value,
            },
            autoGetCouponAtAddBag: (options)=>{
              store.dispatch('autoGetCouponAtAddBag', options)
            }
          })
        }

        setTimeout(() => {
          isAnim.value = false
        }, 500)

        daEventCenter.triggerNotice({
          daId: '1-8-6-10',
          extraData: {
            quickShip: quickShip.value,
            goods_id: currentGoodsId.value,
            sku: productIntroData.value.detail.goods_sn,
            is_cancel: 1,
            result: 1,
            activity_from: 'main',
            sku_id: productIntroData.value.detail.goods_sn,
            detail: productIntroData.value.detail,
            size: attrIsSize.value.attr_value_name || '',
            mall_code: mallCode.value || '',
            location: showGroup.value ? 'page' : 'suspension'
          },
        })

      } else if (type === 'del') {
        store.commit('assignState', {
          isLike: false
        })
        isAnim.value = false
        isShowAddGroup.value = false
        daEventCenter.triggerNotice({
          daId: '1-8-6-10',
          extraData: {
            quickShip: quickShip.value,
            goods_id: currentGoodsId.value,
            sku: productIntroData.value.detail.goods_sn,
            is_cancel: 0,
            result: 1,
            activity_from: 'main',
            sku_id: productIntroData.value.detail.goods_sn,
            mall_code: mallCode.value || '',
            location: showGroup.value ? 'page' : 'suspension'
          },
        })

      }
      fastwish.reAddwish() // 更新收藏夹状态
    } else if (res?.data?.code == 400427) {
      // 超出收藏限制
      SMessage({
        message: template(
          res.data.info.limit,
          language.value.SHEIN_KEY_PC_19347
        ),
        type: 'warning',
        offset: 164,
        duration: 3000,
      })
    } else if (res && Number(res.code) === -1) {
      // 未登录
      store.dispatch('showLoginModal', {
        likeType: type
      })
    }
  })
}
watch(beforeLoginLikeType, (newValue) => {
  switch (newValue) {
    case 'add':
      setGoodsLike('add')
      break
    case 'del':
      setGoodsLike('del')
      break
    default:
      checkLikeStatus()
  }
})

// 检查是否需要显示加组按钮
const checkNeedShowAddGroup = async () => {
  if (!showGroup.value) return
  isShowAddGroup.value = false
  // eslint-disable-next-line @shein-aidc/abt/abt
  const { AddShowGroup } = await abtservice.getUserAbtResult({
    posKeys: 'AddShowGroup',
  })
  await schttp({
    url: '/api/user/wishlist/group/optional/goods/get',
  }).then(({ data }) => {
    const list = (data.info || {}).groupList || []
    const show = AddShowGroup.param === 'ShowGroup' || list.length
    isShowAddGroup.value = show && !isOpenDrawer.value  // 满足推荐弹窗条件时，商详不需要再展示add to board提示语
    isDrawerAddBoard.value = show
    if (!show) return
    sessionStorage.setItem('groupListData', JSON.stringify(list))
    daEventCenter.triggerNotice({
      daId: '1-8-6-47',
      extraData: { board_count: list.length ? 1 : 0 },
    })
    if(isShowAddGroup.value){
      setTimeout(() => {
        isShowAddGroup.value = false
      }, 5000)
    }
  }).catch(()=>{

  })
}

const addGroup = () => {
  daEventCenter.triggerNotice({
    daId: '1-8-6-48',
  })

  isShowAddGroup.value = false

  const { value } = currentGoodsId

  groupDialog().then(dialog => dialog.open(
    {
      type: 4,
      goodsIds: [value],
      useFrom: '商品详情页',
    },
    () => {}
  ))
}

onMounted(() => {
  checkLikeStatus()
})

</script>
<style lang="less">
.product-intro {
  &__loading{
    width: 100%;
    height: 100%;
  }
  &__add-like-ctn {
    .margin-l(17px);
    .sui_icon_save_completed_32px {
      /*rw:begin*/
      color: @sui_color_main;
    }
  }
  &__add-like {
    position: relative;
    .fl();
    width: 54px;
    height: 54px;
    line-height: 54px;
    text-align: center;
    border: 1px solid #e5e5e5;
    cursor: pointer;
    border-radius: 50%;
    /* rw:begin*/
    border-radius: 0;
    i {
      font-size: 24px;
      &.sui_icon_save_32px {
        /* rw:begin*/
        color: @sui_color_gray_dark1;
      }
    }
    &:hover {
      .hovertips {
        transform: scale(1.1);
      }
    }
    .anim {
      animation: goodsdetail-like-anim 0.8s cubic-bezier(0.17, 0.89, 0.32, 1.49)
        both;
    }
    .hovertips {
      &::before {
        display: block;
      }
    }
    .mian-color {
      color: @sui_color_main;
    }
  }
  @keyframes goodsdetail-like-anim {
    0% {
      transform: scale(1.1);
    }
    30% {
      transform: scale(0.32);
    }
    100% {
      transform: scale(1.1);
    }
  }
  // 加组提示
  &__add-group {
    white-space: nowrap;
    font-size: 13px;
    width: auto;
    height: 28px;
    line-height: 28px;
    cursor: pointer;
    .sui_icon_addtoboard_28px_1 {
      font-size: 28px;
      margin-right: 8px;
      color: #222;
    }

    span {
      font-weight: bold;
      vertical-align: top;
    }
  }
}
</style>
