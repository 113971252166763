<template>
  <div
    class="product-intro-zoom__wrap"
    :style="cWrapStyles"
  >
    <slot></slot>

    <template v-if="props.isZoom">
      <div
        class="product-intro-zoom__wrap_image"
        :style="cZoomImageWrapStyle"
      >
        <img
          :src="img"
          :style="cZoomImageStyle"
          @load="onZoomImageLoad"
        />
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'ProductIntroGalleryBigPicZoom',
}
</script>
<script setup>
import { ref, provide, computed } from 'vue'

const props = defineProps({
  /**
   * 是否开启放大镜功能
   */
  isZoom: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['show-big'])
const img = ref('')
const imgPosition = ref({
  x: 0,
  y: 0
})
const imgZoomWidth = ref(0)
const imgPreviewWidth = ref(510)

function handleUpdate(opt = {}){
  if (img.value !== opt.image) {
    img.value = opt.image

    img.value && emit('show-big', img.value)
  }



  if (imgZoomWidth.value !== opt.imgZoomWidth) {
    imgZoomWidth.value = opt.imgZoomWidth
  }
  // if (imgPreviewWidth.value !== opt.imgPreviewWidth) {
  //   imgPreviewWidth.value = opt.imgPreviewWidth
  // }
  imgPosition.value.x = opt.x || 0
  imgPosition.value.y = opt.y || 0
}

const imgLoadMap = ref({})
function onZoomImageLoad() {
  imgLoadMap.value[img.value] = true
}

const cWrapStyles = computed(() => {
  // 只有hover时提高层级，防止对右侧形成遮罩
  if (!img.value) return {}
  return {
    'z-index': 2,
  }
})
const cZoomImageWrapStyle = computed(() => {
  return {
    width: `${imgPreviewWidth.value}px`,
    height: `${imgPreviewWidth.value}px`,
    // 大图完全加载才显示
    opacity: imgLoadMap.value[img.value] && img.value ? 1 : 0
  }
})

const cZoomImageStyle = computed(() => {
  return {
    transform: `translate3d(${imgPosition.value.x}px, ${imgPosition.value.y}px, 0px)`,
    width: imgZoomWidth.value + 'px',
  }
})

provide('galleryBigPicZoom', {
  handleUpdate,
  isZoom: props.isZoom,
  imgPreviewWidth,
  imgLoadMap: imgLoadMap
})

</script>

<style lang="less">
.product-intro-zoom__wrap {
  position: relative;
  &_image {
    position: absolute;
    left: 100%;
    margin-left: 8px;
    top: 0;
    overflow: hidden;
    transition: all 0.2s;
    border: 1px solid #E5E5E5;
  }
}
</style>
