<template>
  <div 
    class="product-selection__content" 
    :class="{ 'product-selection_new': qualityFloor === 'NEW' }"
  >
    <div
      class="product-selection__tag"
      @click="openCatSelectionDrawer"
    >
      <div 
        :class="qualityFloor === 'NEW' ? 'product-selection__tag-title-new' : 'product-selection__tag-title'"
      >
        <template
          v-if="qualityFloor === 'NEW'"
        >
          <Icon        
            name="sui_icon_qualidade_16px_color"
            size="16px"
          />
        </template>
        <span class="product-selection__tag-text">
          ProSelect
        </span>
      </div>
      <div
        v-if="qualityFloor === 'SHOW'"
        class="product-selection__tag-title-svg"
      >
        <template v-if="cssRight">
          <svg
            width="74"
            height="18"
            viewBox="0 0 74 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="65"
              height="18"
              transform="matrix(-1 0 0 1 73.5 0)"
              fill="url(#paint0_linear_1110_1038)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.5 0L1.52588e-05 18H8.5V0Z"
              fill="#040404"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1110_1038"
                x1="0"
                y1="0"
                x2="68.8888"
                y2="12.9778"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#424242" />
                <stop offset="1" />
              </linearGradient>
            </defs>
          </svg>
        </template>
        <template v-else>
          <svg
            width="74"
            height="18"
            viewBox="0 0 74 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="65"
              height="18"
              fill="url(#paint0_linear_1110_1034)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M65 0L73.5 18H65V0Z"
              fill="url(#paint1_linear_1110_1034)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1110_1034"
                x1="0"
                y1="0"
                x2="68.8888"
                y2="12.9778"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#424242" />
                <stop offset="1" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1110_1034"
                x1="-13.4999"
                y1="-0.000100529"
                x2="68.3608"
                y2="18.6122"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#424242" />
                <stop offset="1" />
              </linearGradient>
            </defs>
          </svg>
        </template>
      </div>
      <template
        v-for="(item, index) in descKeyList"
        :key="`descKeyList_${index}`"
      >
        <div 
          v-if="item.langKeyEn"
          :key="`descKeyList_${index}`"
          :class="['product-selection__tag-item', {'mar-left-ten':index !== 0}]"
        >
          <Icon        
            name="sui_icon_success_14px_1"
            size="14px"
            :color="qualityFloor === 'SHOW' ? '#000' : '#745441'" 
          />
          {{ item.langKeyEn }}
        </div>
      </template> 
    </div>
    <div class="product-selection__tag-more">
      <Icon
        name="sui_icon_more_right_14px_1"
        size="14px"
        :color="qualityFloor === 'SHOW' ? '#959595' : '#745441'"
      />
    </div>
    <ClientOnly>
      <s-drawer
        v-model:visible="isShowDrawer"
        class="product-selection__tag-drawer"
        size="440px"
        :append-to-body="true"
      >
        <template #title>
          ProSelect
          <div class="product-selection__sub-title">
            Expertly curated for quality shopping
          </div>
        </template>
        <div 
          v-for="(item, index) in tagItems"
          :key="`tagItems_${index}`"
          class="product-selection__tag-detail-item"
          :style="{ background: item.titleBgColor || '' }"
        >
          <div 
            class="item-title" 
            :style="{ color: item.titleTextColor || '' }"
          > 
            {{ item.titleKeyEn }}
          </div>
          <div 
            class="item-sub-title"
            :style="{ color: item.titleDescTextColor || '' }"
          >
            {{ item.titleDescKeyEn }}
          </div>
          <div class="item-content">
            <div
              v-if="item.tipsType === 'text' && item.tipsKeyEn" 
              class="tag-title"
            >
              {{ item.tipsKeyEn }}
            </div>
            <div
              v-if="item.tipsType === 'picture' && item.tipsPicInfo && item.tipsPicInfo.src" 
              class="tag-title tt-center"
            >
              <img
                class="tag-picture"
                :src="transformImg({img: item.tipsPicInfo.src})"
              />
            </div>
            <template v-if="item.content && item.content.length">
              <div 
                v-for="(tag, cIndex) in item.content"
                :key="`tagItems_${cIndex}`"
                :class="[
                  'tag-list-common', 
                  {'tag-poiont-list': tag.type === 'pointList'},
                  {'tag-poiont-listtop': tag.type === 'pointList' && cIndex === 0},
                  {'tag-check-list': tag.type === 'checkList'}, 
                  {'tag-p-list': tag.type === 'text'}
                ]"
              >
                <Icon        
                  v-if="tag.type === 'checkList'"
                  name="sui_icon_success_14px_1"
                  size="14px"
                  color="#000" 
                />
                <Icon        
                  v-if="tag.type === 'pointList'"
                  name="sui_icon_messenge_right_14px"
                  size="6px"
                  color="#000" 
                />
                {{ tag.langKeyEn }}
              </div>
            </template>
          </div>
        </div>
      </s-drawer>
    </ClientOnly>
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { transformImg } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '1-6-1' })
// let PUBLIC_CDN

// if (typeof window !== 'undefined') {
//   PUBLIC_CDN = gbCommonInfo.PUBLIC_CDN
// }

export default {
  name: 'ProductIntroCatSelectionTag',
  components: { ClientOnly, Icon },
  props: {
    preferredData: {
      type: Object,
      default: () => ({})
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => ({})
    },
    qualityFloor: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // PUBLIC_CDN,
      // loaded: false,
      isShowDrawer: false,
    }
  },
  computed: {
    // renderTitleBackGround() {
    //   if (this.loaded) {
    //     return `url(${PUBLIC_CDN}/she_dist/images/tag_rectangle-3a49ae0846.png)`
    //   } else {
    //     return null
    //   }
    // },
    descKeyList() {
      return this.preferredData?.descKeyList || []
    },
    tagItems() {
      return this.preferredData?.items || []
    }
  },
  mounted() {
    // this.loaded = true
    daEventCenter.triggerNotice({ daId: '1-8-6-180' })
  },
  methods: {
    openCatSelectionDrawer() {
      this.isShowDrawer = true
      daEventCenter.triggerNotice({ daId: '1-8-6-181' })
      daEventCenter.triggerNotice({ daId: '1-8-6-182' })
    },
    transformImg,
  },
}
</script>

<style lang="less">
.product-selection {
  &__content {
    margin-top: 15px;
    display: inline-flex;
    width: 100%;
    align-items: center;
    .mar-left-ten {
      .margin-l(10px);
    }
  }
  &__tag{
    position: relative;
    flex: 1;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    background: linear-gradient(90deg, rgba(244, 213, 114, 0.34) 24.53%, rgba(244, 213, 114, 0.00) 100%);
  }

  &__tag-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 74px;
    min-width: 74px;
    height: 18px;
    color: #FCDF9D;
    font-family: Arial;
    font-size: 12px;
    font-weight: 700;
    text-indent: 6px;
    line-height: 18px;
  }
  &__tag-title-svg {
    width: 74px;
    min-width: 74px;
    height: 18px;
    .margin-r(4px);
  }
  &__tag-item {
    font-family: Arial;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-transform: capitalize;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .sui-icon-common__wrap {
      line-height: 1!important;/* stylelint-disable-line declaration-no-important */
    }
  }  
  &__tag-more {
    width: 16px;
    .margin-l(12px);
    cursor: pointer;
  }
  &__tag-drawer {
    .sui-drawer__container {
      background: linear-gradient(180deg, #FEECC5 0%, #FFF 10.53%), #FFF;
    }
    .sui-drawer__title {
      color: #222;
      font-family: Arial Black;
      font-size: 20px;
      font-weight: 900;
    }
    .sui-drawer__title {
      padding-bottom: 24px;
    }
    .sui-drawer__body {
      padding: 0 24px 32px;
    }
  }
  &__sub-title {
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    color: #000;
    margin-top: 6px;
  }
  &__tag-detail-item {
    margin-bottom: 24px;
    border-radius: 6px;
    padding: 16px 4px 4px 4px;
    font-family: Arial;
    font-style: normal;
    line-height: normal;
    background: linear-gradient(107deg, #424242 0%, #000 99.05%);
    .item-title {
      color: #FCDF9D;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    .item-sub-title {
      color: rgba(252, 223, 157, 0.60);
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 16px;
    }
    .item-content {
      padding: 16px 12px;
      border-radius: 3px;
      background: #FFF;
    }
    .tag-title {
      font-size: 14px;
      font-weight: 700;
      text-transform: capitalize;
      color: #000;
      padding-bottom: 16px;
      border-bottom: 1px dashed @sui_color_gray_weak1;
    }
    .tt-center {
      text-align: center;
    }
    .tag-picture {
      height: auto;
      width: 100%;
    }
    .tag-list-common {
      font-family: Arial;
      color: #000;
      font-weight: 400;
    }
    .tag-poiont-list {
      position: relative;
      padding-left: 18px;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      text-transform: capitalize;
      margin-top: 6px;
      .sui-icon-common__wrap {
        position: absolute;
        top: -1px;
        left: 0;
        line-height: 1!important;/* stylelint-disable-line declaration-no-important */
      }
    }
    .tag-poiont-listtop {
      margin-top: 16px;
    }
    .tag-check-list {
      position: relative;
      padding-left: 18px;
      font-size: 13px;
      margin-top: 16px;
      .sui-icon-common__wrap {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1!important;/* stylelint-disable-line declaration-no-important */
      }
    }
    .tag-p-list {
      font-size: 13px;
      font-style: normal;
      line-height: 16px;
      margin-top: 16px;
    }
  }
}
.product-selection_new{
  border-radius: 2px;
  background: linear-gradient(90deg, #FFEED5 0.21%, #FFF6E8 24.17%, #FFFAF2 99.81%);
  padding: 0 4px;
  .product-selection{
    &__tag{
      height: 28px;
      background: none;
    }
    &__tag-text{
      margin-left: 2px;
      color: #FFFAEE;
      font-size: 11px;
      font-weight: 600;
      -webkit-text-stroke-width: 0.20000000298023224;
      -webkit-text-stroke-color: #000;  
    }
    &__tag-title-new {
      display: flex;
      align-items: center;
      height: 18px;
      padding: 0 6px 0 4px;
      flex-shrink: 0;
      border-radius: 2px;
      background: linear-gradient(90deg, #000 0.02%, #505050 99.98%);
      box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.12) inset;
      border-radius: 2px;
      margin-right: 9px;
    }
    &__tag-item{
      color: #745441;
    }
  }
}
</style>
