const sizeInfoStrFilterField = [
  'size',
  'attr_id',
  'attr_name',
  'attr_value_id',
  'attr_value_name',
  'attr_value_name_en',
]

export const getSizeDesc = (info, index, isShowBaseCode, sizeInfoDes) => {
  const result = []
  info.forEach(_ => {
    const item = _.list[index]
    const list = []
    for (const k in item) {
      if (sizeInfoStrFilterField.includes(k)) continue
      const val = item[k].replace(/(cm|inch)/g, '').trim()
      if (val && val !== '/') {
        list.push({ k: k.trim(), val: item[k].trim() })
      }
    }
    if (list.length) {
      result.push({
        name: _.name,
        list
      })
    }
  })
  if (isShowBaseCode.value) {
    let baseSizeSortMap = sizeInfoDes.value.basicAttribute.base_atte_sort || null
    if (baseSizeSortMap) {
      for (let item of result) {
        item.list = item.list.sort((a, b) => baseSizeSortMap[a.k] - baseSizeSortMap[b.k])
      }
    }
  }
  return result
}

/**
 * 格式化身高信息
 */
export const formatHeightSize = (h, type) => {
  if (!h.length) return ''
  try {
    let numberStr = h.replace(/(cm|inch)/g, '').trim()
    let numberArr = numberStr.split('-')
    if (isNaN(numberArr[0])) return ''
    if (numberArr.length === 2) {
      // 104.3-110.3 cm
      return `(${Number(numberArr[0]).toFixed()}-${Number(numberArr[1]).toFixed()}${type})`
    } else {
      // 104.3cm
      let singleNumber = numberArr[0].split(type)
      return `(${Number(singleNumber[0]).toFixed()}${type})`
    }
  } catch (error) {
    return ''
  }
}
