import { getCurrentInstance, ref, toRefs, computed } from 'vue'
import { template } from '@shein/common-function'

export default function useSizeInfoIndex(props, reactiveInfo, others) {
  const instance = getCurrentInstance()
  const { baseInfo, mallCode, config } = toRefs(props) || {}
  const { 
    skuMap,
    dataMapOrder,
    dataMap,
    dataIdMap,
    skuIdMap,
    skcSaleAttr,
    skuList,
    skcName,
    skuAllInfo,
  } = reactiveInfo || {}
  const { isMobile, agenSizeHover, mallClick, compose } = others || {}
  let skuInfo = ref({})
  let sizeInfoIndexBak = ref('{}')
  let sizeInfoIndex = ref({})
  let firstCompose = ref(true)
  let userSelectSize = ref(false)
  let notSelectedTips = ref([])

  const openIdMap = computed(() => {
    const { OPEN_DETAIL_SIZE_IDMAP } = config.value || {}
    return !!(OPEN_DETAIL_SIZE_IDMAP && dataIdMap.value && skuIdMap.value)
  })

  const getRealTimeSkuInfo = (skuInfoObj) => {
    const { sku_code } = skuInfoObj || {}
    const realTimeData = skuAllInfo.value?.[sku_code] || {}
    let mall = {}
    if (realTimeData.mall_price?.length) {
      Object.keys(skuInfoObj.mall || {}).map(mallCode => {
        let realMallPrice = realTimeData?.mall_price?.find?.(item => item.mall_code == mallCode)
        mall[mallCode] = Object.assign(skuInfoObj.mall[mallCode], realMallPrice, { promotionInfo: realTimeData.promotionInfo })
      })
    }
    return Object.assign(
      skuInfoObj,
      realTimeData,
      realTimeData.mall_price?.length ? { mall } : {}
    )
  }

  const chooseCheck = (attrId, attrName, attrValueName, mallCode, attrValueId = '') => {
    let str = ''
    let idStr = ''
    dataMapOrder?.value?.forEach?.((id) => {
      if (id == attrId) {
        str += `${attrName}__${attrValueName},`
        idStr += `${attrName}__${attrValueId},`
        return
      }
      const { attr_name, attr_value_name, attr_value_id } = sizeInfoIndex.value[id] || {}
      str += (attr_value_name ? `${attr_name}__${attr_value_name},` : ',')
      idStr += (attr_value_name ? `${attr_name}__${attr_value_id},` : ',')
    })
    str += (mallCode || '')
    idStr += (mallCode || '')
    if (openIdMap.value) {
      return dataIdMap.value[idStr]
    }
    return dataMap.value[str]
  }

  const mallCheck = (str, isIdMap = false) => {
    // 没有这种属性项+mall的组合
    if (!skcSaleAttr?.value?.length) return
    const mallList = baseInfo?.value?.mall_info
    if (chooseCheck(-1, '', '', mallCode?.value) === undefined) {
      const optionalMallCode = {}
      let hasStockMall = false
      let mallCode = ''
      // 提取有库存的并且可组合的mallCode
      const targetMapData = isIdMap ? dataIdMap : dataMap
      for (let k in targetMapData?.value || {}) {
        if (k && k.indexOf(str) > -1) {
          const attr = k.split(',')
          if (targetMapData?.value?.[k] > 0) {
            hasStockMall = true
          }
          optionalMallCode[attr[attr.length - 1] || ''] = targetMapData.value[k]
        }
      }
      // mallList的优先级是降序的
      for (let i = 0; i < mallList.length; i++) {
        const mall_code = mallList[i].mall_code
        // 优先找有库存的mall
        if (hasStockMall) {
          if (optionalMallCode[mall_code]) {
            mallCode = mall_code
            break
          }
        } else {
          // 所有mall都没库存，选取优先级最高的那个mall
          if (optionalMallCode[mall_code] == 0) {
            mallCode = mall_code
            break
          }
        }
      }
      if (typeof window !== 'undefined') {
        instance.proxy.$toast(template(baseInfo.value.mall?.[mallCode]?.mall_name), 1500)
      }
      const mallStock = chooseCheck(-1, '', '', mallCode)
      mallClick({
        mallCode,
        mallStock,
      })
      return mallStock
    }
    return chooseCheck(-1, '', '', mallCode?.value)
  }

  const sizeEmit = (item = {}) => {
    let str = ''
    let idStr = ''
    let attrs = []
    let skuInfoObj = {}
    dataMapOrder?.value?.forEach?.((id) => {
      const sizeInfo = sizeInfoIndex.value[id]
      const { attr_value_name, attr_name, attr_value_id } = sizeInfo || {}
      attrs.push({ attr_id: id, ...sizeInfo })
      str += (attr_value_name ? `${attr_name}__${attr_value_name},` : ',')
      idStr += (attr_value_name ? `${attr_name}__${attr_value_id},` : ',')
    })
    // 检测最新选择的销售属性和当前的mall能否组成一个正确的商品
    const mallStock = openIdMap.value ? mallCheck(idStr, true) : mallCheck(str)
    
    // 备份一下
    sizeInfoIndexBak.value = JSON.stringify(sizeInfoIndex.value)
    if (skuList?.value?.length == 1 && skcSaleAttr?.value?.length == 0) {
      skuInfoObj = { ...skuList.value[0], skc_name: skcName.value }
    }
    if (skuMap?.value?.[str]) {
      skuInfoObj = { ...skuMap.value[str], skc_name: skcName.value }
    }
    if (openIdMap.value && skuIdMap?.value?.[idStr]) {
      skuInfoObj = { ...skuIdMap.value[idStr], skc_name: skcName.value }
    }
    skuInfoObj = getRealTimeSkuInfo(skuInfoObj)
    // Todo: 为什么要这样写？
    // skuInfo?.sku_sale_attr?.forEach?.(item => {
    //   if (typeof item !== 'object') return
    //   // 最终显示在页面的属性name
    //   item.sku_calc_name = this.calcSize(item) || ''
    // })
    skuInfo.value = skuInfoObj
    compose(JSON.parse(
      JSON.stringify({
        attrs,
        mallStock,
        skuInfo: skuInfoObj,
        externalSizeInfoIndex: sizeInfoIndex.value,
        curSelectAttr: item,
        isFirst: firstCompose.value
      })
    ))
    firstCompose.value = false
    return openIdMap.value ? idStr : str
  }

  const initSizeInfoIndex = (type, needSetSkuValue) => {
    // 读备份数据
    if (sizeInfoIndexBak.value != '{}') {
      const sizeInfoIndexBakCopy = JSON.parse(sizeInfoIndexBak.value)
      for (let k in sizeInfoIndexBakCopy) {
        if (!sizeInfoIndex.value?.[k]?.attr_value_id) {
          sizeInfoIndex.value[k] = sizeInfoIndexBakCopy[k]
        }
      }
    }
    // 初始化数据
    skcSaleAttr?.value?.forEach?.(attr => {
      const { attr_id, attr_name } = attr
      const sizeInfoIndexItem = sizeInfoIndex.value[attr_id] 
      if (
        !sizeInfoIndexItem ||
            (sizeInfoIndexItem?.attr_value_name && chooseCheck(attr_id, attr_name, sizeInfoIndexItem.attr_value_name, '', sizeInfoIndexItem.attr_value_id) === undefined)
      ){
        sizeInfoIndex.value[attr_id] = {
          attr_name,
          attr_value_name: '',
          attr_value_id: ''
        }
      }
    })
    // 再根据skuInfo去修改选中值
    if (type === 'needSetSkuInfo' && needSetSkuValue) {
      const skuInfo = skuList?.value?.find?.(
        _ => _.sku_code == needSetSkuValue
      )
      if (skuInfo) {
        skuInfo.sku_sale_attr.forEach(attr => {
          const { attr_id, attr_name, attr_value_name, attr_value_id } = attr || {}
          sizeInfoIndex.value[attr_id] = {
            attr_name,
            attr_value_name,
            attr_value_id,
          }
        })
        sizeEmit()
        return
      }
    }
    // 或者根据传递出去的externalSizeInfoIndex修改选中值
    if (type === 'needSetSizeIndex' && needSetSkuValue && Object.keys(needSetSkuValue).length > 0) {
      skcSaleAttr?.value?.forEach?.(item => {
        const subItem = item.attr_value_list.find(_ => _.attr_value_id == needSetSkuValue[item.attr_id].attr_value_id)
        sizeInfoIndex.value[item.attr_id] = Object.assign({ attr_name: item.attr_name }, subItem || {
          attr_value_name: '',
          attr_value_id: ''
        })
      })
    }
    let data = {}
    if (skuList?.value?.length === 1) {
      data = skuList.value[0].sku_sale_attr.reduce((prev, item) => {
        const { attr_id, attr_value_id } = item || {}
        prev[attr_id] = attr_value_id
        return prev
      }, {})
    }
    skcSaleAttr?.value?.forEach?.(item => { 
      let attr = null
      const { attr_value_list, attr_id, attr_name } = item || {}
      if (attr_value_list?.length === 1) {
        attr = attr_value_list[0]
      }else if (skuList?.value?.length === 1) {
        attr = attr_value_list.find(_ => _.attr_value_id == data[item.attr_id])
      }
      if (attr) {
        sizeInfoIndex.value[attr_id] = {
          ...(sizeInfoIndex.value[attr_id] || {}),
          attr_name,
          ...attr
        }
      }
    })
    sizeEmit()
  }

  const handleChoose = (e, isEnter, item, subItem, auto) => {
    if (isMobile && !auto) return
    if (isMobile) {
      agenSizeHover()
      agenSizeHover(e, isEnter)
    }
    if (chooseCheck( item.attr_id, item.attr_name, subItem.attr_value_name, '', subItem.attr_value_id ) !== undefined){
      if (sizeInfoIndex.value[item.attr_id].attr_value_id === subItem.attr_value_id ) {
        sizeInfoIndex.value[item.attr_id] = {
          attr_name: item.attr_name,
          attr_value_name: '',
          attr_value_id: ''
        }
      }else {
        sizeInfoIndex.value[item.attr_id] = {
          attr_name: item.attr_name,
          ...subItem
        }
        notSelectedTips.value = notSelectedTips.value.filter(_ => _ != item.attr_id)
      }
    }else {
      if (!config?.value?.isNoSkuClick) return
      for (let attr_id in sizeInfoIndex.value) {
        if (attr_id == item.attr_id) {
          sizeInfoIndex.value[attr_id] = {
            attr_name: item.attr_name,
            ...subItem
          }
          notSelectedTips.value = notSelectedTips.value.filter(_ => _ != item.attr_id)
        } else {
          sizeInfoIndex.value[attr_id] = {
            ...sizeInfoIndex.value[attr_id],
            attr_value_name: '',
            attr_value_id: ''
          }
        }
      }
    }
    userSelectSize.value = true
    sizeEmit({
      attr_id: item.attr_id,
      ...subItem
    })
  }

  const handleChooseTouch = (e, isEnter, item, subItem) => {
    if (isMobile) {
      handleChoose(e, isEnter, item, subItem, 'auto')
    }
  }

  const updateNotSelectedTips = (init = false) => {
    if (init) {
      notSelectedTips.value = []
      return
    }
    const arr = []
    for (const k in sizeInfoIndex.value) {
      if (!sizeInfoIndex.value[k]?.attr_value_id) {
        arr.push(k)
      }
    }
    notSelectedTips.value = arr
  }

  return {
    sizeInfoIndex,
    skuInfo,
    notSelectedTips,
    chooseCheck,
    initSizeInfoIndex,
    handleChoose,
    handleChooseTouch,
    updateNotSelectedTips,
    userSelectSize,
    sizeEmit,
  }
}
