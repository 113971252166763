<template>
  <div 
    class="add-cart__btn-contentwithprice type-b"
    :style="{'color': strategyStyle?.color}"
  >
    <div 
      v-if="!isStrengthen" 
      class="text add-carttext-style"
    >
      {{ defaultBtnText }}
    </div>
    <div 
      v-expose="exposeValue"
      class="type-b-price"
      :class="{
        'strengthen-to-top': isStrengthen
      }"
    >
      <!-- 利益点文案 -->
      <img 
        v-if="showBenefitText && benefitText && strategyStyle?.icon" 
        class="benefit-icon" 
        :src="strategyStyle?.icon"
      />
      <div 
        v-show="showBenefitText && benefitText" 
        class="benefit" 
        :style="{'color': strategyStyle?.color}"
        v-html="benefitText"
      >
      </div>
    </div>
    <div 
      v-if="isStrengthen" 
      class="text add-carttext-style"
    >
      {{ defaultBtnText }}
    </div>
  </div>
</template>
<script name="BtnContentWithPrice" setup>
/**
 * @component 加车按钮内底部行价格展示
 */
import { template } from '@shein/common-function'
import { computed, toRefs, ref, reactive } from 'vue'


const props = defineProps({
  // 是否展示价格
  showPrice: {
    type: Boolean,
    default: false,
  },
  // 展示类型，与abt [QuickShow] 的值一致
  showType: {
    type: String,
    default: '',
  },
  defaultBtnText: {
    type: String,
    default: '',
  },
  // 价格信息
  priceInfo: {
    type: Object,
    default: () => ({}),
  },
  // 是否评论弹窗内
  isReview: {
    type: Boolean,
    default: false,
  },
  // 曝光abt
  exposeAbt: {
    type: String,
    default: '',
  },
  // 使用折扣样式
  useDiscountStyle: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  getEstimatedInfo: {
    type: Object,
    default: null
  },
  fsAbt: {
    type: Object,
    default: () => ({})
  },
  countdownTips: {
    type: String,
    default: '',
  },
  isShowCountdown: {
    type: Boolean,
    default: false,
  },
  isInversion: {
    type: Boolean,
    default: false,
  },
  complianceMode: {
    type: Boolean,
    default: false,
  },
  complianceTipsMode: {
    type: Boolean,
    default: false,
  },
  bestDealConfig: {
    type: Object,
    default: () => ({})
  },
  soldoutState: {
    type: Object,
    default: () => ({})
  },
  // 闪购倒计时
  flashCountdownTips: {
    type: String,
    default: '',
  },
  isShowflashCountdown: {
    type: Boolean,
    default: false,
  },
  discountPercent: {
    type: String,
    default: '',
  },
  // 命中价格置顶实验quickprice=Ceiling时，加车按钮只展示利益点内容，无需展示价格
  isDetailBuyNowPriceCeiling: {
    type: Boolean,
    default: false,
  },
  // CCC返回的内容顺序及内容标识，判断是否具备利益点文案展示的条件
  cccAddCartPrompt: {
    type: Array,
    default: () => ([]),
  },
  // 是否展示利益点文案
  showBenefitText: {
    type: Boolean,
    default: true,
  },
  isStrengthen: {
    type: Boolean,
    default: false,
  },
  isBrandFlash: {
    type: Boolean,
    default: false,
  },
  isHitComplianceMode: {
    type: Boolean,
    default: false,
  },
  showPriceBeltColor: {
    type: Boolean,
    default: false,
  },
  isPaidUser: {
    type: Boolean,
    default: false,
  },
  sheinClubPromotionInfo: {
    type: Object,
    default: () => null
  },
  strengthenColor: {
    type: Boolean,
    default: true,
  },
  isWhiteBtn: {
    type: Boolean,
    default: false,
  },
  // validateParams: {
  //   type: Object,
  //   default: () => ({}),
  // },
})
const emit = defineEmits(['benefitText'])

let {
  defaultBtnText,
  language,
  countdownTips,
  isShowCountdown,
  bestDealConfig,
  soldoutState,
  flashCountdownTips,
  isShowflashCountdown,
  discountPercent,
  cccAddCartPrompt,
  isStrengthen,
  showBenefitText,
  isWhiteBtn,
  isBrandFlash,
  isHitComplianceMode,
  showPriceBeltColor,
  isPaidUser,
  sheinClubPromotionInfo,
  strengthenColor,
  // validateParams
} = toRefs(props)
// 仅仅 B 在线

const benefitMap = {
  // 库存不足
  inventory() {
    const { showSoldOutTips, soldOutTips } = soldoutState.value
    return showSoldOutTips ? soldOutTips : ''
  },
  // 到手价倒计时 ||  闪购倒计时
  countdown() {
    if(isShowCountdown.value) {
      return countdownTips.value
    }
    if(isShowflashCountdown.value) {
      return flashCountdownTips.value
    }
  },
  // // 同款最低价 || 历史最低价
  // sameitemprice() {
  //   return bestDealConfig.value?.text || ''
  // },
  // 同款最低价 
  sameitemprice() {
    return bestDealConfig.value?.sameitemprice?.contentText || ''
  },
  // 历史最低价
  historyprice() {
    return bestDealConfig.value?.historyprice?.contentText || ''
  },
  // 折扣说明
  normaldiscount() {
    if(discountPercent.value) {
      return template(
        discountPercent.value,
        language.value.SHEIN_KEY_PC_31111,
      )
    }
  }
}
let strategyStyle = reactive({ color: '', icon: '' }) 
const getCurBenefitItem = () => {
  if(!showBenefitText.value){ // 售罄，或者双按钮时，不强化颜色
    strategyStyle.color = ''  
  } else if(isStrengthen.value && strengthenColor.value){
    if(isHitComplianceMode.value){ // 合规价时，写死白色
      strategyStyle.color = isWhiteBtn.value ? '' : '#FFFFFF'
    } else if(isPaidUser.value && sheinClubPromotionInfo.value){ // 满足已开通会员价的颜色
      strategyStyle.color = '#FFBA97'  
    } else if(showPriceBeltColor.value && isBrandFlash.value){ // 满足品牌特卖的颜色
      strategyStyle.color = '#FF8957'
    } else if(showPriceBeltColor.value && !isBrandFlash.value){  // 满足闪购活动的颜色
      strategyStyle.color = '#FFEDA2' 
    } else if(!isWhiteBtn.value){ // 默认强化为白色
      strategyStyle.color = '#FFFFFF'
    } else{
      strategyStyle.color = ''
    }
  }
  if(!cccAddCartPrompt.value?.length) return {}

  for (let i = 0; i < cccAddCartPrompt.value.length; i++) {
    const item = cccAddCartPrompt.value[i]
    const promptCode = item.promptCode
    if (benefitMap[promptCode]) {
      let text =  benefitMap[promptCode]()
      if(text){
        // 处理文案的图标icon
        const iconType = item.iconType || []
        const isDynamicImg = item.iconPickType == 2
        const isStaticImg = item.iconPickType == 1
        if(isStrengthen.value && (isStaticImg || isDynamicImg)){
          const imgType = isStaticImg ? 'staticImg' : 'dynamicImg' // 判断是取静态图还是动态图
          if(isHitComplianceMode.value){ // 合规价时，写死白色
            strategyStyle.icon = iconType?.find?.(it => it.iconCode == 'normalicon')?.[imgType] || ''
          } else if(isPaidUser.value && sheinClubPromotionInfo.value){ // 满足已开通会员价的sheinclubicon
            strategyStyle.icon = iconType?.find?.(it => it.iconCode == 'sheinclubicon')?.[imgType] || ''
          } else if(showPriceBeltColor.value && isBrandFlash.value){ // 满足品牌特卖的brandsaleicon
            strategyStyle.icon = iconType?.find?.(it => it.iconCode == 'brandsaleicon')?.[imgType] || ''
          } else if(showPriceBeltColor.value && !isBrandFlash.value){  // 满足闪购活动的flashsaleicon
            strategyStyle.icon = iconType?.find?.(it => it.iconCode == 'flashsaleicon')?.[imgType] || ''
          } else{ // 默认normalicon
            strategyStyle.icon = iconType?.find?.(it => it.iconCode == 'normalicon')?.[imgType] || ''
          }
        }
        return {
          promptCode,
          text
        }
      }
    }
  }
  return {}
}
const benefitText = computed(() => {
  const { text = '', promptCode } = getCurBenefitItem()
  if(text) emit('benefitText', promptCode)
  return text
})

const exposeValue = ref({
  id: '1-8-6-233',
  once: true,
  data: {
    review_location: 'page_goods_detail',
    interestpoints: getCurBenefitItem()?.promptCode,
  }
})
</script>

<style lang="less">
.add-cart__btn-contentwithprice {
  &.type-b {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1;

    .text {
      font-size: 15px;
    }

    .retail{
      color: @sui_color_gray_light2;
      font-size: 12px;
      text-decoration-line: line-through;
      margin-left: 4px;
    }
    .benefit-icon{
      width: 12px;
      height: 12px;
      margin: 0 2px 0;
    }
    .benefit{
      color: rgba(255, 255, 255);
      text-align: center;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: Arial, Helvetica, sans-serif;
    } 
  }
  .type-b-price {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    margin-top: 2px;
    text-transform: none;
    &.strengthen-to-top{
      margin-top: 0px;
      margin-bottom: 2px;
    }
    .discount {
      color: @sui_color_promo;
    }
  
    .vip {
      color: @sui_color_club_rosegold;
    }
  }

  .estimated-price {
    font-weight: 400;
    font-size: 12px;
    margin-top: 2px;
    color: @sui_color_promo;
    text-transform: none;
    display: flex;
    align-items: center;
  }
  .estimated-price-vip {
    color: @sui_color_club_rosegold;
  }
  .add-cart__count-down{
    font-size: 12px;
    color: rgba(255, 255, 255);
    margin-left: 4px;
  }
}
.add-carttext-style {
  width: 100%;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
</style>
