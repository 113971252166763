<template>
  <div
    v-if="
      config.isCustomization &&
        !(config.isCurrentSoldOut || config.isAllSoldOut)
    "
    class="product-intro__add-tips"
  >
    {{ language.SHEIN_KEY_PC_27606 }}
    <a
      class="product-intro__add-tips-link"
      @click="jumpToArticle"
    >
      {{ language.SHEIN_KEY_PC_22233 }}
    </a>
  </div>
  <!-- earn 售罄不展示-->
  <div v-if="!(config.isCurrentSoldOut || config.isAllSoldOut || config.IS_RW)">
    <div
      v-if="rewardPoints > 0"
      class="product-intro__add-tips"
    >
      <span v-html="integralTips"></span>
    </div>
  </div>
  <div v-if="config.showRWIntegralTips">
    <div class="product-intro__integral-tips">
      <span v-html="integralTips"></span>
    </div>
  </div>
</template>

<script name="BtnTips" setup>
defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  config: {
    type: Object,
    default: () => ({}),
  },
  rewardPoints: {
    type: Number,
    default: 0,
  },
  integralTips: {
    type: String,
    default: '',
  },
})

const jumpToArticle = () => {
  const { IS_EUR_DATA_CENTER, SiteUID } = gbCommonInfo
  let articleId = '1925'
  if (IS_EUR_DATA_CENTER) {
    articleId = '1819'
  }
  if (['us'].includes(SiteUID)) {
    articleId = '1898'
  }
  window.open(`/product/article/${articleId}`)
}
</script>

<style lang="less">
.product-intro {
  &__add-tips {
    margin-top: 10px;
    font-size: 12px;
    color: #767676;

    &-link {
      color: @sui_color_link;
      &:hover {
        color: @sui_color_link;
        cursor: pointer;
      }
    }
  }
  &__integral-tips {
    margin-top: 9px;
    font-size: 12px;
    color: #959595;
    .num {
      color: @sui_color_highlight;
    }
  }
}
</style>
