import { onMounted } from 'vue'
import { getPopupInstance } from 'public/src/pages/goods_detail_v2/components/RecommendRelatives/GetTheLookNew/main-load.js'

export const usePreloadGetTheLookPopup = () => {
  onMounted(() => {
    setTimeout(() => {
      console.log('usePreloadGetTheLookPopup')
      getPopupInstance()
    }, 500)
  })
}
