<script>
export default {
  name: 'SizeSelectItem'
}
</script>
<script setup>
import { defineProps, computed, inject } from 'vue'
import SizeSelectItemTitle from '../SizeSelectItemTitle/index.vue'
import SizeSelectItemLabel from '../SizeSelectItemLabel/index.vue'
import SizeSelectItemRelated from '../SizeSelectItemRelated/index.vue'
import { Icon } from '@shein-aidc/icon-vue3'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  index: {
    type: Number,
    default: 0,
  },
  relatedLocalSize: {
    type: Object,
    default: () => ({}),
  },
  sizeInfoIndex: {
    type: Object,
    default: () => ({}),
  },
  notSelectedTipsAnim: {
    type: Boolean,
    default: false,
  },
  localSizeComputed: {
    type: Object,
    default: () => ({}),
  },
  heightSizeObject: {
    type: Object,
    default: () => ({}),
  },
  sizePopoverPlacemen: {
    type: Array,
    default: () => [],
  },
  bubbleInfoComputed: {
    type: Object,
    default: () => ({}),
  },
  currentSelectedCountry: {
    type: String,
    default: '',
  },
  notSelectedTips: {
    type: Object,
    default: () => ({}),
  },
  foldBtn: {
    type: Array,
    default: () => [],
  },
  foldIndex: {
    type: Number,
    default: -1,
  },
  isAllSoldOut: {
    type: Boolean,
    default: false,
  },
  country: {
    type: String,
    default: '',
  },
  sizeGroupPosition: {
    type: String,
    default: '',
  },
  hideOneSize: {
    type: Boolean,
    default: false,
  },
  constant: {
    type: Object,
    default: () => ({}),
  },
  handleOpenFold: {
    type: Function,
    default: () => {},
  },
})
const setSizeRefs = inject('setSizeRefs')
const language = inject('language')
const showSmallRelate = computed(() => !props.sizeGroupPosition && props.relatedLocalSize?.relateGood && props.item.attr_id == 87 && props.relatedLocalSize?.relateGoodType === 0)
const showBigRelate = computed(() => !props.sizeGroupPosition && props.relatedLocalSize?.relateGood && props.item.attr_id == 87 && props.relatedLocalSize?.relateGoodType === 1)
const showItemContent = computed(() => props.item.attr_value_list?.length || (props.item.attr_id == 87 && props.relatedLocalSize?.relateGood))
const showErrorAni = computed(() => props.sizeInfoIndex[props.item.attr_id]?.attr_value_id == '' && props.notSelectedTipsAnim)

const showContent = computed(() => {
  return !props.hideOneSize && (props.foldIndex < 0 ||  props.foldIndex >= props.index)
})
</script>

<template>
  <div 
    v-show="showContent"
    class="product-intro__size"
  >
    <SizeSelectItemTitle 
      :item="item"
      :index="index"
      :local-size-computed="localSizeComputed"
      :current-selected-country="currentSelectedCountry"
      :not-selected-tips-anim="notSelectedTipsAnim"
      :not-selected-tips="notSelectedTips"
      :country="country"
    />
    <div
      v-if="showItemContent"
      :ref="setSizeRefs('product-intro__size-choose', index)"
      class="product-intro__size-choose"
      :class="{ 'animated animate-shake': showErrorAni }"
    >
      <SizeSelectItemRelated
        v-if="showSmallRelate"
        :constant="constant"
        :related-local-size="relatedLocalSize"
        :size-popover-placemen="sizePopoverPlacemen"
        :current-selected-country="currentSelectedCountry"
      />
      <template
        v-for="(subItem, subIndex) in item.attr_value_list"
        :key="subIndex + 1"
      > 
        <SizeSelectItemLabel
          :item="item"
          :index="index"
          :sub-item="subItem"
          :sub-index="subIndex"
          :local-size-computed="localSizeComputed"
          :height-size-object="heightSizeObject"
          :size-info-index="sizeInfoIndex"
          :is-all-sold-out="isAllSoldOut"
          :size-popover-placemen="sizePopoverPlacemen"
          :bubble-info-computed="bubbleInfoComputed"
        />
      </template>
      <SizeSelectItemRelated
        v-if="showBigRelate"
        :constant="constant"
        :related-local-size="relatedLocalSize"
        :size-popover-placemen="sizePopoverPlacemen"
        :current-selected-country="currentSelectedCountry"
      />
    </div>
    <div
      v-if="foldBtn[index]"
      class="product-intro__sizes-more"
      @click="handleOpenFold"
    >
      {{ language.SHEIN_KEY_PC_26830 || 'View All' }}
      <Icon 
        name="sui_icon_more_down_12px_1" 
        size="12px"
        :custom-style="{ verticalAlign: 'text-top'}"
      />
    </div>
  </div>
</template>

<style lang="less">
.product-intro {
  &__sizes-more {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color: @sui_color_gray_dark3;
    margin-bottom: 6px;
    cursor: pointer;
  }
  &__size-radio-spopover {
    max-width: calc(100% - 2px);
    .product-intro__size-radio {
      margin: 0 12px 12px 0;
    }
  }
  &__sizes-item-text--one {
    .text-overflow();
  }
  &__sizes-item-text--two {
    .line-camp();
    text-align: left;
  }
  &__size-popover-content {
    line-height: 1.33;
    .txt-l();
  }
  &__size-popover-detail {
    color: #1860a7;
    padding-top: 6px;
    cursor: pointer;
  }
  &__size-popover-img {
    background-size: cover;
    background-repeat: none;
    float: left;
    /* sh:begin*/
    height: 70px;
    margin-right: 15px;
    width: 53px;

    /* rw:begin*/
    height: 90px;
    margin-right: 10px;
    width: 68px;
  }
  &__size-popover-stitle {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* sh:begin*/
    color: #222222;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;

    /* rw:begin*/
    color: #9b9b9b;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
  }
  &__size-popover-gtitle {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* sh:begin*/
    color: #222;
    font-family: ArialMT;
    margin-top: 10px;

    /* rw:begin*/
    color: #222;
    font-family: Roboto-Regular, Roboto;
    margin-top: 6px;
  }
  &__size-popover-gprice {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* sh:begin*/
    color: #222;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    margin-top: 2px;

    /* rw:begin*/
    color: #333;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    margin-top: 6px;

    .is-red {
      color: #d53333;
    }
  }
}
</style> 
                                                                         
