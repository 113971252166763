<template>
  <div class="product-intro__head-reviews">
    <span
      v-if="allCommentInfoNum && allCommentInfoNum > 0"
      tabindex="0"
      :aria-label="
        language.SHEIN_KEY_PC_14660 +
          ' ' +
          commentInfo.comment_rank_average +
          ' ' +
          allCommentInfoNum +
          ' ' +
          language.SHEIN_KEY_PC_14678
      "
    >
      <CommonRate
        :size="'small'"
        :rank-num="commentInfo.comment_rank_average"
      />
      <span
        class="product-intro__head-reviews-text"
        da-event-click="1-8-6-3"
        :data-review-num="allCommentInfoNum"
        @click="goReviwesAnchor"
      >({{ allCommentStr }} {{ language.SHEIN_KEY_PC_14678 }})</span>
    </span>
  </div>
</template>
<script setup>
import { computed, toRefs } from 'vue'
import { useStore } from 'vuex'
import CommonRate from '../components/CommonRate'
const props = defineProps({
  offset: {
    type: Number,
    default: 0
  }
})
const { offset } = toRefs(props)

const store = useStore()
const language = computed(() => store.state.language)
const allReportNum = computed(() => store.state.allReportNum)

const commentInfo = computed(() => {
  const hotModules = store.state.hotModules
  return hotModules.comment.commentInfo
})
const allCommentInfoNum = computed(() => allReportNum.value + Number(commentInfo.value.comment_num))
const allCommentStr = computed(() => {
  const { comment_num, comment_fuzzy, comment_num_str } = commentInfo.value
  return comment_fuzzy
    ? comment_num_str
    : `${comment_num >= 10000 ? '9999+' : comment_num}`
})

// 锚点到评论区域
const goReviwesAnchor = () => {
  const dom = document.querySelector('.common-reviews')
  if (dom){
    let y = dom.getBoundingClientRect().y
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    const headerHeight = document.querySelector('.j-c-header1').offsetHeight
    if (y < headerHeight) {
      y = y - headerHeight
    }
    window.scrollTo({
      behavior: 'smooth',
      top: y - offset.value + scrollTop
    })
  }
  
}
</script>
<style lang="less">
.product-intro {
  &__head-reviews {
    white-space: nowrap;
    flex: 1;
    .common-rate {
      margin-right: 5px;
      display: inline-block;
    }
  }
  &__head-reviews-text {
    cursor: pointer;
    color: @sui_color_honor;
    vertical-align: super;
    font-size: 12px;
    font-family: "Arial";
    user-select: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

</style>
