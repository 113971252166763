import { computed, toRefs } from 'vue'
import { template } from '@shein/common-function'
export default function useSizeTips(props, reactiveInfo) {
  const { 
    constant,
    mallCode,
    language,
    tipsCombo,
    isAllSoldOut,
    config,
    getSkcEstimatedInfo
  } = toRefs(props)
  const { skuInfo, skuList, skcSaleAttr, polyAttrSize } = reactiveInfo || {}
  const promotionTips = computed(() => {
    const result = {}
    const { SHEIN_KEY_PC_24113, SHEIN_KEY_PC_17383 } = language.value || {}
    const { newFlashPromotionData, flashSaleInfo, underPriceInfo } = tipsCombo.value || {}
    // 闪购购买数量上限提示
    if (flashSaleInfo) {
      const { flashType, singleNum, limitMode, buyLimit, soldNum, endTimestamp } = flashSaleInfo || {}
      const newFlashPromotionPriceWithSymbol = newFlashPromotionData?.price?.amountWithSymbol || ''
      const onlySpPromotion = [2, 3, 4].includes(flashType) && !newFlashPromotionPriceWithSymbol // 并未享受到闪购活动价
      if (+singleNum && !onlySpPromotion) {
        let currentTime = Date.now() / 1000
        let limit_text = limitMode ? template(singleNum, SHEIN_KEY_PC_24113) : template(singleNum, SHEIN_KEY_PC_17383)
        if ((+buyLimit > +soldNum) && (+currentTime < +endTimestamp) && limit_text) {
          result.flashSaleTip = limit_text
        }
      }
    }
    // 一口价购买数量上限提示
    if (underPriceInfo) {
      const { singleNum, limitMode } = underPriceInfo || {}
      if (+singleNum) {
        let limit_text = limitMode ? template(singleNum, SHEIN_KEY_PC_24113) : template(singleNum, SHEIN_KEY_PC_17383)
        if (limit_text) {
          result.underPriceTip = limit_text || ''
        }
      }
    }
    if (polyAttrSize.value?.length) result.promotionTipsLocation = 'top'
    else if (!skcSaleAttr.value?.length) result.promotionTipsLocation = 'bottom'
    return result
  })
  const almostSoldOut = computed(() => {
    let skuStock = 0
    for (let i = 0; i < skuList.value.length; i++) {
      skuStock += +skuList.value[i]?.mall?.[props.mallCode]?.stock || 0
      if (skuStock > (tipsCombo.value?.soldOutTipsLimitNum || 5)) {
        return false
      }
    }
    return skuStock !== 0
  })
  
  const soldOutTips = computed(() => {
    const { SHEIN_KEY_PC_16990, SHEIN_KEY_PC_16400 } = language.value || {}
    const { soldOutTipsLimitNum, showSizeStockTips } = tipsCombo.value || {}
    if (!showSizeStockTips) return ''
    if (skuInfo.value?.sku_code) {
      const mall = skuInfo.value.mall?.[mallCode.value]
      if (mall?.stock > 0 && mall?.stock <= soldOutTipsLimitNum || 0) {
        return template(mall?.stock, SHEIN_KEY_PC_16400)
      } 
    }else {
      if (!isAllSoldOut.value && almostSoldOut.value) {
        return SHEIN_KEY_PC_16990
      }
    }
    return ''
  })

  const sizeDeviationTips = computed(() => {
    const { sizeTipsText, showHeelHeight, heelHeight, defaultTrueToSizeText } = tipsCombo.value || {}
    const { cssRight } = constant.value || {}
    if (sizeTipsText) {
      return `${sizeTipsText || ''}${showHeelHeight ? '; ' + heelHeight : ''}`
    }
    if (showHeelHeight) {
      return `${defaultTrueToSizeText}${(cssRight || !defaultTrueToSizeText ? ' ' : '; ')}${heelHeight}`
    }
    return defaultTrueToSizeText
  })

  const tipsInfo = computed(() => {
    const { showHeelHeight, cmInchInfo, sizeTipsText, isLargeSizeTips, trueToSizeStrongText, trueToSizeText, sizeTipsBold } = tipsCombo.value || {}
    return { 
      promotionTips: promotionTips.value, 
      soldOutTips: getSkcEstimatedInfo.value?.isSatisfiedBuyMultiple ? '' : soldOutTips.value, 
      showSizeTips: (sizeDeviationTips.value && config.value.from === 'detail') || sizeTipsText || trueToSizeStrongText,
      sizeDeviationTips: sizeDeviationTips.value,
      heelHeightInfo: showHeelHeight ? cmInchInfo : '',
      sizeTipsBold,
      sizeTipsText,
      isLargeSizeTips,
      sizeTipsExpose: {
        id: '1-8-6-136',
        data: { type: isLargeSizeTips ? 1 : 2 }
      },
      trueToSizeText,
      trueToSizeStrongText,
      trueToSizeStrongExpose: {
        id: '1-8-6-136',
        data: { type: 3 }
      },
    }
  })

  return { tipsInfo, almostSoldOut }
}
