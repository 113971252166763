<template>
  <div
    v-expose="{
      id: '1-8-6-98',
      data: analysisData,
    }"
    class="shipping-club__container"
    :style="{ maxHeight: loaded ? '20px' : '0' }"
    @click.stop="jump"
  >
    <div
      class="shipping-club__logo"
      :style="{
        backgroundImage: renderBackGround,
      }"
    ></div>
    <p class="shipping-club__text">
      {{ sheinClubShppingText }}
    </p>
    <Icon
      name="sui_icon_more_right_12px_1"
      size="14px"
      class="shipping-club__icon"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { Icon } from '@shein-aidc/icon-vue3'
let PUBLIC_CDN, GB_cssRight

if (typeof window !== 'undefined') {
  PUBLIC_CDN = gbCommonInfo.PUBLIC_CDN
  GB_cssRight = gbCommonInfo.GB_cssRight
}

export default {
  name: 'ProductIntroShippingSheinClub',
  directives: { expose },
  components: {
    Icon
  },
  props: {
    source: {
      type: String,
      default: 'goodsDetail_shipping',
    },
  },
  data() {
    return {
      PUBLIC_CDN,
      GB_cssRight,
      loaded: false
    }
  },
  computed: {
    ...mapState(['sheinClubUserInfo', 'language']),
    ...mapGetters(['sheinClubShppingText']),
    renderBackGround() {
      if (this.loaded) {
        return `url(${PUBLIC_CDN}/she_dist/images/pay_vip/paid_vip_shipping${
          GB_cssRight ? '_ar-cf51ccb57d' : '-da56628863'
        }.png)`
      } else {
        return null
      }
    },
    analysisData() {
      const { prime_level = 0, total_saving = 0 } = this.sheinClubUserInfo
      return {
        prime_level,
        total_saving,
        location: 'shipping',
      }
    },
  },
  mounted() {
    this.loaded = true
  },
  methods: {
    jump() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-99',
        extraData: this.analysisData,
      })
      window.location.href = '/user/prime?productsource=' + this.source
    },
  },
}
</script>

<style lang="less">
.shipping-club {
  &__container {
    margin-top: 10px;
    display: inline-flex;
    align-items: center;
    color: #c96e3f;
    cursor: pointer;
    max-height: 0;
    transition: max-height 0.1s ease;
  }
  &__logo {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 18px;
    margin-right: 4px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  &__icon {
    transform: rotate(0deg) /* rtl:rotate(180deg) */;
  }
  &__text {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
}
</style>
