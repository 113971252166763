<template>
  <div class="common-slider">
    <div
      class="s-swiper-container common-swiperv2 common-swiperv2_btm"
    >
      <div
        v-show="activeIndex !== 0"
        class="common-swiperv2__prev"
        @click="handleArrowBtn('left')"
      >
        <Icon
          name="sui_icon_more_left_18px"
          size="16px"
          :is-rotate="GB_cssRight"
        />
      </div>

      <swiper-container 
        v-show="swiperInstance"
        ref="newSwiper"
        init="false" 
        class="s-swiper-wrapper"
      >
        <swiper-slide
          v-for="(item, index) in goodsArr"
          :key="`item__${index}`"
          v-expose="{
            id: '1-8-6-184',
            data: {
              goods_id: item.goods_id,
              cb: updateExposedList
            }
          }"
          :class="['s-swiper-slide', slideItemClass]"
          class="common-slider__item"
        >
          <s-product-item
            :key="item.goods_id"
            v-expose="daEventExposeConfig"
            :no-fsp-class="true"
            :da-eid="`${index}_${item.goods_id}`"
            class="product-list__item"
            :index="Number(index)"
            :item="item"
            :language="itemLanguage"
            :goods-item-info="goodsItemInfo"
            :config="producItemShowConfig"
            :constant-data="constantData"
            :bottom-info-style="{ minHeight: '0' }"
            :da-event-click="daEventClick"
            :data-goods_id="item.goods_id"
            :data-spu="item.productRelationID || item.goods_sn"
            :data-us-price="item.salePrice && item.salePrice.usdAmount"
            :data-us-origin-price="item.retailPrice && item.retailPrice.usdAmount"
            :data-cat_id="item.cat_id"
            :data-sku="item.goods_sn"
            :data-mall_tag_code="`${getMallTag(item.mall_code)}_${item.mall_code || ''}`"
            :data-position-index="index+1"
            @click-item="clickItem"
          />
        </swiper-slide>
      </swiper-container>

      <div
        v-show="!isSwiperEnd"
        class="common-swiperv2__next"
        @click="handleArrowBtn('right')"
      >
        <Icon
          name="sui_icon_more_right_18px"
          size="16px"
          :is-rotate="GB_cssRight"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { transformImg } from '@shein/common-function'
import SProductItem from 'public/src/pages/components/productItem/ProductItem.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import itemMixin from 'public/src/pages/components/productItem/mixin'
import i18n from 'public/src/pages/common/biz_helper/i18n'
const { LAZY_IMG, PUBLIC_CDN, lang, GB_cssRight } = gbCommonInfo
import { abtservice } from 'public/src/services/abt'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { extendsComplianceModeConfig } from 'public/src/pages/goods_detail_v2/utils/recommendConfigExtends.js'
daEventCenter.addSubscriber({ modulecode: '2-3' })
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()

export default {
  name: 'CommonSlider',
  components: { SProductItem, Icon },
  directives: { expose },
  mixins: [itemMixin],
  props: {
    daEventExposeConfig: {
      type: Object,
      default: () => {
        return {}
      }
    },
    daEventClick: {
      type: String,
      default: ''
    },
    uniqueName: {
      type: String,
      default: ''
    },
    goodsArr: {
      type: Array,
      default() {
        return []
      }
    },
    isNormal: {
      type: Boolean,
      default: true
    },
    isGetlook: {
      type: Boolean,
      default: false
    },
    isOtheroptions: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Number,
      default: 0
    },
    slidesPerGroup: {
      type: Number,
      default: 5
    },
    themeName: {
      type: String,
      default: ''
    },
    siteMallInfo: {
      type: Array,
      default() {
        return []
      }
    },
    updateExposedList: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      LAZY_IMG,
      PUBLIC_CDN,
      lang,
      GB_cssRight,
      goodsItemInfo: null,
      producItemShowConfig: {
        showLikeBtn: false,
        showPlusSize: true,
        showSeriesBrand: true,
        hideGoodsName: true,
        hideOperationBtn: true,
        disableMainimgJump: true,
        useOwnClickExposeAnalysis: true,
        showNewFlashPriceOnSale: true,
        showSheinClubDiscountValue: true,
        hideDiscountBadge: true, // 隐藏默认的折扣标签
        showDiscountLabelAfterPrice: true, // 折扣展示在价格右边
        hideRetailPrice: true, // 隐藏划线价
      },
      activeIndex: 0,
      isSwiperEnd: false,
      swiperInstance: null
    }
  },
  computed: {
    ...mapGetters(['language', 'isHitComplianceMode']),
    slideItemClass() {
      return {
        'common-swiperv2__slide-normal': this.isNormal,
        'common-swiperv2__slide-getlook': this.isGetlook,
        'common-swiperv2__other-options': this.isOtheroptions
      }
    }
  },
  async mounted() {
    this.goodsItemInfo = new GoodsItemInfo({
      listLanguage: await i18n.loadByQueue('item')
    })
    this.goodsItemInfo.getProductInfo({
      goods: this.goodsArr,
      pageKey: 'page_goods_detail_rec',
      subPageKey: 'carousel_mage',
      requestFields: {
        prices: true,
        seriesAndBrand: true,
        plusSize: true,
        promotion: true,
        sheinClubPromotionInfo: true,
        newFlashPromotion: true,
        flashZonePromotion: true,
        locateLabels: true,
      }
    })

    const abtInfo = await abtservice.getUserAbtResult({
      newPosKeys: 'discountLabel,greysellingPoint,listtagSorting,listrankingTag',
    })
    const showNewDiscountLabel = abtInfo?.['discountLabel']?.param?.['discountLabel'] == 'Label'
    const showGreySellingPoint = abtInfo?.['greysellingPoint']?.param?.['greysellingPoint'] == 'grey'
    const showHorseRaceLabel = !!abtInfo?.listtagSorting?.param?.tagsortingContent
    const addRankingLabelToSwiper = abtInfo.listrankingTag?.param?.rankingTag === 'on'
    this.producItemShowConfig = {
      ...this.producItemShowConfig,
      showNewDiscountLabel,
      showGreySellingPoint,
      showHorseRaceLabel,
      addRankingLabelToSwiper,
      configReady: true
    }

    this.isHitComplianceMode && extendsComplianceModeConfig(this.producItemShowConfig)

    Vue.nextTick(() => {
      const swiperEl = this.$refs.newSwiper
      if (!swiperEl) return
      const swiperParams = {
        observer: true,
        observeParents: true,
        // modules: [Autoplay],
        slidesPerView: 'auto',
        slidesPerGroup: this.slidesPerGroup,
        // spaceBetween: 10,
        on: {
          reachEnd: (swiper) => {
            this.isSwiperEnd = swiper.isEnd
          },
          slideChangeTransitionEnd: (swiper) => {
            this.activeIndex = swiper.realIndex
            this.isSwiperEnd = swiper.isEnd
            const index = swiper.activeIndex / this.slidesPerGroup

            this.goodsArr.forEach((item, index) => {
              item.slideIndex = index
            })

            this.$emit('slide', {
              swiper,
              index,
              goods: this.goodsArr,
              uniqueName: this.uniqueName
            })
          }
        }
      }
      if (this.autoplay) {
        swiperParams.autoplay = {
          delay: this.autoplay,
          disableOnInteraction: false,
        },
        swiperParams.speed = 1000
        swiperParams.modules = [Autoplay]
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.swiperInstance = swiperEl.swiper
    })
  },
  methods: {
    handleArrowBtn(direction) {
      if (direction === 'left') {
        this.swiperInstance.slidePrev()
      } else if (direction === 'right') {
        this.swiperInstance.slideNext()
      }
    },
    clickItem(arg) {
      this.$emit(
        'tapItem',
        Object.assign({}, arg, {
          slideIndex: arg.index
        }),
        arg.target
      )
    },
    /**
     * 单击滑动项
     */
    tapItem(item, index, $event) {
      this.$emit(
        'tapItem',
        Object.assign({}, item, {
          slideIndex: index
        }),
        $event.currentTarget
      )
    },
    getMallTag(mall_code) {
      return (
        this.siteMallInfo.find(_ => _.mall_code == mall_code)?.mall_tags || ''
      )
    },
    calClubDisCountValue(item) {
      return item?.sheinClubPromotionInfo?.discountValue || ''
    },
    transformImg
  },
  emits: ['slide', 'tapItem']
}
</script>

<style lang="less">
.common-slider {
  .s-swiper-container{
    position: relative;
  }
  .common-swiperv2 {
    //swiper container 宽度占最外层100%时滑块的宽度
    &__slide-normal {
      width: 18.4166666%;
    }

    //新商详get the look滑块所占宽度
    &__slide-getlook {
      width: 29.94945799%;
    }

    //新商详Curve+Plus & Family Look 滑块所占宽度
    &__other-options {
      width: 35.714285%;
    }
  }

  &__item {
    margin-right: 10px;
  }
}
</style>
