<script>
export default {
  name: 'SizeSelectItemRelated'
}
</script>
<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { defineProps, inject, computed } from 'vue'
import ProductCardPriceSimpleAutoComplianceMode from 'public/src/pages/goods_detail_v2/components/ProductCardPriceSimpleAutoComplianceMode.vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  constant: {
    type: Object,
    default: () => ({}),
  },
  relatedLocalSize: {
    type: Object,
    default: () => ({}),
  },
  sizePopoverPlacemen: {
    type: Object,
    default: () => ({}),
  },
  currentSelectedCountry: {
    type: String,
    default: '',
  }
})
const setSizeRefs = inject('setSizeRefs')
const config = inject('config')
const language = inject('language')
const commonFn = inject('commonFn')
const plusSizeRule = computed(() => props.relatedLocalSize?.plusSizeRule)
const relateGood = computed(() => props.relatedLocalSize?.relateGood)
const relateGoodType = computed(() => props.relatedLocalSize?.relateGoodType)
const relateGoodsName = computed(() => props.relatedLocalSize?.relateGoodsName)
const idx = computed(() => relateGoodType.value === 0 ? 0 : 999)
const relateSizeMap = computed(() => {
  return relateGood.value?.sizeText?.map?.(goods => {
    const plus = plusSizeRule.value[props.currentSelectedCountry]?.find?.(({ name }) => {
      return name == goods && goods != 'one-size'
    })
    if (plus) {
      return `${plus.correspond}(${goods})`
    }
    return goods
  }) || {}
})
// const priceCardConfig = computed(() => {
//   let config = {
//     camelCasePriceMaxSize: 17,
//     camelCasePriceMinSize: 11,
//     direction: 'column',
//   }
//   const { complianceTipsMode = false } = props.constant
//   if(complianceTipsMode) {
//     config.priceColor = '#000'
//     config.hidePriceBottomLabel = true
//   }
//   return config
// })
const showQuickviewModalTouch = (e, isEnter, data) => {
  if (config.isMobile) {
    commonFn.showQuickviewModal(e, isEnter, data, 'auto')
  }
}
</script>

<template>
  <s-popover     
    :ref="setSizeRefs('popoverRef', idx + 'pluseSize')"
    :key="idx"
    :class="[
      'relate-good',
      'product-intro__size-radio-spopover',
      `j-product-intro__size-radio-spopover_${item.attr_id}_index${idx}`,
    ]"
    :placemen="sizePopoverPlacemen[idx]"
    :delay-render="300"
    trigger="hover"
    :append-to-body="true"
    :empty-un-show="true"
  >
    <template #reference>
      <div
        v-enterkey
        class="product-intro__size-radio"
        tabindex="0"
        @touchstart="showQuickviewModalTouch($event, true, relateGood)"
        @click="commonFn.showQuickviewModal($event, true, relateGood)"
      >
        <div class="product-intro__size-radio-inner">
          <span
            v-for="(subItem, i) in relateGood.sizeText"
            :key="i"
          >{{ relateSizeMap[i] }}</span>
          <Icon
            :class="{ 'to-right': config.from == 'detail' }"
            name="sui_icon_paly_40px"
            size="14px"
            :custom-style="{ verticalAlign: '-3px'}"
          />
        </div>
      </div>
    </template>
    <div class="product-intro__size-popover-content">
      <div
        class="product-intro__size-popover-img"
        :style="{
          backgroundImage: `url(${relateGood.goods_thumb})`,
        }"
      ></div>
      <div class="product-intro__size-popover-wrap">
        <div class="product-intro__size-popover-stitle">
          {{ language.SHEIN_KEY_PC_17358 }}
        </div>
        <div class="product-intro__size-popover-gtitle">
          {{ relateGoodsName }}
        </div>
        <div class="product-intro__size-popover-gprice">
          <ProductCardPriceSimpleAutoComplianceMode
            :goods-info="relateGood"
            :is-paid="constant.isPaidUser"
          />
        </div>
      </div>
    </div>
  </s-popover>
</template>

<!-- <style lang="less">

</style> -->
