import schttp from 'public/src/services/schttp'
import { SMessage } from '@shein-aidc/sui-message'
import { template, htmlDecode } from '@shein/common-function'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { SILogger } from 'public/src/pages/common/monitor/index.js'
import {
  quickAddAnalysis,
  addToBagAnalysis,
  addToBagFailAnalysis,
} from './analysis'

/**
 * 加车主流程
 */

const handleAddToBag = async (
  { baseInfo = {}, validateParams = {}, customerAnalysis = {} },
  {
    btnEventCenter,
    language,
    callbacks,
    target,
    btnType = 'add_to_bag',
    checkNotSelectedTips,
  }
) => {
  const isValid = validate(
    { baseInfo, validateParams, customerAnalysis },
    {
      btnEventCenter,
      target,
      checkNotSelectedTips,
      errorText: language.SHEIN_KEY_PC_27739,
      reloginCallback: function () {
        handleAddToBag(
          { baseInfo, validateParams, customerAnalysis },
          {
            btnEventCenter,
            language,
            callbacks,
            target,
            btnType,
            checkNotSelectedTips,
          }
        )
      },
    }
  )
  if (!isValid) return
  const { goods_id, quantity, mallCode, sku_code } = baseInfo
  const requestParams = {
    goods_id,
    quantity,
    sku_code: sku_code,
    mall_code: mallCode,
  }

  sendAddRequest(
    {
      requestParams,
      baseInfo,
      validateParams,
      language,
      callbacks,
    },
    { btnEventCenter, customerAnalysis, btnType }
  )
}

/**
 * 发送加车请求
 */
const sendAddRequest = async (
  { requestParams, baseInfo, validateParams, language, callbacks },
  { btnEventCenter, customerAnalysis, btnType }
) => {
  const { isCustomization } = validateParams
  const { sku_code } = requestParams
  const extendData = {}
  // 加车中
  btnEventCenter({ type: 'updateLoading', data: true })
  if (isCustomization) {
    btnEventCenter({ type: 'updateLoading', data: false })
    const customizationInfo = await getCustomizationInfo({
      sku_code,
      errorText: language.SHEIN_KEY_PC_27739,
    })
    if (!customizationInfo) return
    extendData.customization_info = customizationInfo
  }
  // SEND REQUEST
  const FORM_DATA = { ...requestParams, ...extendData }

  schttp({
    url: '/api/cart/add_mall/create',
    method: 'POST',
    data: FORM_DATA,
  })
    .then(res => {
      sendAddRequestSuccess(
        { baseInfo, validateParams, formData: FORM_DATA, language, callbacks },
        { res, btnEventCenter, customerAnalysis, btnType }
      )
    })
    .catch(err => {
      addToBagFailAnalysis(
        { baseInfo, validateParams },
        { customerAnalysis, btnType }
      )
      if (err?.status == 403) {
        //更新csrf_token
        schttp.updateXCsrfToken()
        // 上报日志
        SILogger.logInfo({
          tag: 'GOODS_DETAIL_PWA_CLIENT_V3',
          message: 'add_to_cart_error',
          params: {
            scene: 'add_to_cart',
            msgData: err
          }
        })
      }
    })
}

const sendAddRequestSuccess = (
  { baseInfo, validateParams, formData, language, callbacks },
  { res = {}, btnEventCenter, customerAnalysis }
) => {
  const { isForEstimated } = validateParams

  if (isForEstimated) {
    btnEventCenter({ type: 'forEstimatedHandle', data: res })
  } else {
    btnEventCenter({ type: 'getCoupon', data: res?.code == 0 })
  }

  const { code, info } = res || {}

  if (code == 0 && info) {
    btnEventCenter({ type: 'addToBagSuccess', data: res })
  } else {
    errorCodeCallback(
      { baseInfo, validateParams, language, callbacks },
      { res, btnEventCenter, customerAnalysis }
    )
    btnEventCenter({ type: 'updateLoading', data: false })
  }

  addToBagAnalysis(
    { baseInfo, validateParams, formData },
    { res, customerAnalysis }
  )
}

const errorCodeCallback = (
  { baseInfo, language, callbacks },
  { res = {}, btnEventCenter }
) => {
  let errorCodeTips = ''
  let tip = htmlDecode({ text: language.SHEIN_KEY_PC_14560 })
  const { limit_count } = baseInfo
  const { code } = res || {}
  const ERROR_CODE = +code
  switch (ERROR_CODE) {
    case 300405: // 清仓限购
      errorCodeTips = template(limit_count, language.SHEIN_KEY_PC_14594)
      break
    case 200401: // 限时折扣限购
    case 500301:
      errorCodeTips = language.SHEIN_KEY_PC_14661
      break
    case 300402: // 缺货
      errorCodeTips = language.SHEIN_KEY_PC_15071
      break
    case 300407: // 购物车满了
      errorCodeTips = res.tips
      break
    case 500303: // 限购
      errorCodeTips = template(res.info?.limitCount || '', res.info?.resultLimit || 0, res.info?.remainNum || 0, res.tips)
      break
    case 500306: // 闪购限购
    case 500302:
    case 300417:
      errorCodeTips = template(res.info?.limitCount || '', res.info?.resultLimit || 0, res.info?.remainNum || 0, res.tips)
      btnEventCenter({ type: 'updateQuantity', data: res.info.limitCount })
      break
    case 500304: //打折促销 单品限制
      errorCodeTips = language.SHEIN_KEY_PC_17492
      callbacks?.discountLimitOneCallback?.()
      break
    case 500305: //打折促销 订单限制
      callbacks?.discountLimitCallback?.()
      break
    case 200301:
      SMessage({
        message: res.tips,
        type: 'error',
        offset: 180,
      })
  }
  btnEventCenter({ type: 'updateErrorCodeTips', data: errorCodeTips })
}

/**
 * 获取一件定制商品信息
 */
const getCustomizationInfo = async ({ sku_code, errorText = '' }) => {
  let customizationInfo = null
  try {
    const { default: customerMade } = await import(
      'public/src/pages/goods_detail_v2/components/CustomeowDrawer/index.js'
    )
    customizationInfo = await customerMade({ sku: sku_code })
  } catch (e) {
    if (e && e.event === 'error') {
      SMessage({
        message: errorText,
        type: 'error',
        offset: 180,
      })
    }
  }
  return customizationInfo
}

/**
 * 加车校验
 */
const validate = (
  { baseInfo, validateParams, customerAnalysis },
  { btnEventCenter, target, checkNotSelectedTips, errorText, reloginCallback }
) => {
  const {
    isAllSoldOut,
    isForEstimated,
    isAllowAutoClaim,
    isCurrentSoldOut,
    hasGoodsSize,
  } = validateParams
  // 在到手价需求中，需要自动领券，且未登录时，需要走登录逻辑。登录成功后，自动再走一次handleAddToBag逻辑。
  if (isForEstimated && isAllowAutoClaim && !isLogin()) {
    btnEventCenter({ type: 'reLoginAndClick', data: reloginCallback })
    return false
  }
  // 售罄拦截
  if (isCurrentSoldOut || isAllSoldOut) return false

  if (!hasGoodsSize) {
    SMessage({
      message: errorText,
      type: 'error',
      offset: 180,
    })

    return false
  }

  const isValidSelect = validSizeSelect(
    { baseInfo, validateParams, customerAnalysis },
    { btnEventCenter, target, checkNotSelectedTips }
  )

  if (!isValidSelect) return false

  return true
}

/**
 * 校验尺码选择
 */
const validSizeSelect = (
  { baseInfo, validateParams, customerAnalysis },
  { btnEventCenter, target, checkNotSelectedTips }
) => {
  const { sku_code } = baseInfo
  const { openQuick } = validateParams

  if (sku_code) {
    return true
    // todo test
  } else if (openQuick) {
    openQuickHandle(
      { baseInfo, validateParams, customerAnalysis },
      { btnEventCenter, target }
    )
  } else {
    // 有尺码未选择，显示 Plase Select Size 提示
    btnEventCenter({ type: 'checkNotSelected', data: checkNotSelectedTips + 1 })
  }
  return false
}

/**
 * 打开快速加车
 */
const openQuickHandle = (
  { baseInfo, validateParams, customerAnalysis },
  { btnEventCenter, target }
) => {
  const { goods_id, quantity } = baseInfo
  const { isForEstimated, forbidAtmosphereFlow } = validateParams
  const { reportData = {} } = customerAnalysis
  import('public/src/pages/common/quickView').then(
    ({ default: QuickViewPopover }) => {
      let quickViewParams = {
        goodsId: goods_id,
        mainGoodsId: goods_id,
        config: {
          addSource: 'DetailPage',
          showBestDealLabel: true,
          showFollowBeltByOrigin: true,
          showEstimatedPrice: true,
          showNoSatisfied: true,
          reportData: reportData,
          forbidAtmosphereFlow,
        },
        extendForm: {
          ...(quantity ? { quantity } : {})
        },
        callback: {
          handleOpenLogin: addToWish => {
            QuickViewPopover.hide()
            btnEventCenter({
              type: 'showLogin',
              data: {
                type: 'qv-add-wish',
                originRef: {
                  addToWish,
                },
              },
            })
          },
          closeQuickView: detail => {
            btnEventCenter({ type: 'switchColor', data: detail })
          },
          handleChangeLocalCountry: country => {
            btnEventCenter({ type: 'changeLocalCountry', data: country })
          },
          handleAddToCartDone(res) {
            window.cart_module && window.cart_module.recart(res)
            if (isForEstimated) {
              btnEventCenter({ type: 'forEstimatedHandle', data: res })
            } else {
              btnEventCenter({ type: 'getCoupon', data: res?.code == 0 })
            }
          },
          async handleHookCheckAddToGroup({ state, next }) {
            btnEventCenter({ type: 'checkAddToGroup', data: { state, next } })
          },
        },
      }
      QuickViewPopover.show(quickViewParams)
      quickAddAnalysis({ baseInfo, customerAnalysis }, { target })
    }
  )
}

export { handleAddToBag }
