<template>
  <div class="product-intro-zoom__item">
    <!-- 最高的遮罩层监听mask事件 -->

    <template v-if="parentInject && parentInject.isZoom">
      <div
        class="product-intro-zoom__item_topmask"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
        @mousemove="onMouseMove"
      ></div>
      <div
        v-if="image && parentInject.imgLoadMap.value[image]"
        class="product-intro-zoom__item_mask"
        :style="cMaskStyles"
      ></div>
    </template>

    <!-- 保底的覆盖蒙层，防止用户/爬虫右键保存图片 -->
    <div
      class="cover-frame"
      :index="itemIndex"
    ></div>
    <template v-if="image && showImage">
      <div
        v-if="enhanceImg"
        :style="{
          background: `url(${enhanceLoadImg})  center/contain no-repeat`
        }"
        :data-background-image="enhanceLoadImg"
      >
        <CropImageContainer
          :lazy="lazy"
          :lazy-img="lazyImg"
          container-background="transparent"
          :img-src="dprInsert(image, imgType)"
          :aria-label="goodsName"
          :fixed-ratio="fixedRatio"
          :is-support-crop-image="isSupportCropImage"
          ignore-img-ext
          :transform-img-props="{
            imgClassName: {
              ...imgClassName,
              'lcp-gallery__hook': lcpHook
            }
          }"
          @load="imgShowCheck"
        />
      </div>
      <CropImageContainer
        v-else
        :lazy="lazy"
        :lazy-img="lazyImg"
        :img-src="dprInsert(image, imgType)"
        :aria-label="goodsName"
        :fixed-ratio="fixedRatio"
        :is-support-crop-image="isSupportCropImage"
        ignore-img-ext
        :transform-img-props="{
          imgClassName: {
            ...imgClassName,
            'lcp-gallery__hook': lcpHook
          }
        }"
        @load="imgShowCheck"
      />
    </template>

    <slot
      :on-mouse-enter="onMouseEnter"
      :on-mouse-leave="onMouseLeave"
      :on-mouse-move="onMouseMove"
    ></slot>
  </div>
</template>

<script>
import { transformImg } from '@shein/common-function'
import dprInsertMixins from '../../utils/dprInsertMixins'
// import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import CropImageContainer from './CropImageContainerForDetailMain.vue'
// import TransformImg from 'public/src/pages/components/transformImg.vue'
import { inject } from 'vue'

export default {
  name: 'ProductIntroGalleryBigPicZoomItem',
  emits: ['img-load'],
  components: {
    CropImageContainer
    // TransformImg
  },
  mixins: [dprInsertMixins],
  props: {
    /**
     * 不显示
     */
    showImage: {
      type: Boolean,
      default: true
    },
    image: {
      type: String
    },
    /**
     * 图片懒加载
     */
    lazy: {
      type: Boolean
    },
    /**
     * 是否能被swiper点击放大
     */
    lcpHook: {
      type: Boolean
    },
    goodsName: {
      type: String,
      default: ''
    },
    /**
     * 懒加载图片
     */
    lazyImg: {
      type: String,
      required: true
    },
    /**
     * 是否采用图片增强模式
     */
    enhanceImg: {
      type: String,
      default: ''
    },
    /**
     * type 主图类型 1: 900x / 2: 405x552 / 3: 220x293
     */
    imgType: {
      type: String
    },
    itemIndex: {
      type: Number,
      default: 0
    },
    fixedRatio: {
      type: String,
      default: ''
    },
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    imgClassName: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      maskX: 0,
      maskY: 0,
      showMask: false,
      maskWidth: 255
    }
  },
  computed: {
    /**
     * 懒加载计算属性
     */
    cImageOption() {
      const { lazy, lazyImg, image } = this
      if (!image) return {}

      // const img_src = transformImg({ img: this.dprInsert(image, this.imgType) })
      const img_src = this.dprInsert(image, this.imgType)

      if (!lazy) {
        return {
          'img-src': img_src
        }
      }

      return {
        src: lazyImg,
        'img-src': img_src
      }
    },
    cMaskStyles() {
      const { maskX, maskY, maskWidth, showMask } = this
      const result = {
        transform: `translate3d(${maskX}px, ${maskY}px, 0px)`,
        width: `${maskWidth}px`,
        height: `${maskWidth}px`,
        opacity: showMask ? 1 : 0
      }
      return result
    },
    enhanceLoadImg(){
      if(!this.enhanceImg) return null
      return this.dprInsert(this.enhanceImg, 'reuse').replace(/^https?\:/, '').replace(/\b(\.jpg|\.png)\b/, '.webp')
    }
  },
  methods: {
    transformImg,
    imgShowCheck(e) {
      const { image } = this
      if (!image) return
      if (
        transformImg({ img: e.target.src }) ==
        transformImg({ img: this.dprInsert(image) })
      ) {
        this.$emit('img-load', true)
      }
    },
    onMouseEnter() {
      this.showMask = true
    },
    onMouseLeave() {
      this.showMask = false
      if (!this.parentInject) return
      this.parentInject.handleUpdate({
        image: ''
      })
    },
    onMouseMove(e) {
      if (!this.parentInject) return

      const { clientWidth, clientHeight } = e.target
      const { GB_cssRight } = gbCommonInfo

      // 图片宽度相对mask宽度的倍数（设计稿小图宽度/mask宽度）
      const maskRate = 1.93
      const _maskWidth = Math.ceil(clientWidth / maskRate)
      if (_maskWidth !== this.maskWidth) this.maskWidth = _maskWidth

      const { parentInject, maskWidth } = this
      const _halfMaskWidth = maskWidth / 2

      // 计算mask的位置
      const _maxTopX = clientWidth - maskWidth
      const _maxTopY = clientHeight - maskWidth

      let _topX = 0
      // ar站反转计算x轴
      if (GB_cssRight) {
        _topX = clientWidth - e.offsetX - _halfMaskWidth
        if (_topX < 0) _topX = 0
        if (_topX > _maxTopX) _topX = _maxTopX
        _topX = -_topX
      } else {
        _topX = e.offsetX - _halfMaskWidth
        if (_topX < 0) _topX = 0
        if (_topX > _maxTopX) _topX = _maxTopX
      }

      let _topY = e.offsetY - _halfMaskWidth
      if (_topY < 0) _topY = 0
      if (_topY > _maxTopY) _topY = _maxTopY

      this.maskX = _topX
      this.maskY = _topY
      const _smallPreviceRate =
        (parentInject.imgPreviewWidth.value * maskRate) / clientWidth

      // 计算预览图的偏移位置
      const _parentZoomX = -_topX * _smallPreviceRate
      const _parentZoomY = -_topY * _smallPreviceRate
      const imgZoomWidth = Math.ceil(clientWidth * _smallPreviceRate)
      parentInject.handleUpdate({
        image: this.image,
        x: _parentZoomX,
        y: _parentZoomY,
        imgZoomWidth
      })
    }
  },
  setup() {
    const parentInject = inject('galleryBigPicZoom')
    return {
      parentInject
    }
  }
}
</script>
<style lang="less">
.product-intro-zoom__item {
  &_topmask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
  }

  &_mask {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 166, 84, 0.4);
    z-index: 3;
    transition: opacity 0.2s;
  }
}
</style>
