<template>
  <div
    v-if="goodsSn && optionsDataLoaded.length > 0"
    class="j-expose__matching-styles-container"
  >
    <h2
      ref="matchingStylesHeadHook"
      v-enterkey
      class="product-intro__matching-styles-head"
      :aria-label="language.SHEIN_KEY_PC_1848"
      tabindex="0"
      @click="handleFold"
    >
      {{ language.SHEIN_KEY_PC_18489 }}
      <template v-if="!disabledFold">
        <Icon
          v-if="unfold.includes('matchingStyles')"
          name="sui_icon_min_16px"
          size="16px"
          class="head-icon"
        />
        <Icon
          v-else
          name="sui_icon_add_16px"
          size="16px"
          class="head-icon"
        />
      </template>
    </h2>
    <div
      v-show="disabledFold || unfold.includes('matchingStyles')"
      class="product-intro__matching-styles-swiper"
    >
      <ProductIntroMatchingStylesItem
        v-if="optionsDataLoaded.length > 0"
        :slides-per-group="1"
        :goods-arr="optionsDataLoaded"
        :is-normal="false"
        :is-getlook="true"
        :site-mall-info="siteMallInfo"
        :show-shein-club-discount-value="!IS_RW"
        @slide="slide"
        @tap-item="showQuickviewModal"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import ProductIntroMatchingStylesItem from './ProductIntroMatchingStylesItem'
import { getImageRatio } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'
import { Icon } from '@shein-aidc/icon-vue3'
let QuickViewPopover = {}
if (typeof window !== 'undefined') {
  import('../analysis/index')
  import('public/src/pages/common/quickView').then(res => {
    QuickViewPopover = res.default
  })
}

daEventCenter.addSubscriber({ modulecode: '1-8-1' })

export default {
  name: 'ProductIntroMatchingStyles',
  components: {
    ProductIntroMatchingStylesItem,
    Icon
  },
  props: {
    /**
   * 禁用折叠功能，默认全展开
   */
    disabledFold: {
      type: Boolean,
    },
  },
  data() {
    return {
      result: null, // 暂存 other options API 数据
      seriesResult: {}, // 系列数据
      seriesProductResult: {}, // 暂存每个系列商品数据
      optionsDataLoaded: [],
    }
  },
  computed: {
    ...mapState(['realtimePricePromotionStatus', 'productIntroData', 'unfold',  'language', 'isUpdateSkc', 'IS_RW']),
    ...mapGetters(['goodsSn', 'siteMallInfo', 'optionsData']),
  },
  watch: {
    realtimePricePromotionStatus(val) {
      val && this.isUpdateSkc && this.getData()
    },
  },
  mounted() {
    this.initData(this.optionsData)
  },
  methods: {
    ...mapMutations(['assignState']),
    ...mapActions(['showLoginModal', 'autoGetCouponAtAddBag']),
    // esc 关闭
    escHandle() {
      this.unfold.includes('matchingStyles') && this.$refs['matchingStylesHeadHook'].click()
    },
    /**
     * 收起展开
     */
    handleFold() {
      if (this.disabledFold) {
        return
      }
      let updatedArray = []
      if (this.unfold.includes('matchingStyles')) {
        updatedArray = this.unfold.filter(item => item !== 'matchingStyles')
      } else {
        updatedArray = [...this.unfold, 'matchingStyles']
      }
      this.assignState({
        unfold: updatedArray
      })
    },
    /**
     * 获取 other options 数据
     */
    async getData() {
      const otherOptionsResult = await this.getOtherOptions({ goodsSn: this.goodsSn })
      this.initData(otherOptionsResult)
    },
    initData(optionsData) {
      /* 
        服务端已处理过滤
        Matching Styles中过滤掉已在关联商品(Size Group位置)展示的数据
        与more size 大小码（theme_type=3）也是互斥，已在上层数据中过滤
      */
      this.optionsDataLoaded = []
      let index = 0
      if(optionsData?.length > index) {
        const mergeThemeData = this.mergeThemeData(optionsData)
        // optionsDataLoaded为一个三维数组，第一层表示滑动的页数，一页六个主题，第二层表示主题，第三层表示主题下不同的搭配
        while(index < mergeThemeData.length) {
          this.optionsDataLoaded.push(mergeThemeData.slice(index, index + 6))
          index += 6
        }
      }
    },
    // 主题相同不同的搭配合并在一起
    mergeThemeData(data) {
      return data.reduce((pre, cur) => {
        let index = pre.length ? pre.findIndex(item => item.some(i => i.theme_id === cur.theme_id)) : -1
        const isNotAllSoldOut = cur.goods.some(goodItem => {
          return goodItem?.is_on_sale || goodItem?.stock
        })
        // 商品都售罄则过滤掉入口
        if (!isNotAllSoldOut) {
          return pre
        }
        if (index !== -1) {
          pre[index].length <= 3 && pre[index].push(cur)
        } else {
          pre.push([cur])
        }
        return pre
      }, [])
    },
    getOtherOptions({ goodsSn = '' } = {}) { 
      if (!goodsSn) {
        return null
      }
      const themeTypeStr = this.productIntroData?.cccAttrGroups?.themeTypeRes?.data?.themeType || ''
      return new Promise(resolve => {
        schttp({
          url: '/api/productInfo/otherOptions/get',
          params: {
            goodsSn,
            themeTypeStr
          },
        }).then((res) => {
          resolve(res)
        })
      })
    },
    /**
     * 显示登陆弹窗
     */
    showLogin(addToWish) {
      QuickViewPopover.hide()

      this.showLoginModal({
        type: 'qv-add-wish',
        originRef: {
          addToWish,
        },
      })
    },

    /**
     * 显示快速加车弹窗
     * @param {Object} data 商品数据
     */
    async showQuickviewModal(data, target) {
      let self = this
      const imgRatio = await getImageRatio(data?.goods_img)
      // const { sa: abtest } = abtservice.getUserAbtResultForAnalysis({ posKeys: 'DetailFamilyOutfits' })
      const mainGoodsId = this.productIntroData?.detail?.goods_id
      QuickViewPopover.show({
        goodsId: data.goods_id,
        mainGoodsId,
        imgRatio,
        mallCode: data.mall_code,
        leftProductsData: this.optionsDataLoaded[data.slideIndex][data.index],
        config: {
          addSource: 'DetailPage',
          showBestDealLabel: true,
          showFollowBeltByOrigin: true,
          isMatchingStyles: true,
          hideThumb: true
        },
        callback: {
          handleOpenLogin: this.showLogin,
          // 埋点上报
          handleOpenQuickViewDone: this.clickQuickEvent(this.optionsDataLoaded[data.slideIndex][data.index], target),
          handleChangeLocalCountry: (country) => {
            this.assignState({ country })
          },
          handleAddToCartDone(res){
            window.cart_module && window.cart_module.recart(res)
            self.autoGetCouponAtAddBag({ needToast: res?.code == 0, isMainGoods: mainGoodsId == data.goods_id })
          },
        },
        analysis: {
          daIdConfig: {
            viewFullDetail: '2-8-6',
          },
          pageType: '详情页',
          sourceTarget: target,
          index: 0,
          sa: {
            code: 'otherOptions',
            activity_from: 'other_options',
            poskey: 'DetailFamilyOutfits',
            scene_list: this.getSceneList(this.optionsDataLoaded[data.slideIndex][data.index]),
            // abtest
          },
          ga: {
            eventCategory: '推荐列表',
            moduleName: 'recommend',
            moduleTitle: 'OtherOptions',
          },
        },
      })
    },
    clickQuickEvent(data) {
      daEventCenter.triggerNotice({
        daId: '1-8-1-5',
        extraData: {
          scene_list: `${this.getSceneList(data)}`,
        },
      })
    },
    getSceneList(data) {
      const skcCount =  data.reduce((prev, cur) => {
        return prev + (cur?.goods.length || 0)
      }, 0)
      let result = []
      result.push(data[0].theme_id)
      result.push(data[0].theme_name)
      result.push(skcCount)
      result.push(data[0].series_img ? 1 : 0)
      return result.join('`')
    },
  }
}
</script>

<style lang="less">
.product-intro {
  &__matching-styles-head {
    position: relative;
    font-size: 16px;
    height: 47px;
    line-height: 47px;
    font-weight: 700;
    text-transform: capitalize;
    svg {
      position: absolute;
      .right(0);
      font-size: 16px;
    }
  }
  // &__matching-styles {
  //   &-swiper {
  //     display: flex;
  //   }
  // }
  // &__matching-styles-item {
  //   & + .product-intro__matching-styles-item {
  //     margin-top: 15px;
  //   }
  // }
  // &__matching-styles-item {
  //   width: 100%;
  // }
}
</style>
