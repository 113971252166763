<template>
  <div class="freeshipping-belt__wrapper">
    <Icon
      v-if="!beltQuickshipStrongStyle"
      class="icon"
      name="sui_icon_selected_16px"
      size="16px"
    />
    <Icon
      v-else-if="beltQuickshipStrongConfig.onlyFreeShiping"
      class="icon"
      name="sui_icon_freeshipping_flat_24px" 
      size="16px"
    />
    <Icon
      v-else
      class="icon"
      name="sui_icon_qucikship_flat_16px" 
    />
    <div 
      ref="freeshippingBeltWrapper"
      class="freeshipping-belt__scroll-wrapper" 
    >
      <div
        ref="freeshippingBeltScroll"
        v-expose="{
          id: '1-6-1-167',
          data: {
            freeshipping_location: 1,
            keyinformation: beltQuickshipStrongConfig.keyinformation || ''
          }
        }"
        class="freeshipping-belt__scroll-item"
        :class="{'freeshipping-belt__scroll-play': play}"
        :style="{'animation-duration': millisecond }"
      >
        <div class="freeshipping-belt__container">
          <span class="freeshipping-belt__text">{{ freeshippingBeltText }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
import { debounce } from '@shein/common-function'
export default {
  name: 'FreeShippingBelt',
  components: {
    Icon
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    beltQuickshipStrongConfig: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      play: false,
      debounceFunc: debounce({ func: this.handleScroll, wait: 200 }),
      millisecond: '1000ms'
    }
  },
  computed: {
    beltQuickshipStrongStyle() {
      return this.beltQuickshipStrongConfig.strongStyle || false
    },
    freeshippingBeltText () {
      return this.beltQuickshipStrongStyle ? this.beltQuickshipStrongConfig.strongText : this.language.SHEIN_KEY_PC_26553
    }
  },
  watch: {
    freeshippingBeltText() {
      this.handleScroll()
    }
  },
  mounted() {
    this.handleScroll()
    window.addEventListener('resize', this.debounceFunc)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.debounceFunc)
  },
  methods: {
    handleScroll() {
      const scrollWidth = this.$refs.freeshippingBeltScroll?.clientWidth - this.$refs.freeshippingBeltWrapper?.clientWidth 
      if(scrollWidth > 10) {
        this.play = true
        this.millisecond = `${scrollWidth * 30}ms`
      }else {
        this.play = false
      }
    }
  }
}
</script>

<style lang="less">
.freeshipping-belt {
  &__wrapper{
    width: 100%;
    padding: 6px 8px;
    margin-top: 10px;
    z-index: @zindex-hack;
    font-size: 12px;
    color: var(---sui_color_safety, #198055);
    border-radius: 2px;
    border: 0.5px solid rgba(25, 128, 85, 0.20);
    background: #ECFCF3;
    display: flex;
    justify-content: flex-start;
  }
  &__scroll-wrapper {
    max-width: 100%;
    line-height: normal;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    margin-left: 4px;
  }
  &__scroll-item{
    float: left;
    text-align: center;
    white-space: nowrap;
  }
  &__scroll-play {
    animation: beltScroll linear 4s alternate infinite;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content:flex-start;
    white-space: nowrap;
    .icon {
      margin-right: 4px;
    }

  }
}
@keyframes beltScroll {
    0% {
        margin-left: 0;
        transform: translateX(0);
    }

    10% {
        margin-left: 0;
        transform: translateX(0);
    }

    90% {
        margin-left: 100%;
        transform: translateX(-100%);
    }

    100% {
        margin-left: 100%;
        transform: translateX(-100%);
    }
}
</style>
