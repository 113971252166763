<template>
  <transition name="main-heat">
    <div
      v-if="show"
      class="product-intro__main-heat"
      :class="{ out: out }"
    >
      <div>{{ text }}</div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'
let once = false
export default {
  name: 'GalleryHeat',
  props: {
    imgLoad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      heatShow: false,
      out: false,
    }
  },
  computed: {
    ...mapState(['productIntroData', 'language', 'detailHotNews']),
    show() {
      return this.imgLoad && this.heatShow && this.text && this.detailHotNews
    },
    text() {
      const data =
        this.productIntroData?.hotColorList?.[
          this.productIntroData?.detail?.goods_id
        ] || {}
      if (data.c7d_top_checkout_uv) {
        return template(
          data.c7d_top_checkout_uv,
          this.language.SHEIN_KEY_PC_18091
        )
      } else if (data.c7d_top_wishlist_uv) {
        return template(
          data.c7d_top_wishlist_uv,
          this.language.SHEIN_KEY_PC_18089
        )
      } else if (data.current_top_browsing_uv) {
        return template(
          data.current_top_browsing_uv,
          this.language.SHEIN_KEY_PC_18090
        )
      }
      return ''
    },
  },
  watch: {
    imgLoad(val) {
      if (val && !once) {
        once = true
        this.heatShow = true
        setTimeout(() => {
          this.out = true
          this.$nextTick(() => {
            this.heatShow = false
          })
        }, 3400)
      }
    },
    show(val) {
      if (val) {
        daEventCenter.triggerNotice({
          daId: '1-8-6-73',
        })
      }
    },
  },
  methods: {
    template,
  },
}
</script>

<style lang="less">
.product-intro {
  &__main-heat {
    position: absolute;
    width: 200px;
    height: 48px;
    top: 42px;
    .left(0);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.96);
    z-index: @zindex-hover-tip;
    transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
    overflow: hidden;
    div {
      width: 100%;
      padding: 0 10px;
      font-size: 12px;
      line-height: 14px;
      .clamp(2);
      word-break: break-word;
    }
    &.out {
      transition: all 0.25s cubic-bezier(0, 0, 0.2, 1);
    }
  }
  .main-heat-enter-from,
  .main-heat-leave-to {
    /* stylelint-disable-line */
    transform: translate3d(-100%, 0, 0);
  }
}
</style>
