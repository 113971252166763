<template>
  <div
    class="la-ball-pulse la-ball-pulse-black"
  >
    <div :style="renderStyle()"></div>
    <div :style="renderStyle()"></div>
    <div :style="renderStyle()"></div>
  </div>
</template>
<script name="ProductLoading" setup>
const props = defineProps({
  size: {
    type: [String, Number],
    default: '8px',
  }
})

const renderStyle = () => {
  let size = props.size
  if (typeof size === 'number') {
    size += 'px'
  }
  return {
    width: size,
    height: size
  }
}

</script>
