<template>
  <div
    v-if="relatedGoods.length > 1"
  >
    <ClientOnly>
      <template v-if="showOutfitCate">
        <UnderImage 
          v-if="type === 'underImage'"
          :language="language"
          :related-goods="relatedGoods"
          :is-discount="isDiscount"
          :amount-with-symbol="amountWithSymbol"
          :discount="discount"
          :under-analysis-data="underAnalysisData"
          :open-get-the-look-new-popup="openGetTheLookNewPopup"
        />
        <TipEntry
          v-else
          :language="language"
          :tip-gtl-and-outfit-info="tipGtlAndOutfitInfo"
          :style="showBlackFridayBelt? { bottom: '55px' } : {}"
          :more-num="moreNum"
          :open-get-the-look-new-popup="openGetTheLookNewPopup"
        />
      </template>
    </ClientOnly>
  </div>
</template>

<script>
export default {
  name: 'GalleryRecomendEntry'
}
</script>
  
<script setup>
import { computed } from 'vue'
import { useMapState, useMapGetters, useMapActions } from 'public/src/pages/goods_detail_v2/store/mapHook.js'

import { ClientOnly } from '@sheinfe/vue-client-only'
import UnderImage from './UnderImage.vue'
import TipEntry from './TipEntry.vue'
defineProps({
  type: {
    type: String,
    default: '',
  },
})
const { language } = useMapState(['language'])
const { newGtlAndOutfitInfo } = useMapState('Recommend', ['newGtlAndOutfitInfo'])
const { complianceMode, showBlackFridayBelt } = useMapGetters(['complianceMode', 'showBlackFridayBelt'])
const { showOutfitCate } = useMapGetters('Recommend', ['showOutfitCate'])

const tipGtlAndOutfitInfo = computed(() => newGtlAndOutfitInfo.value?.slice(0, 2) || [])
const moreNum = computed(() => {
  return newGtlAndOutfitInfo.value?.length > 2 ? newGtlAndOutfitInfo.value?.length - 2 : 0
})

const firstGtlAndOutfitInfo = computed(() => newGtlAndOutfitInfo.value?.[0] || {})
const relatedGoods = computed(() => firstGtlAndOutfitInfo.value?.related_goods || [])
const bundlePrice = computed(() => firstGtlAndOutfitInfo.value?.bundlePrice || {})
const amountWithSymbol = computed(() => bundlePrice.value?.amountWithSymbol || '')
const discount = computed(() => bundlePrice.value?.discount || '')
const isDiscount = computed(() => !!discount.value && discount.value !== '0' && !complianceMode.value)
const underAnalysisData = computed(() => {
  const { theme_id, _lookType: look_type } = firstGtlAndOutfitInfo.value || {}
  return {
    goods_num: relatedGoods.value?.length,
    theme_id: theme_id || '-',
    look_type,
  }
})

const { openGetTheLookNewPopup } = useMapActions('Recommend', ['openGetTheLookNewPopup'])
</script>
  
