function label_language(language) {
  return {
    // 这些是最新的
    'Belts': language.SHEIN_KEY_PC_23835, 
    'Dresses': language.SHEIN_KEY_PC_23829, 
    'GlovesAndMitten': language.SHEIN_KEY_PC_23839, 
    'Hats': language.SHEIN_KEY_PC_23833, 
    'Jumpsuits': language.SHEIN_KEY_PC_23831, 
    'Shoes': language.SHEIN_KEY_PC_23832, 
    'Skirts': language.SHEIN_KEY_PC_23828, 
    'Sunglasses': language.SHEIN_KEY_PC_23834, 
    'Trousers': language.SHEIN_KEY_PC_23827, 
    'Underwear': language.SHEIN_KEY_PC_23844, 
    'Watches': language.SHEIN_KEY_PC_23837, 
    'Bags': language.SHEIN_KEY_PC_23847, 
    'Scarfs': language.SHEIN_KEY_PC_23838, 
    'Shirts': language.SHEIN_KEY_PC_23820, 
    'SocksAndTights': language.SHEIN_KEY_PC_23840, 
    'ShortCoats': language.SHEIN_KEY_PC_23823, 
    'LongCoats': language.SHEIN_KEY_PC_23824, 
    'Tanktops': language.SHEIN_KEY_PC_23819, 
    'Blazers': language.SHEIN_KEY_PC_23822, 
    'Bra': language.SHEIN_KEY_PC_23842, 
    'Bodysuits': language.SHEIN_KEY_PC_23830, 
    'Jewelry': language.SHEIN_KEY_PC_23846, 
    'HairAccessories': language.SHEIN_KEY_PC_23836, 
    'FaceMasks': language.SHEIN_KEY_PC_23843, 
    'PhoneCases': language.SHEIN_KEY_PC_23845, 
    'Sweaters': language.SHEIN_KEY_PC_23825, 
    'Sweatshirt': language.SHEIN_KEY_PC_23826, 
    'Blouses': language.SHEIN_KEY_PC_23821, 

    // 这里是 之前梳理的key 去找到对应的 pwa的
    'SwimwearOnePiece': language.SHEIN_KEY_PC_23778,
    'SwimwearShorts': language.SHEIN_KEY_PC_23777,
    'Vestes': language.SHEIN_KEY_PC_23773,
    'Kimonos': language.SHEIN_KEY_PC_23852,
    'SportsGear': language.SHEIN_KEY_PC_23786,
    'Suitcases': language.SHEIN_KEY_PC_23785,
    'Bracelets': language.SHEIN_KEY_PC_23815,
    'Cufflinks': language.SHEIN_KEY_PC_23812,
    'Makeup': language.SHEIN_KEY_PC_23800,
    'Necklaces': language.SHEIN_KEY_PC_23799,
    'Rings': language.SHEIN_KEY_PC_23795,
    'Sandals': language.SHEIN_KEY_PC_23794,
    'Ties': language.SHEIN_KEY_PC_23776,
    'SportShoes': language.SHEIN_KEY_PC_23787,
    'Jackets': language.SHEIN_KEY_PC_23804,
    'Earrings': language.SHEIN_KEY_PC_23810,
    'Boots': language.SHEIN_KEY_PC_23813,
    'Backpacks': language.SHEIN_KEY_PC_23746,
    'Coats': language.SHEIN_KEY_PC_23816,
    'Glasses': language.SHEIN_KEY_PC_23809,
    'NightMorning': language.SHEIN_KEY_PC_23797,
    'PouchBag': language.SHEIN_KEY_PC_23796,
    'WalletsPurses': language.SHEIN_KEY_PC_23772,
    'SwimwearBikini': language.SHEIN_KEY_PC_23783,
    'SwimwearBikiniTop': language.SHEIN_KEY_PC_23780,
    'SwimwearBikiniBottom': language.SHEIN_KEY_PC_23782,
    'Shorts': language.SHEIN_KEY_PC_23851,

    // ⬇️ 这些是目前 PC 还没找到的 KEY 但是 pwa 有的
    // 'Customize': language.SHEIN_KEY_PWA_19519,
    // 'Ballerina': language.SHEIN_KEY_PWA_20827,
    // 'Cardigans': language.SHEIN_KEY_PWA_20828,
    // 'CoverUps': language.SHEIN_KEY_PWA_20829,
    // 'DorsayShoes': language.SHEIN_KEY_PWA_20830,
    // 'DressShoes': language.SHEIN_KEY_PWA_20831,
    // 'Eyeglasses': language.SHEIN_KEY_PWA_20832,
    // 'FashionAccessories': language.SHEIN_KEY_PWA_20833,
    // 'Gladiator': language.SHEIN_KEY_PWA_20834,
    // 'HairBands': language.SHEIN_KEY_PWA_20835,
    // 'Hairpins': language.SHEIN_KEY_PWA_20836,
    // 'MensUnderwear': language.SHEIN_KEY_PWA_20838,
    // 'Nightwear': language.SHEIN_KEY_PWA_20840,
    // 'Panties': language.SHEIN_KEY_PWA_20841,
    // 'Perfumes': language.SHEIN_KEY_PWA_20842,
    // 'PhoneCovers': language.SHEIN_KEY_PWA_20843,
    // 'Pumps': language.SHEIN_KEY_PWA_20844,
    // 'Rompers': language.SHEIN_KEY_PWA_20845,
    // 'Scarves': language.SHEIN_KEY_PWA_20846,
    // 'Scrunchies': language.SHEIN_KEY_PWA_20847,
    // 'SlipOn': language.SHEIN_KEY_PWA_20848,
    // 'Slippers': language.SHEIN_KEY_PWA_20849,
    // 'SportsBras': language.SHEIN_KEY_PWA_20850,
    // 'Suspenders': language.SHEIN_KEY_PWA_20851,
    // 'SwimwearRashGuard': language.SHEIN_KEY_PWA_20852,
    // 'Umbrellas': language.SHEIN_KEY_PWA_20853,
    // 'Vests': language.SHEIN_KEY_PWA_20854,
    // 'Wallets': language.SHEIN_KEY_PWA_20855,
    // 'Wristbands': language.SHEIN_KEY_PWA_20856,

  }
}

export default label_language

