<template>
  <div
    class="product-intro__cottonSource"
    @keyup.esc="escHandle"
  >
    <h2
      ref="CottonSourceHeadHook"
      v-enterkey
      class="product-intro__cottonSource-head"
      tabindex="0"
      aria-label
      @click="handleFold"
    >
      <span>{{ language.SHEIN_KEY_PC_22237 }}</span>
  
      <Icon
        v-if="unfold.includes('CottonSource')"
        name="sui_icon_min_16px"
        size="16px"
        class="head-icon"
      />
      <Icon
        v-else
        name="sui_icon_add_16px"
        size="16px"
        class="head-icon"
      />
    </h2>
    <ClientOnly>
      <div v-show="unfold.includes('CottonSource')">
        <div class="product-intro__cottonSource-info">
          <div class="info-item">
            <label class="label">{{ language.SHEIN_KEY_PC_22238 }}:</label>
            <span class="value">
              {{ cottonInfo.place_of_origin }}
              <span
                v-if="certificationImage"
                class="image"
                @click="
                  updateModel({
                    show: true,
                    type: 'image',
                    title: language.SHEIN_KEY_PC_22238,
                  })
                "
              >
                <Icon
                  name="sui_icon_photos_16px"
                  size="16px"
                />
                {{ language.SHEIN_KEY_PC_22239 }}
              </span>
            </span>
          </div>
          <div class="info-item">
            <label class="label">{{ language.SHEIN_KEY_PC_22240 }}:</label>
            <span class="value">{{ factoryPlace }}</span>
          </div>
          <div class="info-item">
            <label class="label">{{ language.SHEIN_KEY_PC_22242 }}:</label>
            <span class="value">{{ supplierPlace }}</span>
          </div>
          <div
            v-if="!showAll"
            class="info-more"
            @click="showAllHandle"
          >
            {{ language.SHEIN_KEY_PC_19641 }}
          </div>
        </div>
      </div>
    </ClientOnly>
    <ClientOnly>
      <s-dialog
        v-model:visible="model.show"
        :type="'W720'"
        :height="model.type === 'image' ? '837px' : ''"
        :show-close="true"
        :append-to-body="true"
        @opened="handleOpened"
      >
        <template #title>
          {{ model.title }}
        </template>
        <div class="product-intro__cottonSource-popup">
          <template v-if="model.type === 'image'">
            <ProductIntroCottonSourceImage
              ref="imageRef"
              :certification-image="certificationImage"
            />
          </template>
          <template v-if="model.type === 'info'">
            <ProductIntroCottonSourceInfo
              ref="infoRef"
              :factory-info="factoryInfo"
              :supplier-info="supplierInfo"
              :factory-place="factoryPlace"
              :supplier-place="supplierPlace"
              :cotton-info="cottonInfo"
              :language="language"
              :srs-content="srsContent"
              :certification-image="certificationImage"
            />
          </template>
        </div>
      </s-dialog>
    </ClientOnly>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ProductIntroCottonSourceInfo from './ProductIntroCottonSourceInfo.vue'
import ProductIntroCottonSourceImage from './ProductIntroCottonSourceImage.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
import schttp from 'public/src/services/schttp'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { userModule } from 'public/src/pages/common/user_module.js'
import { Icon } from '@shein-aidc/icon-vue3'


export default {
  name: 'ProductIntroCottonSource',
  components: { ProductIntroCottonSourceInfo, ProductIntroCottonSourceImage, ClientOnly, Icon },
  data() {
    return {
      showAll: false,
      isShowLoginModal: false,
      model: {
        show: false,
        title: '',
        type: '', // image / info
      },
      srsContent: {},
    }
  },
  computed: {
    ...mapState(['productIntroData', 'unfold', 'language']),
    sourceData() {
      return this.productIntroData?.skcSource
    },
    factoryInfo() {
      // 工厂
      return this.sourceData?.related_factory_info || {}
    },
    supplierInfo() {
      // 面料
      return this.sourceData?.related_supplier_info || {}
    },
    factoryPlace() {
      const { factoryInfo } = this
      return template(
        `${factoryInfo.province}, ${factoryInfo.country}`,
        this.language.SHEIN_KEY_PC_22241
      )
    },
    supplierPlace() {
      const { supplierInfo } = this
      return template(
        `${supplierInfo.province}, ${supplierInfo.country}`,
        this.language.SHEIN_KEY_PC_22241
      )
    },
    cottonInfo() {
      // 原产地
      return this.supplierInfo?.cotton_info?.[0] || {}
    },
    certificationImage() {
      return this.supplierInfo?.proof_of_origin || ''
    },
  },
  mounted() {
    this.fetchCottonSourceData()
  },
  methods: {
    ...mapMutations(['assignState', 'showLoginModal']),
    async fetchCottonSourceData() {
      const { sourceData } = this
      Promise.all([this.fetchProductArticle()]).then(
        (res) => {
          const [SRSContent] = res
          if (sourceData) {
            this.srsContent = SRSContent || {}
            daEventCenter.triggerNotice({
              daId: '1-8-6-85',
            })
          }
        }
      )
    },
    /**
     * @param 测试环境 10116 / 灰度生产 1363
     */
    fetchProductArticle() {
      return new Promise((resolve, reject) => {
        schttp({
          url: '/product/article/1363?isJson=true',
        }).then((res) => {
          if (+res.code === 1000) {
            resolve(res.data)
          } else {
            reject()
          }
        }).catch(() => {
          reject()
        })
      })
    },
    fetchSkcSource() {
      const { goods_id } = this.productIntroData.detail
      return new Promise((resolve, reject) => {
        schttp({
          url: `/api/productInfo/skcSource/get?goods_id=${goods_id}`,
        }).then((res) => {
          if (+res.code === 0) {
            resolve(res.info.data?.[0])
          } else {
            reject()
          }
        })
      })
    },
    handleFold() {
      let updatedArray = []
      if (this.unfold.includes('CottonSource')) {
        updatedArray = this.unfold.filter(item => item !== 'CottonSource')
      } else {
        updatedArray = [...this.unfold, 'CottonSource']
      }
      this.assignState({
        unfold: updatedArray
      })
      this.unfold.includes('CottonSource') && daEventCenter.triggerNotice({ daId: '1-8-6-86', })
    },
    escHandle() {
      this.unfold.includes('CottonSource') && this.$refs['CottonSourceHeadHook'].click()
    },
    showAllHandle() {
      if (!isLogin()) {
        this.isShowLoginModal = true
        if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
          this.showLoginModal({
            type: 'ProductIntroCottonSource',
            callback: this.loginModalSucCb,
          })
          return
        }
        SHEIN_LOGIN.show({ cb: this.loginModalSucCb })
        return
      }
      this.updateModel({
        show: true,
        title: this.language.SHEIN_KEY_PC_22237,
        type: 'info',
      })
    },
    loginModalSucCb() {
      this.isShowLoginModal = false
      this.showAllHandle()
      // 导航栏登陆态更新
      userModule?.update_top_login()
    },
    updateModel({ show, title, type }) {
      this.model = { show, title, type }
    },
    handleOpened() {
      // image / info / srs
      const { type } = this.model
      this.$refs[`${type}Ref`]?.handleOpened?.()
    },
  },
}
</script>

<style lang="less">
.product-intro__cottonSource-info {
  .info-item {
    margin-bottom: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    .image {
      margin-left: 11px;
      cursor: pointer;
      color: @sui_color_link;
    }
  }
  .info-more {
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
    color: @sui_color_link;
  }
}
</style>
