<template>
  <div
    class="evolu-shein__entry"
    :style="boxStyle"
    @mouseover="onEvoluBarMouseOver"
    @mouseleave="onEvoluBarMouseLeave"
  >
    <s-popover
      v-model="popoverShow"
      placemen="top-start"
      class="evolu-shein__inner-box"
    >
      <div
        class="evolu-shein__exp-box"
        @mouseover="onEvoluBarMouseOver"
        @mouseleave="onEvoluBarMouseLeave"
      >
        <p class="title">
          {{ language.SHEIN_KEY_PC_24400 }}
        </p>
        <p class="detail-text">
          {{ language.SHEIN_KEY_PC_24401 }}
        </p>
        <p
          class="jump-text"
          @click="jumpToEvoluSHEIN"
        >
          {{ language.SHEIN_KEY_PC_24345 }} >
        </p>
      </div>

      <template
        #reference
      >
        <p>
          <img
            src="https://img.ltwebstatic.com/images3_ccc/2024/06/05/e0/evolu_pc.png"
            class="evolu-shein__icon"
          />
          <span>{{ language.SHEIN_KEY_PC_24400 }}</span>
        </p>
      </template>
    </s-popover>
    <div class="split-line"></div>
    <p class="detail-text">
      {{ language.SHEIN_KEY_PC_24401 }}
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { SPopover } from '@shein-aidc/sui-popover'

const { langPath, IS_RW } = gbCommonInfo

export default {
  name: 'EvoluSheinBar',
  components: {
    SPopover
  },
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popoverShow: false,
      popEventTimer: -1,
    }
  },
  computed: {
    ...mapState(['language']),
  },
  beforeUnmount() {
    clearTimeout(this.popEventTimer)
  },
  methods: {
    // 跳转至evoluSHEIN页面
    jumpToEvoluSHEIN() {
      if (IS_RW) {
        location.href = `${langPath}/campaign/evolushein`
      } else {
        location.href = `${langPath}/campaigns/evolushein`
      }
    },
    // 光标移入evoluSHEIN栏
    onEvoluBarMouseOver() {
      clearTimeout(this.popEventTimer)
      this.popoverShow = true
    },
    // 光标移出evoluSHEIN栏时，延时关闭气泡
    onEvoluBarMouseLeave() {
      clearTimeout(this.popEventTimer)
      this.popEventTimer = setTimeout(() => {
        this.popoverShow = false
      }, 2e2)
    }
  },
}
</script>

<style lang="less">
.evolu-shein {
  &__entry {
    display: flex;
    height: 32px;
    align-items: center;
    padding: 0 8px;
    background: linear-gradient(90deg, #FFF5E9 10.83%, #FFF 102.99%);
    border-radius: 1px;
    cursor: default;

    .split-line {
      width: 0;
      height: 10px;
      border-left: 1px solid @sui_color_safety;
      margin: 0 6px;
      opacity: 0.4;
    }

    p.detail-text {
      font-size: 10px;
      color: #767676;
      .clamp(1);
    }
  }
  &__inner-box {
    display: block;

    p {
      height: 32px;
      font-size: 12px;
      font-weight: 700;
      color: #A86104;
      display: flex;
      align-items: center;

    }

    svg, span {
      display: block;
    }
    span {
      margin-left: 2px;
    }
  }
  &__exp-box {
    font-size: 10px;
    line-height: 12px;

    p.title {
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      color: #333;
    }

    p.detail-text {
      color: #333;
      margin-top: 6px;
      line-height: 1.4;
    }

    p.jump-text {
      display: inline-block;
      margin-top: 6px;
      cursor: pointer;
      color: #2D68A8;
    }
  }
  &__icon{
    width: 14px;
    height: 14px;
  }
}
</style>
