<template>
  <GalleryBigPicZoomItem
    v-slot="slotProps"
    :image="img"
    :lazy="false"
    :show-image="false"
  >
    <div
      ref="OUTFIT_WRAP"
      class="outfitImg-tips__container"
      @mouseenter="slotProps.onMouseEnter"
      @mouseleave="slotProps.onMouseLeave"
      @mousemove="slotProps.onMouseMove"
    >
      <template v-if="showImage && img">
        <img
          class="outfitImg-tips__img lazyload"
          :data-src="transformImg({ img: dprInsert(img, '2') })"
        />
      </template>
      <canvas
        ref="OUTFIT"
        class="outfitImg-tips__draw"
      ></canvas>
    </div>
  </GalleryBigPicZoomItem>
</template>

<script>
import { mapMutations } from 'vuex'
import { transformImg } from '@shein/common-function'
import { DrawPoints } from './utils/tools'
import dprInsertMixin from '../../utils/dprInsertMixins'
import labelMixin from './mixins/label'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import GalleryBigPicZoomItem from '../Gallery/GalleryBigPicZoomItem'
daEventCenter.addSubscriber({ modulecode: '2-21' })
export default {
  name: 'OutfitImgTips',
  components: {
    GalleryBigPicZoomItem
  },
  mixins: [dprInsertMixin, labelMixin],
  props: {
    outfitData: {
      type: Object,
      default() {
        return {}
      },
    },
    wrap: {
      type: [String, Array],
      default: '',
    },
    showImage: {
      type: Boolean,
      default: true,
    },
    needActive: {
      type: Boolean,
      default: true,
    },
    activeKey: {
      type: [String, Number],
      default: '',
    },
    imageWidth: {
      type: Number,
      default: 0,
    },
    imageHeight: {
      type: Number,
      default: 0,
    },
    catId: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    boundary: {
      // 安全边界
      type: Object,
      default() {
        return {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }
      },
    },
    analysis: {
      type: Object,
      default() {
        return {
          activity_from: 'picture_anchor',
        }
      },
    },
    tipsStyle: {
      type: Object,
      default() {
        return {
          fontSize: 14,
          height: 28,
        }
      },
    },
    language: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      POINTS: null,
    }
  },
  computed: {
    img() {
      const { url } = this.outfitData
      return url
    },
  },
  watch: {
    outfitData: {
      deep: true,
      immediate: true,
      handler({ labels = [] }) {
        this.$nextTick(() => {
          labels?.length && this.initOutfit()
        })
      },
    },
    activeKey(key) {
      this.POINTS && this.POINTS.setActivePoint(key)
    },
  },
  beforeUnmount() {
    this.POINTS?.destroyAll?.()
    window.removeEventListener('resize', this.handleResizeListener)
  },
  mounted() {
    window.addEventListener('resize', this.handleResizeListener)
  },
  methods: {
    ...mapMutations(['assignState']),
    async initOutfit() {
      let wrapEl = null,
          imageWidth,
          imageHeight

      if (this.wrap) {
        wrapEl = document.querySelector(this.wrap)
        imageWidth = wrapEl.clientWidth
        imageHeight = wrapEl.clientHeight
      } else if (this.imageWidth && this.imageHeight) {
        imageWidth = this.imageWidth
        imageHeight = this.imageHeight
      } else {
        console.error('need wrap or imageWidth && imageHeight')
        return false
      }

      const { labels } = this.outfitData
      const { tipsStyle } = this
      const canvas = this.$refs['OUTFIT']
      const ratio = window.devicePixelRatio || 1
      const ctx = canvas.getContext('2d')
      const padding = 6 * ratio // 设计稿看的
      const tipsHeight = tipsStyle.height * ratio // 设计稿看的
      const font = `400 ${tipsStyle.fontSize * ratio}px Arial`
      const radius = 7 * ratio
      const margin = 10 * ratio
      const icon = !this.showIcon
        ? null
        : {
          line: 5 * ratio, // 文字箭头边长
          padding: 5 * ratio,
          color: '#222',
        }

      this.$refs['OUTFIT_WRAP'].style.height = `${imageHeight}px`
      canvas.width = imageWidth * ratio
      canvas.height = imageHeight * ratio

      if (!this.POINTS) {
        this.POINTS = new DrawPoints({
          config: {
            ctx: ctx,
            radius: radius,
            ratio: ratio, // 分辨率
            needActive: this.needActive,
            points: this.formatPoints(
              labels,
              imageWidth * ratio,
              imageHeight * ratio
            ),
            tips: {
              height: tipsHeight,
              margin: margin,
            },
            fonts: {
              font,
              padding,
              color: '#222',
            },
            triangle: {
            // 气泡三角形
              width: 6 * ratio,
              height: 10 * ratio,
            },
            icon,
            boundary: this.getRatioBoundary(ratio),
          },
          callback: {
            click: (key) => {
              this.assignState({
                outfitActiveId: key,
              })
              this.$emit('outfitClick', key)
            },
          },
        })
      } else {
        this.POINTS.reset({
          points: this.formatPoints(
            labels,
            imageWidth * ratio,
            imageHeight * ratio
          )
        })
      }
      this.POINTS.setActivePoint(this.activeKey)
    },
    getRatioBoundary(ratio) {
      const { top, right, bottom, left } = this.boundary
      return {
        top: top * ratio,
        right: right * ratio,
        bottom: bottom * ratio,
        left: left * ratio,
      }
    },
    formatPoints(labels, mw, mh) {
      const arr = labels.map((item, index) => {
        const { label, label_id, location } = item
        const [x1, y1, x2, y2] = location.split('_')
        const x = ((+x1 + +x2) / 2) * mw
        const y = ((+y1 + +y2) / 2) * mh
        return {
          x,
          y,
          width: Math.abs(x1 - x2) * mw,
          text: this.labelText(label),
          label_id,
          key: index,
        }
      })
      return arr
    },
    transformImg,
    handleResizeListener() {
      this.outfitData?.labels?.length && this.initOutfit()
    },
  },
  emits: ['outfitClick'],
}
</script>

<style lang="less">
.outfitImg-tips {
  &__container {
    position: relative;
    width: 100%;
    overflow-y: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: @zindex-task-tips;
  }
  &__img {
    width: 100%;
    height: auto;
  }
  &__draw {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
</style>
