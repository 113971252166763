<template>
  <div
    class="crop-image-container"
    :data-before-crop-src="imgSrc"
    :style="cropContainerStyle"
    @click="handleClickImage"
  >
    <img 
      :src="lazy ? EmptyImg : originImgSrc"
      class="crop-image-container__img"
      :class="{
        ...(transformImgProps.imgClassName || {}),
        lazyload: lazy
      }"
      :data-src="lazy ? originImgSrc : void 0"
      :alt="transformImgProps.alt"
      :style="transformImgProps.imgStyle"
      :data-design-width="transformImgProps.imgDesignWidth"
      @load="onLoadImg"
    />
    <div
      v-if="showMask"
      class="crop-image-container__mask"
    ></div>
    <slot name="content"></slot>
  </div>
</template>

<script>
import { EmptyImg } from 'public/src/services/resource/index'
import { closestRatio } from 'public/src/pages/common/utils/index.js'

/**
 * @name CropImageContainerForDetailMain 商详主图专用切图组件 公共组件有懒加载限制
 */
export default {
  name: 'CropImageContainer',
  props: {
    /**
     * @param {String} imgSrc 图片原始地址
     * @param {Object} transformImgProps 透传 transformImg 组件props
     * @param {String} containerBackground 容器背景颜色
     * @param {String} fixedRatio 容器固定宽高比 （宽-高）例如 fixedRatio="3-4"
     * @param {String} ignoreImgExt 忽略根据图片地址后缀决定容器比例的逻辑
     * */
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    imgSrc: {
      required: true,
      type: String,
      default: ''
    },
    containerBackground: {
      type: String,
      default: '#FFF'
    },
    lazy: {
      type: Boolean,
      default: true
    },
    lazyImg: {
      type: String,
      default: ''
    },
    transformImgProps: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fixedRatio: {
      type: String,
      default: ''
    },
    ignoreImgExt: {
      type: Boolean,
      default: false
    },
    ignoreMask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      realImgRatio: 1
    }
  },
  emits: ['clickImage'],
  computed: {
    EmptyImg() {
      return this.lazyImg || EmptyImg
    },
    cropContainerStyle() {
      const backdropContainerStyle = {
        // backgroundImage: `url(${this.originImgSrc})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        backgroundColor: `${this.containerBackground}`,
      }

      const containerRatioStyle = {
        paddingBottom: `calc(${this.containerRatio.toFixed(2)} * 100%)`,
      }

      const containerStyle = {
        ...containerRatioStyle,
        ...backdropContainerStyle
      }
      
      if (this.ignoreImgExt) {
        return containerStyle
      }

      if (this.isNotExtSrc) {
        return containerRatioStyle
      }
      
      return containerStyle
    },
    backdropFrameStyle() {
      if (this.ignoreImgExt) {
        return {}
      }
      
      return this.isNotExtSrc ? { display: 'none' } : {}
    },
    isSquareSrc() {
      // 未命中 Apollo 依旧使用未被去后缀的图片地址
      if (!this.isHitApollo()) {
        return false
      }

      return this.imgSrc.includes('_square')
    },
    isSqufixSrc() {
      // 未命中 Apollo 依旧使用未被去后缀的图片地址
      if (!this.isHitApollo()) {
        return false
      }

      return this.imgSrc.includes('_squfix')
    },
    isNotExtSrc() {
      return !this.isSqufixSrc && !this.isSquareSrc
    },
    containerRatio() {
      if (this.fixedRatio === '3-4') {
        return 4 / 3
      }
      
      if (this.fixedRatio === '4-5') {
        return 5 / 4
      }

      if (this.fixedRatio === '5-6') {
        return 6 / 5
      }

      if (this.fixedRatio === '13-16') {
        return 16 / 13
      }

      if (this.fixedRatio === '1-1') {
        return 1 / 1
      }
      
      // 以下是对非固定容器宽高比 根据imgSrc地址确定的容器大小
      const isSquareSrc = this.isSquareSrc
      // const isSqufixSrc = this.isSqufixSrc 

      if (isSquareSrc) {
        // 1 : 1
        return 1 / 1
      }
      
      // 3 : 4
      return 4 / 3
    },
    originImgSrc() {
      let resSrc = this.imgSrc
      if(!this.lazy){ // 不懒加载时，替换成webp格式 懒加载lazyload sdk会自动替换
        resSrc = resSrc.replace(/^https?\:/, '').replace(/\b(\.jpg|\.png)\b/, '.webp')
      }
      // 未命中 Apollo 依旧使用未被去后缀的图片地址
      if (this.isHitApollo()) {
        resSrc = resSrc.replace(/(_square)|(_squfix)/ig, '')
      }
      return resSrc
    },
    closestRatioStr() {
      return closestRatio(this.realImgRatio)
    },
    showMask() {
      // if (this.isNotExtSrc) {
      //   return false
      // }

      if (this.ignoreMask) {
        return false
      }

      return true
    }
  },
  methods: {
    onLoadImg(e) {
      const width = e.target.width
      const height = e.target.height

      this.realImgRatio = width / height
    },
    handleClickImage() {
      const realImgRatio = this.realImgRatio
      
      this.$emit('clickImage', realImgRatio, this.closestRatioStr)
    },
    isHitApollo() {
      if (typeof window === 'undefined') {
        return this.isSupportCropImage
      }

      const { RESOURCE_SDK } = gbCommonInfo
      if (RESOURCE_SDK && RESOURCE_SDK.isSupportCropImage) {
        return true
      }

      return false
    }
  }
}
</script>

<style lang="less">
.crop-image-container {
  position: relative;
  overflow: hidden;
  height: 0;

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.03);
  }

  // .backdrop-frame {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   backdrop-filter: blur(100px);
  // }
}
</style>
