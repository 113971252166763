<template>
  <s-popover
    trigger="hover"
    placemen="top-start"
    :immediately-render="false"
    :empty-un-show="true"
    :delay-render="300"
    :goods-id="color.goods_id"
    :prop-style="{
      maxWidth: 'unset'
    }"
    @opened="handlePopoverOpened(color)"
  >
    <template #reference>
      <div
        class="goods-color__radio"
        :class="{
          'goods-color__radio_text': colorType == 'text',
          'goods-color__radio_block': colorType == 'block',
          'goods-color__radio_radio': colorType == 'radio',
          active: color.isCurrentGoods,
        }"
        tabindex="0"
        :aria-label="color.mainSaleAttr && color.mainSaleAttr.attr_value"
        @click="handleColorClick(color)"
      >
        <div
          v-if="colorType != 'text'"
          class="radio-inner"
        >
          <template v-if="colorType === 'block'">
            <CropImageContainer
              v-if="imgField === 'goods_thumb'"
              class="fsp-element"
              :img-src="
                transformImg({ img: color.goods_thumb || color.goods_image })
              "
              ignore-img-ext
              :fixed-ratio="fixedRatio"
              :is-support-crop-image="isSupportCropImage"
            />
            <template v-if="color.isSoldoutColor">
              <span class="block-soldout"></span>
              <div class="block-soldout-slash"></div>
            </template>
          </template>
          <template v-else-if="colorType === 'radio'">
            <img :src="transformImg({ img: color.goods_color_image })" />
            <span
              v-if="color.isSoldoutColor"
              class="radio-soldout fsp-element"
            ></span>
          </template>
        </div>
        <div
          v-else
          class="radio-inner fsp-element"
        >
          {{ color.goods_title }}
        </div>
        <!-- todo: 加车弹窗特有的促销tag 后续加上 -->
        <!-- <ClientOnly 
      v-if="
        promotionTagInfos &&
        promotionTagInfos[color.goods_id] &&
        promotionTagInfos[color.goods_id].showVisible
      "
    >
      <div
        v-if="
          promotionTagInfos[color.goods_id].tagType === 1 &&
          promotionTagInfos[color.goods_id].unit_discount
        "
        v-expose="labelExpose(color.goods_id)"
        class="product-intro__tag-discount"
      >
        -{{ promotionTagInfos[color.goods_id].unit_discount }}%
      </div>
    </ClientOnly> -->
        <ClientOnly>
          <img
            v-if="color.isEco"
            class="color-evolu"
            :src="EcoImgUrl"
          />
          <img
            v-else-if="color.isHot"
            class="color-hot"
            :src="HotImgUrl"
          />
        </ClientOnly>
      </div>
    </template>
    <ClientOnly
      v-if="colorConfig.showAttrImgPopover && color.mainSaleAttr && color.mainSaleAttr.attr_value && color.mainSaleAttr.attr_image"
    >
      <div
        v-if="color.mainSaleAttr.attr_value && color.mainSaleAttr.attr_image"
      >
        <div
          v-if="color.mainSaleAttr.attr_value"
          class="goods-color__radio_popover-title"
        >
          {{ color.mainSaleAttr.attr_value }}
        </div>
        <img
          :src="transformImg({ img: color.mainSaleAttr.attr_image })"
          class="goods-color__radio_popover-img"
        />
      </div>
    </ClientOnly>
  </s-popover>
</template>

<script setup>
import { ClientOnly } from '@sheinfe/vue-client-only'
import { defineComponent, defineProps, ref, toRefs } from 'vue'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import { transformImg } from '@shein/common-function'

defineComponent({
  name: 'ColorRadio',
  components: { ClientOnly, CropImageContainer },
})

const props = defineProps({
  isDetail: {
    type: Boolean,
    default: true,
  },
  cdnUrl: {
    type: String,
    default: '',
  },
  isSupportCropImage: {
    type: Boolean,
    default: false,
  },
  fixedRatio: {
    type: String,
    default: '3-4',
  },
  colorType: {
    type: String,
    default: 'radio',
  },
  imgField: {
    type: String,
    default: 'color_image',
  },
  color: {
    type: Object,
    default: () => ({}),
  },
  colorConfig: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits(['radioClick', 'radioPopverOpen'])

// eslint-disable-next-line vue/no-setup-props-destructure
const cdnUrl = props.cdnUrl
const EcoImgUrl = 'https://img.ltwebstatic.com/images3_ccc/2024/05/07/42/color_evolu_pc.png'
const HotImgUrl = cdnUrl + '/she_dist/images/color_hot-e5b559d042.png'

const { isSupportCropImage, fixedRatio, colorType, imgField } = toRefs(props)
// eslint-disable-next-line no-unused-vars
const colorPopover = ref(null)

const handleColorClick = color => {
  emits('radioClick', color)
}

const handlePopoverOpened = color => {
  emits('radioPopverOpen', color)
}
</script>

<style lang="less">
.goods-color__radio {
  // block类型
  &_block {
    position: relative;
    .margin-r(10px);
    margin-bottom: 10px;
    display: inline-block;
    font-size: 0;
    cursor: pointer;
    width: 51px;
    padding: 3px;
    border: 2px solid transparent;
    &:hover {
      outline: 1px solid #222;
      outline-offset: -1px;
    }

    .radio-inner {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      &:after {
        position: absolute;
        .left(0);
        .right(0);
        top: 0;
        bottom: 0;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid fade(#000, 8%);
        background: transparent;
        /* rw:begin*/
        border: 1px solid transparent;
      }
    }

    &.active {
      border: 2px solid #222;
    }

    .block-soldout {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
    }

    .block-soldout-slash {
      bottom: 0;
      left: 1px;
      top: 50%;
      transform: translate3d(0%, 0%, 0) rotate(-54.8deg);
      transform-origin: left bottom;
      width: 74px;
      height: 2px;
      background: rgba(153, 153, 153, 0.7);
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: -1px;
        .left(0);
        background: rgba(255, 255, 255, 0.6);
      }
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -1px;
        .left(0);
        background: rgba(255, 255, 255, 0.6);
      }
    }
  }

  // radio类型
  &_radio {
    position: relative;
    .margin-r(10px);
    margin-bottom: 10px;
    padding: 3px;
    width: 36px;
    height: 36px;
    line-height: 28px;
    border: 1px solid transparent;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    font-size: 0;
    cursor: pointer;
    &:hover {
      border: 1px solid #222;
      /* rw:begin*/
      border: 1px solid rgba(255, 105, 110, 1);
    }

    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }

    .radio-inner {
      width: 28px;
      height: 28px;
      &::after {
        position: absolute;
        .left(2px);
        top: 2px;
        display: block;
        content: '';
        width: 30px;
        height: 30px;
        border: 1px solid fade(#000, 8%);
        border-radius: 50%;
        background: transparent;
      }
    }

    &.active {
      .radio-inner {
        &:after {
          border: 2px solid fade(#000, 8%);
          /* rw:begin*/
          border: 2px solid transparent;
        }

        &::before {
          position: absolute;
          .left(-1px);
          .right(0);
          top: -1px;
          bottom: 0;
          display: block;
          content: '';
          width: 36px;
          height: 36px;
          border: 2px solid @sui_color_main;
          border-radius: 50%;
          background: transparent;
        }
      }
      .radio-soldout {
        width: 24px;
        .left(5px);
      }
    }

    .radio-soldout {
      position: absolute;
      .left(4px);
      top: 50%;
      transform: translate3d(0, -50%, 0) rotate(-45deg);
      transform-origin: center;
      display: block;
      width: 26px;
      height: 2px;
      background: rgba(153, 153, 153, 0.7);
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: -1px;
        left: 0;
        background: rgba(255, 255, 255, 0.6);
      }
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -1px;
        left: 0;
        background: rgba(255, 255, 255, 0.6);
      }
    }
  }

  // text类型

  &_text {
    position: relative;
    margin: 0 10px 10px 0;
    border: 1px solid #e5e5e5;
    border-radius: 48px;
    text-align: center;
    cursor: pointer;
    min-width: 66px;
    min-height: 32px;
    line-height: 16px;
    font-size: 0;
    display: inline-block;
    max-width: 100%;
    padding: 8px 4px;
    &:hover {
      border: 1px solid @sui_color_main;
    }

    .radio-inner {
      max-width: 100%;
      font-size: 14px;
      overflow: hidden;
      padding: 0 12px;
    }

    &.active {
      &:hover {
        border-color: transparent;
      }

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        .left(0);
        .right(0);
        content: '';
        width: 100%;
        height: 100%;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        box-sizing: content-box;
        margin: auto;
        border-radius: 16px;
        background: transparent;
        border: 2px solid @sui_color_main;
      }
    }
  }

  .color-evolu {
    position: absolute;
    top: -4px;
    .right(-8px);
    width: 19px;
    height: 13px;
    border-radius: 0;
    z-index: @zindex-hack;
  }

  .color-hot {
    position: absolute;
    top: -4px;
    .right(-13px);
    width: 24px;
    height: 13px;
    border-radius: 0;
    z-index: @zindex-hack;
  }

  &_popover-title {
    font-size: 14px;
    font-weight: 700;
    color: @sui_color_brand;
  }
  &_popover-img {
    margin-top: 8px;
    height: 72px;
  }
}
</style>
