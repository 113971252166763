/**
 * 加购按钮按钮埋点事件
 * @param btnType 'addCart' | 'ocp'
 * @param extraData
 */
const fireBtnAnalysis = (btnType, extraData = {}) => {
  const traceid = gbExposeTraceid('getProduct', {
    goods_id: extraData.goods_id,
  })

  const params = {
    daId: '1-7-1-1',
    extraData: {
      button_type: btnType,
      code: 'detailMain',
      from: 'main',
      traceid,
      ...extraData,
    },
  }

  daEventCenter.triggerNotice(params)
}

/**
 * 快速加车埋点
 * @param {*} param
 */
const quickAddAnalysis = ({ baseInfo, customerAnalysis }, { target }) => {
  const { goods_id, goods_sn, productRelationID, usdAmount, amount, mallCode, slideIndex } = baseInfo
  const { reportData } = customerAnalysis
  daEventCenter.triggerNotice({
    daId: '2-3-5',
    target: target,
    extraData: {
      goodsListParams: {
        sku: goods_sn,
        spu: productRelationID || goods_sn,
        goodsId: goods_id,
        originPrice: usdAmount,
        price: amount,
        mallCode: mallCode,
        index: slideIndex || 0,
      },
      tab_list: '',
      need_tab_list: true,
      ...reportData,
    },
  })
}

/**
 * 加车请求成功埋点
 * @param {*} baseInfo
 * @param {*} validateParams
 */
const addToBagAnalysis = (
  { baseInfo, validateParams, formData },
  { customerAnalysis, res, btnType }
) => {
  const {
    interestpoints,
    quickShip,
    sku_code,
    goods_sn,
    productRelationID,
    cat_id,
    usdAmount,
    quantity,
    unit_discount,
    amount,
    business_model,
    store_code,
    mallCode,
    mall_tags,
    hasNewFlag,
    variant,
    threshold
  } = baseInfo
  const { openQuick, isCustomization } = validateParams
  const { reportData = {} } = customerAnalysis
  // 加车埋点
  fireBtnAnalysis(btnType, {
    result: res,
    postData: Object.assign(
      {
        interestpoints,
        quickShip: quickShip,
        skuCode: sku_code || '',
        sku: goods_sn,
        spu: productRelationID || goods_sn,
        catId: cat_id,
        price: usdAmount,
        quantity: quantity,
        variant,
        unit_discount,
        amount,
        index: 0,
        businessModel: business_model,
        storeCode: store_code,
        mallCode,
        location: openQuick ? 'suspension' : 'page',
        image_tp: 'small',
        mallTag: mall_tags,
        ...reportData,
        threshold,
      },
      formData
    ),
    isNew: hasNewFlag,
    is_customize: isCustomization ? 1 : 0,
  })
}

/**
 * 加车失败埋点
 * @param {*} baseInfo
 * @param {*} validateParams
 * @param {*} customerAnalysis
 */
const addToBagFailAnalysis = (
  { baseInfo, validateParams },
  { customerAnalysis, btnType }
) => {
  const { reportData = {} } = customerAnalysis
  const {
    quickShip,
    sku_code,
    goods_sn,
    goods_id,
    mallCode,
    mall_tags,
    hasNewFlag,
  } = baseInfo
  const { openQuick, isCustomization } = validateParams
  // 加车失败
  fireBtnAnalysis(btnType, {
    skuCode: sku_code || '',
    result: { code: 1 },
    postData: {
      quickShip: quickShip,
      sku: goods_sn,
      goods_id,
    },
    faultReason: 'server_failure',
    isNew: hasNewFlag,
    mallCode,
    location: openQuick ? 'suspension' : 'page',
    image_tp: 'small',
    mallTag: mall_tags,
    ...reportData,
    is_customize: isCustomization ? 1 : 0,
  })
}

export { quickAddAnalysis, addToBagAnalysis, addToBagFailAnalysis, fireBtnAnalysis }
