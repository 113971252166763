<template>
  <div class="cotton-source__details">
    <div class="info-item main">
      <h3 class="title">
        {{ language.SHEIN_KEY_PC_22238 }}: {{ cottonInfo.place_of_origin }}
        <span
          v-if="certificationImage"
          class="image"
          @click="
            updateModel({
              show: true,
              type: 'image',
              title: language.SHEIN_KEY_PC_22238,
            })
          "
        >
          <Icon
            name="sui_icon_photos_16px"
            size="16px"
          />
          {{ language.SHEIN_KEY_PC_22239 }}
        </span>
      </h3>
    </div>
    <div class="info-item">
      <h4 class="title">
        {{ language.SHEIN_KEY_PC_22240 }}: {{ factoryPlace }}
      </h4>
      <div class="content">
        <div
          v-if="factoryInfo.factory_nm"
          class="text"
        >
          {{ language.SHEIN_KEY_PC_22243 }}: {{ factoryInfo.factory_nm }}
          <span
            v-if="showSrsTips(factoryInfo)"
            class="srs-icon"
            @click="openSRS"
          >
            <Icon
              name="sui_icon_srs_14px"
              size="14px"
            />
            SRS
          </span>
        </div>
        <div
          v-if="showSrsTips(factoryInfo) && formatSrsText(factoryInfo)"
          class="text"
        >
          SRS {{ language.SHEIN_KEY_PC_22244 }}
          <span
            class="tips"
            @click="openSRS"
          >
            <Icon
              class="icon"
              name="sui_icon_doubt_16px_1"
              size="16px"
            />
          </span>
          :
          {{ formatSrsText(factoryInfo) }}
        </div>
      </div>
    </div>
    <div class="info-item">
      <h4 class="title">
        {{ language.SHEIN_KEY_PC_22242 }}: {{ supplierPlace }}
      </h4>
      <div class="content">
        <div
          v-if="supplierInfo.supplier_nm"
          class="text"
        >
          {{ language.SHEIN_KEY_PC_22245 }}: {{ supplierInfo.supplier_nm }}
          <span
            v-if="showSrsTips(supplierInfo)"
            class="srs-icon"
            @click="openSRS"
          >
            <Icon
              name="sui_icon_srs_14px"
              size="14px"
            />
            SRS
          </span>
        </div>
        <div
          v-if="showSrsTips(supplierInfo) && formatSrsText(supplierInfo)"
          class="text"
        >
          SRS {{ language.SHEIN_KEY_PC_22244 }}
          <span
            class="tips"
            @click="openSRS"
          >
            <Icon
              class="icon"
              name="sui_icon_doubt_16px_1"
              size="16px"
            />
          </span>
          :
          {{ formatSrsText(supplierInfo) }}
        </div>
      </div>
    </div>
    <div class="info-footer">
      *{{ language.SHEIN_KEY_PC_22246 }}
    </div>
    <s-dialog
      v-model:visible="model.show"
      :type="'W720'"
      :show-close="true"
      :append-to-body="true"
      @opened="opened"
    >
      <template #title>
        {{ model.title }}
      </template>
      <div class="product-intro__cottonSource-popup">
        <template v-if="model.type === 'image'">
          <ProductIntroCottonSourceImage
            ref="imageRef"
            :certification-image="certificationImage"
          />
        </template>
        <template v-if="model.type === 'srs'">
          <ProductIntroCottonSourceSRS
            ref="srsRef"
            :content="srsContent.conText"
          />
        </template>
      </div>
    </s-dialog>
  </div>
</template>

<script>
import ProductIntroCottonSourceSRS from './ProductIntroCottonSourceSRS.vue'
import ProductIntroCottonSourceImage from './ProductIntroCottonSourceImage.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Icon } from '@shein-aidc/icon-vue3'
const SRSLevel = ['C', 'D', 'ZTV']
export default {
  name: 'ProductIntroCottonSourceInfo',
  components: { ProductIntroCottonSourceSRS, ProductIntroCottonSourceImage, Icon },
  props: {
    factoryInfo: Object,
    supplierInfo: Object,
    cottonInfo: Object,
    certificationImage: String,
    factoryPlace: String,
    supplierPlace: String,
    srsContent: {
      type: Object,
      default() {
        return {}
      },
    },
    language: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      showImage: false,
      model: {
        show: false,
        title: '',
        type: '', // image / srs
      },
    }
  },
  methods: {
    updateModel({ show, title, type }) {
      this.model = { show, title, type }
    },
    handleOpened() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-88',
      })
    },
    openSRS() {
      const { conName, conText } = this.srsContent
      if (!conText) return
      this.updateModel({
        show: true,
        type: 'srs',
        title: conName,
      })
    },
    showSrsTips({ authentication_tp, authentication_lvl }) {
      return !!authentication_tp && !SRSLevel.includes(authentication_lvl)
    },
    formatSrsText({ authentication_lvl, authentication_score }) {
      if (authentication_lvl && authentication_score >= 0) {
        return `${authentication_lvl} / ${authentication_score}`
      } else if (authentication_score >= 0) {
        return authentication_score
      } else if (authentication_lvl) {
        return authentication_lvl
      } else {
        return null
      }
    },
    opened() {
      // image / info / srs
      const { type } = this.model
      this.$refs[`${type}Ref`].handleOpened()
    },
  },
}
</script>

<style lang="less">
.cotton-source__details {
  .info-item {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #222222;
    .title {
      font-size: 12px;
      line-height: 14px;
      text-transform: none;
    }
    .content {
      display: flex;
      flex-direction: column;
      .text {
        display: inline-flex;
        align-items: center;
        margin-top: 12px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }
      .tips {
        cursor: pointer;
      }
      .icon {
        margin: 0 3px;
      }
      .srs-icon {
        padding: 2px 4px;
        margin-left: 4px;
        font-size: 12px;
        display: flex;
        align-items: center;
        color: #11763f;
        background: #e1f5e3;
        border-radius: 2px;
        cursor: pointer;
        i {
          margin-right: 4px;
        }
      }
    }
    &.main {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      .title {
        font-size: 16px;
        line-height: 18px;
        display: inline-flex;
        align-items: center;
      }
      .image {
        margin-left: 11px;
        font-weight: 400;
        font-size: 12px;
        color: @sui_color_link;
        cursor: pointer;
      }
    }
  }
  .info-footer {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #959595;
  }
}
</style>
