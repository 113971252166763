<template>
  <div
    v-if="descriptionList.length || content.imgAttr.length"
    class="product-intro__description"
    @keyup.esc="escHandle"
  >
    <h2
      ref="descriptionHeadHook"
      v-enterkey
      class="product-intro__description-head"
      tabindex="0"
      :aria-label="descriptionConf.descriptionName"
      da-event-click="1-8-6-17"
      @click="handleFold"
    >
      <span>{{ descriptionConf.descriptionName }}</span>
      <Icon
        v-if="unfold.includes('Description')"
        name="sui_icon_min_16px"
        size="16px"
        class="head-icon"
      />
      <Icon
        v-else
        name="sui_icon_add_16px"
        size="16px"
        class="head-icon"
      />
    </h2>
    <ClientOnly>
      <div
        v-show="unfold.includes('Description')"
        ref="descriptionPanel"
      >
        <!-- 描述 -->
        <ProductIntroDetailGoodsDesc
          :is-show="unfold.includes('Description')"
          :content="content"
          :idx="'description'"
        >
          <div
            v-show="descriptionConf.descriptionColor"
            class="product-intro__description-color"
            tabindex="0"
            :aria-label="
              descriptionConf.descriptionList[0].attr_name +
                ': ' +
                descriptionConf.descriptionList[0].attr_value
            "
          >
            <div class="key">
              {{ descriptionConf.descriptionList[0].attr_name }}:
            </div>
            <div class="val">
              {{ descriptionConf.descriptionList[0].attr_value }}
              <div
                class="product-intro__description-table-html"
                :style="{
                  'max-height':
                    showMoreMark || showMoreMarkInit ? '40px' : 'none',
                }"
              >
                <div
                  ref="descriptionColor"
                  class="word"
                  v-html="descriptionConf.goodsDesc"
                ></div>
              </div>
              <div
                v-if="showMoreMark"
                class="product-intro__description-more"
                @click="showMoreMark = false"
              >
                {{ language.SHEIN_KEY_PC_15953 }}
                <span class="svgicon svgicon-arrow-down"></span>
              </div>
            </div>
          </div>
          <div 
            v-expose="{ id: '1-8-6-222', data: { is_include_cv: hasCVAttrStatus ? 1 : 0 } }"
            class="product-intro__description-table"
          >
            <div
              v-for="(item, index) in descriptionList"
              :key="index"
              class="product-intro__description-table-item"
              tabindex="0"
              role="text"
            >
              <div class="key">
                {{ item.attr_name }}:
              </div>
              <div class="val">
                {{ item.attr_value }}
                <span dir="ltr">{{ calcValue(item) }} </span>
              </div>
            </div>
          </div>
          <EvoluSheinBar
            v-if="showEvoluFloor"
            :box-style="{ marginTop: '16px' }"
          />
        </ProductIntroDetailGoodsDesc>
      </div>
    </ClientOnly>
  </div>
</template>

<script>
import 'public/src/icon/arrow-down.svg'
import { mapState, mapMutations, mapGetters } from 'vuex'
import ProductIntroDetailGoodsDesc from './ProductIntroDetailGoodsDesc'
import EvoluSheinBar from './EvoluSheinBar'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { contentIncludeSpecialFeatures, CVAttrId } from '../utils/productDetail'
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'ProductIntroDescription',
  components: {
    ClientOnly,
    EvoluSheinBar,
    ProductIntroDetailGoodsDesc,
    Icon
  },
  data() {
    return {
      showMoreMark: false,
      showMoreMarkInit: true,
    }
  },
  computed: {
    ...mapState(['productIntroData', 'unfold', 'language', 'showHeelHeight', 'cmInchInfo']),
    ...mapGetters(['pageComponents', 'isNeedFilterCVAttr', 'originDescriptionImgList', 'originDescriptionList', 'hasCVAttrStatus', 'isEvolusheinTag']),
    descriptionConf() {
      return this.pageComponents.descriptionConf
    },
    descriptionList() {
      let result = this.originDescriptionList
      if(this.isNeedFilterCVAttr) { // 过滤CV属性
        result = result.filter(item => !CVAttrId.includes(Number(item.attr_id || 0)))
      }
      return result
    },
    content() {
      let result = this.originDescriptionImgList
      if(this.isNeedFilterCVAttr) { // 过滤CV属性
        result = result.filter(item => {
          const attr_id = item?.[0]?.attr_id || 0
          return !CVAttrId.includes(Number(attr_id))
        })
      }
      return {
        textAttr: [],
        imgAttr: result
      }
    },
    calcValue() {
      return (item) => {
        if(this.showHeelHeight) {
          let dimensionAttrInfo = this.productIntroData?.sizeInfoDes?.dimensionAttrInfo ?? []
          const heelHeightMap = dimensionAttrInfo.find(item => { // {attr_name:"Heel Height"attr_name_en:"Heel Height"},通过attr_name的值（多语言）去尺码表拿数据
            return item?.attr_name_en?.includes('Heel Height')
          })
          if(heelHeightMap?.attr_name?.includes(item.attr_name)) {
            return  this.cmInchInfo
          }
        }
        return ''
      }
    },
    // 判断evolushein楼层展示
    showEvoluFloor() {
      const { descriptionList = [] } = this
      return contentIncludeSpecialFeatures(descriptionList) && this.isEvolusheinTag
    },
  },
  watch: {
    'productIntroData.getBeautyGoodsDesc'() {
      if (this.unfold.includes('Description')) {
        this.setShowMoreMark()
      } else {
        this.showMoreMarkInit = true
      }
    },
    'unfold': {
      handler (newVal) {
        if (newVal.includes('Description') && this.showMoreMarkInit) {
          this.showMoreMarkInit = false
          this.setShowMoreMark()
        }
      },
      deep: true
    }
  },
  mounted() {
    if(window.SaPageInfo && SaPageInfo.page_param) {
      SaPageInfo.page_param.is_include_cv = this.hasCVAttrStatus ? 1 : 0
    }
    if (this.descriptionList.length) {
      daEventCenter.triggerNotice({
        daId: '1-8-6-32',
      })
    }
  },
  methods: {
    ...mapMutations(['assignState']),
    // esc 关闭
    escHandle() {
      this.unfold.includes('Description') && this.$refs['descriptionHeadHook'].click()
    },
    /**
     * 收起展开
     */
    handleFold() {
      let updatedArray = []
      if (this.unfold.includes('Description')) {
        updatedArray = this.unfold.filter(item => item !== 'Description')
      } else {
        updatedArray = [...this.unfold, 'Description']
      }
      this.assignState({
        unfold: updatedArray
      })
    },
    setShowMoreMark() {
      this.$nextTick(() => {
        this.showMoreMark =
          this.$refs.descriptionColor &&
          this.$refs.descriptionColor.offsetHeight > 40
      })
    },
  },
}
</script>
