<template>
  <div
    class="product-intro__freeshipping-item full-line paddingTop-0"
    da-event-click="1-8-6-74"
    @click="clickHandle"
  >
    <div class="product-intro__freeshipping-icon">
      <Icon
        name="sui_icon_cod_24px"
        size="24px"
        :color=" isRw ? '#2bcacc' : '#198055' "
      />
    </div>
    <div>
      <div class="product-intro__freeshipping-title">
        <span tabindex="0">
          <p>{{ codPolicy.codTitle }}</p>
        </span>
        <Icon
          class="product-intro__freeshipping-more"
          name="sui_icon_doubt_circle_14px_1"
          color="#999"
          size="14px"
        />
      </div>
      <div class="product-intro__freeshipping-desc">
        <span class="gray">{{ language.SHEIN_KEY_PC_14599 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'ShippingCodPolicy',
  components: { Icon },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    codPolicy: {
      type: Object,
      default: () => {}
    },
    isRw: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickHandle() {
      this.$emit('clickHandle', 'cod')
    }
  },
  emits: ['clickHandle']
}
</script>
