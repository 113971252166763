<script>
export default {
  name: 'SizeSelectItemLabel'
}
</script>
<script  setup>
import { defineProps, inject, computed, ref } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import SizeDescContent from '../SizeDescContent/index.vue'
import AttrPicInfo from '../AttrPicInfo/index.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { sortMainSubSize } from 'public/src/pages/goods_detail_v2/utils/sizeInfoDesc.js'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  subItem: {
    type: Object,
    default: () => ({}),
  },
  subIndex: {
    type: Number,
    default: 0,
  },
  sizeInfoIndex: {
    type: Object,
    default: () => ({}),
  },
  isAllSoldOut: {
    type: Boolean,
    default: false,
  },
  sizePopoverPlacemen: {
    type: Array,
    default: () => [],
  },
  localSizeComputed: {
    type: Object,
    default: () => ({}),
  },
  heightSizeObject: {
    type: Object,
    default: () => ({}),
  },
  bubbleInfoComputed: {
    type: Object,
    default: () => ({}),
  },
  index: {
    type: Number,
    default: 0,
  },
})
let showDetail = ref(false)
const sizeRefs = inject('sizeRefs')
const setSizeRefs = inject('setSizeRefs')
const config = inject('config')
const language = inject('language')
const commonFn = inject('commonFn')
const analysisData = inject('analysisData')

const stock = computed(() => commonFn.chooseCheck(props.item.attr_id, props.item.attr_name, props.subItem.attr_value_name, '', props.subItem.attr_value_id))
const isActive = computed(() => props.subItem.attr_value_id == props.sizeInfoIndex[props.item.attr_id]?.attr_value_id && +stock.value >= 0)
const calcSize = computed(() => {
  const { defaultlocalsize, showHightSize, sizeSaleAttrId } = config || {}
  const { attr_id } = props.item || {}
  const { attr_value_name_en, attr_std_value, attr_value_name } = props.subItem || {}
  const { localSizeMap } = props.localSizeComputed || {}
  let defaultSize = attr_std_value ? `${attr_std_value} / ${attr_value_name}` : attr_value_name
  // 选择查看默认尺码时，本地尺码不存在 (所以当本地尺码存在时，必定为主尺码)
  let localSize = attr_id == sizeSaleAttrId ? localSizeMap?.[attr_value_name_en] || '' : ''
  let { main_size, sub_size } = sortMainSubSize(defaultSize, localSize, defaultlocalsize)
  // 如果不存在副尺码，且需要显示高度尺码，且尺码不包含/，且存在高度尺码
  if (sub_size === '' && showHightSize && main_size.indexOf('/') === -1 && props.heightSizeObject?.[attr_value_name]) {
    sub_size = `${props.heightSizeObject[attr_value_name]}`
  }
  return sub_size ? `${main_size} ${sub_size}` : main_size
})
const isLongSizeTwoLine = computed(() => config.longsizeTowLine && calcSize.value?.length > 10)
const sizeDesc = computed(() => props.item.attr_id == config.sizeSaleAttrId ? props.bubbleInfoComputed.sizeDescObj?.[props.subItem.attr_value_name_en] || [] : [])
const handleSizeHover = (i) => {
  const doms = document.querySelectorAll(`.j-product-intro__size-radio-spopover_87_index${i} .product-intro__size-popover-list`)
  // let index
  for (let i = 0; i < doms.length; i++) {
    if (doms[i].className.indexOf('lessthan3') > -1) {
      if (doms[i].scrollHeight > doms[i].clientHeight) {
        showDetail.value = true
        break
      }
    }
    if (doms[i].className.indexOf('morethan3') > -1) {
      if (doms[i].scrollWidth > doms[i].clientWidth) {
        showDetail.value = true
        break
      }
    }
    if (
      doms[i].className.indexOf('lessthan3') == -1 &&
          doms[i].className.indexOf('morethan3') == -1
    ) {
      showDetail.value = true
      break
    }
  }
  if (showDetail.value) {
    Object.values(sizeRefs.value['popoverRef'] || {}).forEach(_ => _.calcPopoverPosition?.())
    sizeRefs['popoverRef']?.forEach(_ => _.calcPopoverPosition?.())
    return
  }
  showDetail.value = false

  // 上报图片埋点
  if (!props.subItem.attr_image) return
  const location = config?.from === 'detail' ? 'page' : 'popup'
  daEventCenter.triggerNotice({
    daId: '1-8-6-197',
    extraData: {
      goods_id: analysisData.value.goodsId,
      location,
      main_attr: props.index + 2,  // 主销售属性上报1；次级上报2；3级上报3.。。
    },
  })

}
</script>

<template>
  <s-popover
    :ref="setSizeRefs('popoverRef', subItem.attr_value_id)"
    :class="[
      'product-intro__size-radio-spopover',
      `j-product-intro__size-radio-spopover_${item.attr_id}_index${subIndex + 1}`,
    ]"
    :placemen="sizePopoverPlacemen[subIndex + 1]"
    :trigger="config.isMobile ? 'click' : 'hover'"
    :hide-close-icon="true"
    :append-to-body="true"
    :empty-un-show="true"
    :delay-render="300"
    @opened="handleSizeHover(subIndex + 1)"
  >
    <template #reference>
      <div
        v-enterkey
        class="product-intro__size-radio fsp-element"
        :class="{
          'product-intro__size-radio_active': isActive,
          'product-intro__size-radio_soldout': stock === 0 || isAllSoldOut,
          'product-intro__size-radio_disabled': stock === undefined,
        }"
        da-event-click="1-8-6-5"
        :data-size-radio="subItem.attr_value_id"
        :data-attr_value_name="subItem.attr_value_name"
        :data-attr_id="item.attr_id"
        tabindex="0"
        :aria-label="calcSize"
        @touchstart="commonFn.handleChooseTouch($event, true, item, subItem)"
        @click="commonFn.handleChoose($event, true, item, subItem)"
      >
        <p
          class="product-intro__size-radio-inner"
          :class="{
            'product-intro__sizes-item-text--one': !isLongSizeTwoLine,
            'product-intro__sizes-item-text--two': isLongSizeTwoLine,
          }"
        >
          {{ calcSize }}
        </p>
      </div>
    </template>
    <!-- 尺码图片 -->
    <ClientOnly
      v-if="config.showAttrImgPopover && subItem.attr_image && calcSize"
    >
      <AttrPicInfo
        :text="calcSize"
        :image="subItem.attr_image"
      />
    </ClientOnly>
    <!-- 尺码气泡 -->
    <ClientOnly v-else-if="sizeDesc && sizeDesc.length">
      <SizeDescContent 
        class="product-intro__size-popover-content"
        :size-info-title="bubbleInfoComputed.sizeInfoTitle"
        :size-desc="sizeDesc"
      >
        <template #footer>
          <div
            v-show="showDetail"
            class="product-intro__size-popover-detail"
            @click="() => commonFn.openSizeGuideModal(undefined, { fromScene: 'detail_pop' })"
          >
            {{ language.SHEIN_KEY_PC_15098 }}
          </div>
        </template>
      </SizeDescContent>
    </ClientOnly>
  </s-popover>
</template>
