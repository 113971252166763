<template>
  <!-- ADD ERROR TIPS -->
  <template v-if="topConfig.showErrorTips">
    <ClientOnly>
      <div
        v-if="errorTipsCombo.addToBagErrorTips"
        class="product-intro__add-error"
      >
        {{ errorTipsCombo.addToBagErrorTips }}
      </div>
    </ClientOnly>
    <div
      v-show="errorTipsCombo.showMallSoldOutTips"
      class="product-intro__add-soldtip"
    >
      {{ errorTipsCombo.mallSoldOutTips }}
    </div>
  </template>

  <!-- ADD ATMOS -->
  <div
    v-if="topConfig.showFreeshippingButtonType || errorTipsCombo.showAtmosphere"
    class="product-intro__atmosphere-freeshipping"
    :class="{ 'keep-left': topConfig.showFreeshippingButtonType }"
  >
    <template v-if="topConfig.showFreeshippingButtonType">
      <div 
        v-expose="{
          id: '1-6-1-167',
          data: {
            freeshipping_location: 3
          }
        }"
        class="product-intro__freeshipping-button"
      >
        <div class="product-intro__freeshipping-buttonIcon">
          <Icon
            name="sui_icon_free_shipping_12px"
            size="14px"
          />
        </div>
        <p>{{ topConfig.freeShippingButtonTypeText }}</p>
      </div>
      <div 
        v-if="errorTipsCombo.showAtmosphere"
        class="product-intro__atmosphere-freeshippingGap"
      >
        <div></div>
      </div>
    </template>

    <ClientOnly>
      <AtmosphereSwiper
        v-if="errorTipsCombo.showAtmosphere"
        class="product-intro__atmosphere"
      />
    </ClientOnly>
  </div>
</template>

<script name="TopTips" setup>
import { defineProps, toRefs } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { ClientOnly } from '@sheinfe/vue-client-only'
import AtmosphereSwiper from '../../AtmosphereSwiper.vue' // TODO 异步
const props = defineProps({
  topConfig: { // 外围透传配置信息
    type: Object,
    default: () => ({
      showErrorTips: true,
      showFreeshippingButtonType: false,
      freeShippingButtonTypeText: ''
    }),
  },
  errorTipsCombo: {
    type: Object,
    default: () => ({
      mallSoldOutTips: '',
      addToBagErrorTips: '',
      showMallSoldOutTips: false,
      showAtmosphere: false
    }),
  }
})
const { topConfig, errorTipsCombo } = toRefs(props)
</script>

<style lang="less">
.product-intro {
  &__add-soldtip {
    color: @sui_color_unusual;
    font-size: 12px;
    margin-bottom: 24px;
  }
  &__add-error {
    margin-bottom: 3px;
    font-size: 12px;
    color: @sui_color_unusual;
  }
  &__atmosphere {
    flex: 243;
  }
  &__atmosphere-freeshipping {
    display: flex;
    height: 28px;
  }
  &__atmosphere-freeshippingGap {
    flex: 17;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 0.5px;
      height: 14px;
      background: #bbbbbb;
    }
  }
  &__freeshipping-button {
    flex: 91;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    color: #198055;

    & > p {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0px;
      white-space: nowrap;
    }
  }
  &__freeshipping-buttonIcon {
    margin-left: 1px;
    margin-right: 2px;
  }
}
.product-intro__atmosphere-freeshipping{
  &.keep-left {
    width: calc(100% - 71px);
  }
}
</style>
