import label_language from 'public/src/pages/goods_detail_v2/components/OutfitRecommend/utils/tools/label_language.js'
export default {
  computed: {
    labelLanguageMap() {
      return label_language(this.language)
    }
  },
  methods: {
    labelText(key) {
      return this.labelLanguageMap[key] || this.language.SHEIN_KEY_PC_15317
    }
  },
}
