<template>
  <div
    v-if="goodsSn && filterOptionsData.length > 0"
    class="j-expose__other-options-container"
  >
    <div class="j-expose__other-options-content">
      <div class="j-expose__other-options-target">
        <div
          class="product-intro__otheroptions j-da-event-box"
          data-title="otherOptions"
          data-active-from="other_options"
        >
          <div
            v-for="(item, index) in filterOptionsData"
            :key="index"
            class="product-intro__otheroptions-item"
          >
            <h2
              class="product-intro__otheroptions-head"
              tabindex="0"
              :aria-label="item.theme_name"
            >
              {{ item.theme_name }}
            </h2>
            <div class="product-intro__otheroptions-swiper">
              <common-slider
                :unique-name="`${commonSlider.uniqueNamePrefix}${index}`"
                :slides-per-group="2"
                :goods-arr="item.goods"
                :is-normal="false"
                :is-otheroptions="true"
                :theme-name="item.theme_name"
                :site-mall-info="siteMallInfo"
                :show-shein-club-discount-value="!IS_RW"
                :update-exposed-list="updateExposedList"
                @slide="slide"
                @tap-item="showQuickviewModal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import CommonSlider from './CommonSlider'
import { getImageRatio } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'

let QuickViewPopover = {}
if (typeof window !== 'undefined') {
  import('../analysis/index')
  import('public/src/pages/common/quickView').then(res => {
    QuickViewPopover = res.default
  })
}

daEventCenter.addSubscriber({ modulecode: '1-8-1' })

export default {
  name: 'ProductIntroOtherOptions',
  components: {
    CommonSlider,
  },
  data() {
    return {
      result: null, // 暂存 other options API 数据
      seriesResult: {}, // 系列数据
      seriesProductResult: {}, // 暂存每个系列商品数据
      optionsDataLoaded: [],
      commonSlider: {
        // 滑块
        perViewCount: 2, // 默认每页商品数
        uniqueNamePrefix: 'otherOptionsCommonSlider', // 组件唯一标识
        exposedIndex: {}, // 已曝光的滑块页数
      },
      opIndex: 0
    }
  },
  computed: {
    ...mapState(['realtimePricePromotionStatus', 'isUpdateSkc', 'IS_RW', 'productIntroData']),
    ...mapGetters(['goodsSn', 'currentRate', 'siteMallInfo', 'optionsData']),
    filterOptionsData() {
      /* 
       服务端已处理过滤
      
      */
      return this.optionsDataLoaded
    },
  },
  watch: {
    realtimePricePromotionStatus(val) {
      val && this.isUpdateSkc && this.getData()
    },
  },
  created() {
    this.optionsDataLoaded = this.optionsData
  },
  mounted() {
    this.initSlider()
  },
  methods: {
    ...mapMutations(['assignState']),
    ...mapActions(['showLoginModal', 'updateExposedList', 'autoGetCouponAtAddBag']),
    /**
     * 获取 other options 数据
     */
    async getData() {
      const result = await this.getOtherOptions({ goodsSn: this.goodsSn })
      this.optionsDataLoaded = result
      this.initSlider()
    },
    getOtherOptions({ goodsSn = '' } = {}) {
      if (!goodsSn) {
        return null
      }
      const themeTypeStr = this.productIntroData?.cccAttrGroups?.themeTypeRes?.data?.themeType || ''
      return new Promise(resolve => {
        schttp({
          url: '/api/productInfo/otherOptions/get',
          params: {
            goodsSn,
            themeTypeStr
          },
        }).then((res) => {
          resolve(res)
        })
      })
    },
    /**
     * 显示登陆弹窗
     */
    showLogin(addToWish) {
      QuickViewPopover.hide()

      this.showLoginModal({
        type: 'qv-add-wish',
        originRef: {
          addToWish,
        },
      })
    },

    /**
     * 显示快速加车弹窗
     * @param {Object} data 商品数据
     */
    async showQuickviewModal(data, target) {
      // this.quickviewModal.index = data.slideIndex + 10
      let self = this
      this.opIndex = data.slideIndex + 1
      const imgRatio = await getImageRatio(data?.goods_img)
      const mainGoodsId = this.productIntroData?.detail?.goods_id
      QuickViewPopover.show({
        goodsId: data.goods_id,
        mainGoodsId,
        imgRatio,
        mallCode: data.mall_code,
        config: {
          addSource: 'DetailPage',
          showBestDealLabel: true,
          showFollowBeltByOrigin: true,
        },
        callback: {
          handleOpenLogin: this.showLogin,
          // 埋点上报
          handleOpenQuickViewDone: this.clickQuickEvent(data, target),
          handleChangeLocalCountry: (country) => {
            this.assignState({ country })
          },
          handleAddToCartDone(res){
            window.cart_module && window.cart_module.recart(res)
            self.autoGetCouponAtAddBag({ needToast: res?.code == 0, isMainGoods: mainGoodsId == data.goods_id })
          },
        },
        analysis: {
          daIdConfig: {
            viewFullDetail: '2-8-6',
          },
          pageType: '详情页',
          sourceTarget: target,
          index: this.opIndex || data.slideIndex + 10,
          sa: {
            code: 'otherOptions',
            activity_from: 'other_options',
          },
          ga: {
            eventCategory: '推荐列表',
            moduleName: 'recommend',
            moduleTitle: 'OtherOptions',
          },
        },
      })
    },
    clickQuickEvent(data, target) {
      daEventCenter.triggerNotice({
        daId: '1-8-1-1',
        extraData: {
          goodsId: data.goods_id,
          spu: data.relation_id || data.goods_sn,
          sku: data.goods_sn,
          catId: data.cat_id,
          price: data.salePrice.usdAmount,
          position: `100${data.slideIndex}`,
        },
      })
      daEventCenter.triggerNotice({
        daId: '2-3-5',
        target: target,
        extraData: {
          goodsListParams: {
            sku: data.goods_sn,
            spu: data.productRelationID || data.goods_sn,
            goodsId: data.goods_id,
            originPrice: data.retailPrice.usdAmount,
            price: data.salePrice.amount,
            mallCode: data.mall_code,
            index: data.slideIndex,
          },
          tab_list: '',
          need_tab_list: true
        },
      })
    },
    /**
     * 曝光轮播图中商品、曝光缓存
     * @param {Object} goods 曝光商品列表
     * @param {Number} index slider 页数
     * @param {String} uniqueName slider 唯一标识
     */
    esposeProduct(goods, index, uniqueName) {
      if (!this.commonSlider.exposedIndex[uniqueName]) {
        this.commonSlider.exposedIndex[uniqueName] = []
      }

      if (this.commonSlider.exposedIndex[uniqueName].includes(index)) {
        return
      }

      this.commonSlider.exposedIndex[uniqueName].push(index)
    },

    /**
     * 轮播图滚动事件
     * @param {Object} data { swiper=实例, index=页, goods=商品信息 }
     */
    slide(data) {
      // 滑块默认会展示下一页的半个，
      const realPerViewCount =
        data.index < 1
          ? this.commonSlider.perViewCount + 1
          : this.commonSlider.perViewCount

      const realIndex =
        data.index < 1 ? 0 : data.index * this.commonSlider.perViewCount + 1
      const exposeGoods = data.goods.slice(
        realIndex,
        realIndex + realPerViewCount
      )

      this.esposeProduct(exposeGoods, data.index, data.uniqueName)
    },

    /**
     * 初始化 slider 所需的数据、初次曝光
     */
    initSlider() {
      this.optionsDataLoaded.map((item, index) => {
        // 此处规则为初次曝光将半个商品一起曝光，后续按照正常的数量曝光
        const goods = item.goods
          .slice(0, this.commonSlider.perViewCount + 1)
          .map((item, index) => {
            item.slideIndex = index
            return item
          })
        const uniqueName = `${this.commonSlider.uniqueNamePrefix}${index}`

        this.esposeProduct(goods, 0, uniqueName)
      })
    },
  }
}
</script>

<style lang="less">
.product-intro {
  &__otheroptions-head {
    position: relative;
    font-size: 16px;
    height: 47px;
    line-height: 47px;
    font-weight: 700;
    text-transform: capitalize;
    i {
      position: absolute;
      .right(0);
      font-size: 16px;
    }
  }
  &__otheroptions-item {
    & + .product-intro__otheroptions-item {
      margin-top: 15px;
    }
  }
}
</style>
