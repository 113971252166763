<template>
  <div class="matching-styles-slider">
    <div
      class="s-swiper-container matching-styles-swiperv2 matching-styles-swiperv2_btm"
    >
      <div
        v-show="activeIndex !== 0"
        class="common-swiperv2__prev"
        @click="handleArrowBtn('left')"
      >
        <Icon
          name="sui_icon_more_left_18px"
          size="16px"
          :is-rotate="constantData.GB_cssRight"
        />
      </div>

      <swiper-container 
        ref="swiper"
        init="false"
        class="matching-styles-swiper-wrapper s-swiper-wrapper"
      >
        <swiper-slide
          v-for="(slideItem, slideIndex) in goodsArr"
          :key="`slideItem__${slideIndex}`"
          v-expose="getAnlysis('1-8-1-4', { slideItem, slideIndex })"
          class="s-swiper-slide"
        >
          <div class="matching-styles-grid-container">
            <div
              v-for="(item,index) in slideItem"
              :key="`item__${index}`"
            >
              <s-product-item-imgs
                ref="imgcontainer"
                :no-fsp-class="true"
                :config="producItemShowConfig"
                :goods-info="getGoodsInfo(item)"
                :language="itemLanguage"
                :is-rw="constantData.IS_RW"
                :index="index"
                :lazy-img="constantData.LAZY_IMG"
                :css-right="constantData.GB_cssRight"
                :forbid-jump-detail="true"
                :relate-color-info="null"
                :item="item[0].goods[0]"
                @click-item="handleClickItem($event, item[0].goods[0],slideIndex, index)"
                @focus="hovering = true"
              >
                <template #imgBottom>
                  <div 
                    class="S-product-item__imgbottom-label 
                  matching-styles__imgbottom-label_12PX 
                  S-product-item__imgbottom-label_black 
                  S-product-item__imgbottom-label_center"
                  >
                    <span 
                      class="imgbottom-label__text imgbottom-label__text_12PX"
                    >{{ getItemsCount(item) }} {{ language.SHEIN_KEY_PC_21939 }}</span>
                  </div>
                </template>
              </s-product-item-imgs>
              <div class="matching-styles-theme-text">
                {{ item[0].theme_name }}
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
      <div
        v-show="activeIndex !== goodsArr.length - 1"
        class="common-swiperv2__next"
        @click="handleArrowBtn('right')"
      >
        <Icon
          name="sui_icon_more_right_18px"
          size="16px"
          :is-rotate="constantData.GB_cssRight"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import itemMixin from 'public/src/pages/components/productItem/mixin'
import i18n from 'public/src/pages/common/biz_helper/i18n'
const { LAZY_IMG, PUBLIC_CDN, lang } = gbCommonInfo
import SProductItemImgs from 'public/src/pages/components/productItem/components/ProductItemImgs.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { abtservice } from 'public/src/services/abt'
import { register } from 'swiper/element'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()

export default {
  name: 'ProductIntroMatchingStylesItem',
  components: { SProductItemImgs, Icon },
  mixins: [itemMixin],
  props: {
    daEventClick: {
      type: String,
      default: ''
    },
    goodsArr: {
      type: Array,
      default() {
        return []
      }
    },
    isNormal: {
      type: Boolean,
      default: true
    },
    isGetlook: {
      type: Boolean,
      default: false
    },
    isOtheroptions: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Number,
      default: 0
    },
    slidesPerGroup: {
      type: Number,
      default: 5
    },
    themeName: {
      type: String,
      default: ''
    },
    siteMallInfo: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      arr: [1, 2, 3, 4],
      LAZY_IMG,
      PUBLIC_CDN,
      lang,
      goodsItemInfo: null,
      producItemShowConfig: {
        showLikeBtn: false,
        showPlusSize: true,
        showSeriesBrand: true,
        hideGoodsName: true,
        hideOperationBtn: true,
        disableMainimgJump: true,
        useOwnClickExposeAnalysis: true,
        showNewFlashPriceOnSale: true,
        showSheinClubDiscountValue: true,
        hideAllBottomPrices: true,
        hideDiscountBadge: true, // 隐藏默认的折扣标签
        showDiscountLabelAfterPrice: true, // 折扣展示在价格右边
        hideRetailPrice: true, // 隐藏划线价
      },
      swiperInstance: null,
      activeIndex: 0
    }
  },
  computed: {
    ...mapGetters(['language']),
    slideItemClass() {
      return {
        'common-swiperv2__slide-normal': this.isNormal,
        'common-swiperv2__slide-getlook': this.isGetlook,
        'common-swiperv2__other-options': this.isOtheroptions
      }
    }
  },
  async mounted() {
    this.goodsItemInfo = new GoodsItemInfo({
      listLanguage: await i18n.loadByQueue('item')
    })
    this.goodsItemInfo.getProductInfo({
      goods: this.goodsArr,
      requestFields: {
        prices: true,
        seriesAndBrand: true,
        plusSize: true,
        promotion: true,
        sheinClubPromotionInfo: true,
        newFlashPromotion: true,
        flashZonePromotion: true,
      }
    })
    const abtInfo = await abtservice.getUserAbtResult({
      newPosKeys: 'discountLabel,greysellingPoint',
    })
    const showNewDiscountLabel = abtInfo?.['discountLabel']?.param?.['discountLabel'] == 'Label'
    const showGreySellingPoint = abtInfo?.['greysellingPoint']?.param?.['greysellingPoint'] == 'grey'
    this.producItemShowConfig = {
      ...this.producItemShowConfig,
      showNewDiscountLabel,
      showGreySellingPoint,
      configReady: true
    }

    Vue.nextTick(() => {
      const swiperEl = this.$refs.swiper
      if (!swiperEl) return

      const swiperParams = {
        observer: true,
        observeParents: true,
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        spaceBetween: 10,
        on: {
          slideChangeTransitionEnd: (swiper) => {
            this.activeIndex = swiper.realIndex
          }
        }
      }

      if (this.autoplay) {
        swiperParams.autoplay = this.autoplay
        swiperParams.speed = 1000
        swiperParams.autoplayDisableOnInteraction = false
      }

      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.swiperInstance = swiperEl.swiper
    })
  },
  methods: {
    /**
     * 点击左右箭头
    */
    handleArrowBtn(direction) {
      if (direction === 'left') {
        this.swiperInstance.slidePrev()
      } else if (direction === 'right') {
        this.swiperInstance.slideNext()
      }
    },
    getAnlysis(id, { slideItem, slideIndex }) {
      return {
        id,
        code: 'cartAddItem',
        data: {
          item: slideItem,
          index: slideIndex
        },
      }
    },
    getItemsCount(item) {
      return item.reduce((prev, cur) => {
        return prev + (cur?.goods.length || 0)
      }, 0)
    },
    getGoodsInfo(item) {
      if (item[0].series_img) {
        item[0].goods[0].goods_img = item[0].series_img 
      }
      return  item[0].goods[0]
    },
    handleClickItem({ target }, item, slideIndex, index) {
      this.clickItem( { ...item, target, slideIndex, index })
    },
    clickItem(arg) {
      this.$emit(
        'tapItem',
        Object.assign({}, arg),
        arg.target
      )
    },
    /**
     * 单击滑动项
     */
    tapItem(item, $event) {
      this.$emit(
        'tapItem',
        Object.assign({}, item),
        $event.currentTarget
      )
    },
  },
  emits: ['slide', 'tapItem']
}
</script>

<style lang="less">
.s-swiper-container{
  position: relative;
}
// .common-slider {
//   .common-swiperv2 {
//     //swiper container 宽度占最外层100%时滑块的宽度
//     &__slide-normal {
//       width: 18.4166666%;
//     }

//     //新商详get the look滑块所占宽度
//     &__slide-getlook {
//       width: 29.94945799%;
//     }

//     //新商详Curve+Plus & Family Look 滑块所占宽度
//     &__other-options {
//       width: 35.714285%;
//     }
//   }

//   &__item {
//     // height: 50%;
//     margin-right: 10px;
//   }
// }

.matching-styles {
  &-grid-container {
    display: grid;
    grid-template-rows: auto auto; /* 两行 */
    grid-template-columns: repeat(3, 1fr); /* 三列 */
    grid-gap: 16px 10px; /* 可选：设置网格项之间的间距 */
    .S-product-item__img-container > img {
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
  }
  &__imgbottom-label_12PX  {
    line-height: 28px;
  }
  &-theme-text {
    margin-top: 11px;
    font-size: 14px;
    color: #000;
  }
}
</style>
