<template>
  <div class="common-video-player">
    <video
      ref="video"
      preload
      muted
      :controls="controls"
      disablePictureInPicture
      :src="url"
    ></video>
    <!-- 自定义播放器UI -->
    <slot
      name="player-ui"
      :el="video"
      :paused="paused"
      :duration="formatDuration"
      :current-time="formatCurrentTime"
      :progress="playProgress"
      :info="info"
      :set-progress="setProgress"
      :handle-progress-drag-start="handleProgressDragStart"
      :handle-progress-drag-end="handleProgressDragEnd"
    ></slot>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  reactive,
} from 'vue'
export default defineComponent({
  name: 'CommonVideoPlayer',
  props: {
    url: {
      type: String,
      default: ''
    },
    controls: {
      type: Boolean || String,
      default: true
    }
  },
  emits: ['loaded', 'error'],
  setup(props, { emit }) {
    const video = ref(null)
    const paused = ref(true)
    const info = reactive({
      currentTime: 0,
      duration: 0,
      isDragging: false
    })

    /**
     * @description 播放进度
     */
    const playProgress = computed(() => {
      return (info.currentTime / info.duration) * 100
    })
    const formatDuration = computed(() => {
      return formatTime(info.duration || 0)
    })
    const formatCurrentTime = computed(() => {
      return formatTime(info.currentTime || 0)
    })

    onMounted(() => {
      video.value?.addEventListener('canplay', () => {
        emit('loaded', true)
      })
      video.value?.addEventListener('play', function () {
        paused.value = false
      })
      video.value?.addEventListener('pause', function () {
        paused.value = true
      })
      video.value?.addEventListener('durationchange', () => {
        info.duration = video.value?.duration
      })
      video.value?.addEventListener('timeupdate', () => {
        if(info.isDragging) return
        info.currentTime = video.value?.currentTime
      })
      video.value?.addEventListener('error', () => {
        emit('error', false)
      })
    })

    function setProgress(progress) {
      info.isDragging = true
      info.currentTime = progress
    }

    function playVideo() {
      return new Promise((resolve, reject) => {
        try {
          video.value?.play()
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    }

    function pauseVideo() {
      return new Promise((resolve, reject) => {
        try {
          video.value?.pause()
          resolve()
        } catch (error) {
          reject(error)
        }
      })
      
    }

    function handleProgressDragStart() {
      info.isDragging = true
      video.value?.pause()
    }

    function handleProgressDragEnd() {
      info.isDragging = false
      if (video.value) {
        video.value.currentTime = info.currentTime
        video.value.play()
      }
    }

    function formatTime(seconds) {
      const format = val => `0${Math.floor(val)}`.slice(-2)
      const hours = seconds / 3600
      const minutes = (seconds % 3600) / 60
      seconds = seconds % 60
      if (hours < 1) {
        return [minutes, seconds].map(format).join(':')
      }
      return [hours, minutes, seconds].map(format).join(':')
    }

    return {
      props,
      video,
      playVideo,
      pauseVideo,
      info,
      paused,
      formatDuration,
      formatCurrentTime,
      playProgress,
      setProgress,
      handleProgressDragStart,
      handleProgressDragEnd
    }
  }
})
</script>

<style lang="less">
.common-video-player {
  width: 100%;
  height: 100%;
  position: relative;

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
