<template>
  <div class="product-intro__main-loading"> 
    <svg 
      class="loading-trace" 
      viewBox="0 0 50 50" 
      focusable="false"
    >
      <circle 
        cx="50%" 
        cy="50%" 
        r="20" 
      />
    </svg>
    <svg 
      class="loading-circle" 
      viewBox="0 0 50 50" 
      focusable="false"
    >
      <circle 
        cx="50%" 
        cy="50%" 
        r="20" 
      />
    </svg> 
  </div>
</template>
<script>
export default {
}
</script>
