<template>
  <div
    v-expose="{
      id: '2-21-1',
    }"
    v-tap="{
      id: '2-21-3',
    }"
    class="newGtl-tip"
    @click="openGetTheLookNewPopup"
  >
    <div
      v-for="(item, index) in tipGtlAndOutfitInfo"
      :key="index"
      class="item-box"
    >
      <img
        :src="transformImg({ img: item.series_img })"
        class="item-img"
      />
      <div
        v-if="moreNum && index === tipGtlAndOutfitInfo.length - 1"
        class="item-mask"
      >
        +{{ moreNum }}
      </div>
    </div>
    <div class="title">
      {{ language.SHEIN_KEY_PC_14591 }}
    </div>
    <Icon
      name="sui_icon_more_right_12px_1"
      size="12px"
      :is-rotate="GB_cssRight"
    />
  </div>
</template>

<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { usePreloadGetTheLookPopup } from 'public/src/pages/goods_detail_v2/components/RecommendRelatives/GetTheLookNew/preloadCompositionUtils.js'
import { transformImg } from '@shein/common-function'
const { GB_cssRight } = gbCommonInfo
usePreloadGetTheLookPopup()

defineProps({
  language: {
    type: Object,
    default: () => ({})
  },
  moreNum: {
    type: Number,
    default: 0
  },
  tipGtlAndOutfitInfo: {
    type: Array,
    default: () => ([])
  },
  openGetTheLookNewPopup: {
    type: Function,
    default: () => () => {}
  },
})

</script>

<style lang="less" scoped>
.newGtl-tip {
    position: absolute;
    bottom: 36px;
    right: 10px;
    white-space: nowrap;
    cursor: pointer;
    z-index: @zindex-hack;

    border-radius: 19px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    align-items: center;
    padding: 10px 16px;
    line-height: normal;

    .item-box {
      position: relative;
      margin-right: 6px;
    }

    .item-img {
        width: 21px;
        height: 21px;
        object-fit: cover;
    }

    .item-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.3);

      color: #FFF;
      font-size: 12px;
      font-weight: 510;
    }

    .title {
        font-size: 16px;
        font-weight: 700;
        color: #000;
        margin-right: 6px;
    }
}
</style>
