<template>
  <div class="store-recommend-list">
    <div 
      class="j-da-event-box"
      data-feed_type="2"
    >
      <div
        class="store-recommend-list__list"
      >
        <div
          ref="commonSwipervPrev"
          class="common-swiperv2__prev"
        >
          <Icon
            name="sui_icon_more_left_18px"
            size="18px"
            :is-rotate="constantData.GB_cssRight"
          />
        </div>
        <swiper-container
          ref="newSwiper"
          init="false" 
          class="s-swiper-wrapper store-swiper-remmendlist"
          destroy-on-disconnected="false"
          :da-expose-code="code"
        >
          <swiper-slide
            v-for="(item, index) in products"
            :key="index"
            class="s-swiper-slide store-recommend-list__list-item"
            :class="{
              'size_4_5': recommendSize == '4_5'
            }"
          >
            <s-product-item
              ref="goodsItem"
              :no-fsp-class="true"
              :no-img-lazy="index > 2 ? true : false"
              :index="index"
              :item="item"
              :language="language"
              :config="productItemShowConfig"
              :goods-item-info="goodsItemInfo"
              :constant-data="constantData"
              :bottom-info-style="{ minHeight: '0px' }"
              @click-add-to-bag="productClick"
              @open-quick-add="productClick"
              @click-item="productClick"
            >
              <template #imgTopLeft>
                <FlashProductDiscount
                  v-if="item.is_flash_sale > 0 && !isHitComplianceMode"
                  :item="item"
                />
              </template>
            </s-product-item>
            <img 
              v-show="loading" 
              :src="constantData.LAZY_IMG_SOLID_COLOR" 
            />
            <div 
              v-if="index == 10"
              class="store-recommend-list__last-cover"
            >
              <div 
                v-expose="exposeViewMore()"
                class="cover-btn-wrap"
                :data-is-store="true"
                :data-brand-code="storeInfo.id"
                :data-brand-type="storeInfo.type"
                :data-name="storeInfo.name"
                :data-designer-id="storeInfo.designerId"
                :data-id="storeInfo.id"
                :data-sc-id="storeInfo.scId"
                data-page-nm="page_goods_detail"
                da-event-click="1-8-6-174"
                @click="clickViewMore"
              >
                <Icon
                  name="sui_icon_viewmore_right_40px"
                  size="40px"
                  :is-rotate="constantData.GB_cssRight"
                />
                <span> {{ language.SHEIN_KEY_PC_19641 }}</span>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
        <div
          ref="commonSwipervNext"
          class="common-swiperv2__next"
        >
          <Icon
            name="sui_icon_more_right_18px"
            size="18px"
            :is-rotate="constantData.GB_cssRight"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SProductItem from 'public/src/pages/components/productItem/ProductItem.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import FlashProductDiscount from './FlashProductDiscount'

export default {
  components: {
    SProductItem,
    Icon,
    FlashProductDiscount,
  }
}
</script>
<script setup>
/**
 * @component EntryBox 商详页店铺、品牌入口盒子
 * * 依赖商详vuex
 */
import {
  ref,
  unref,
  toRefs,
  computed,
  nextTick,
  onMounted,
  // watch,
  onBeforeUnmount,
  reactive,
} from 'vue'
import { useStore } from 'vuex'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import i18n from 'public/src/pages/common/biz_helper/i18n'
import { stringifyQueryString } from '@shein/common-function'
import { getRecAbtResult } from 'public/src/services/productRecommend/components/utils.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getAtomicRequestFileds } from 'public/src/services/goodsItemInfo/utils.js'
import { extendsComplianceModeConfig } from 'public/src/pages/goods_detail_v2/utils/recommendConfigExtends.js'
import { register } from 'swiper/element'
import { Navigation } from 'swiper/modules'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()
let QuickViewPopover = {}
if (typeof window !== 'undefined') {
  import('public/src/pages/common/quickView').then(res => {
    QuickViewPopover = res.default
  })
}
const daEventExpose = daEventCenter.getExposeInstance()

/**
 * props
 */
const props = defineProps({
  // 店铺信息
  storeInfo: {
    type: Object,
    default: () => ({}),
  },
  // 是否为店铺类型
  // * 此按钮兼容品牌和系列的跳转行为
  isStore: {
    type: Boolean,
    default: true,
  },
  /**
   * 推荐列表尺寸
   */
  recommendSize: {
    type: String,
    default: ''
  },
})
const {
  storeInfo,
  isStore,
} = toRefs(props)

/**
 * data
 */
const newSwiper = ref(null)
const commonSwipervPrev = ref(null)
const commonSwipervNext = ref(null)
let swiperInstance = ref(null)
const goodsItem = ref(null)

let goodsItemInfo = ref(null)
let code = ref('storeRecommendExposeCode')
let initExpose = ref(false)
let exposeGoodsList = reactive({})

/**
 * computed
 */
const constantData = computed(() => {
  const { IMG_LINK, LAZY_IMG, LAZY_IMG_SQUARE, LAZY_IMG_SOLID_COLOR, GB_cssRight, IS_RW } = typeof window === 'undefined' ? {} : gbCommonInfo
  return {
    IMG_LINK, 
    LAZY_IMG, 
    LAZY_IMG_SQUARE, 
    LAZY_IMG_SOLID_COLOR,
    GB_cssRight, 
    IS_RW
  }
})
let productItemShowConfig = reactive({
  daEventExposeId: '1-8-6-175',
  showSheinClubDiscountValue: true,
  showPromoLabel: true,
  showSellingPoint: false,
  disableMainimgJump: true,
  showPromotion: true,
  hideOperationBtn: true,
  showSeriesBrand: true,
  showLocalSeller: true, // 本地卖家角标
  showNewFlashPrice: true, // 新型闪购价格
  hideDiscountBadge: true, // 隐藏默认的折扣标签
  showDiscountLabelAfterPrice: true, // 折扣展示在价格右边
  hideRetailPrice: true, // 隐藏划线价
  showBeltLabel: true, // 腰带
  showEstimatedPriceReachThreshold: true, // 到手价
  hideGoodsName: true,
  hidePromotionIcon: true,
  standardPrice: false // 合规模式
  // showAddBagAfterGoodsPrice: true, // 加入购物车按钮展示在价格右边
})


const vuexStore = useStore()
// 商详全局的折叠状态
const products = computed(() => vuexStore.getters.recommendProducts)    
const isHitComplianceMode = computed(() => vuexStore.getters.isHitComplianceMode)
// const storeRate = computed(() => vuexStore.state.storeRate)
const language = computed(() => vuexStore.state.language)
const productIntroData = computed(() => vuexStore.state.productIntroData)   



function productClick(product) {
  const { target } = product || {}
  if (product?.goods_id) {
    const { type, id, name, designerId, scId } = storeInfo.value || {}
    const brand_data = {
      brand_type: type,
      brand_code: id,
      page_nm: 'page_goods_detail',
      name,
      designerId,
      scId,
    }
    daEventCenter.triggerNotice({
      daId: '1-8-6-176',
      extraData: {
        target,
        brand_data
      }
    })
  }
  const goodsId = product?.goods_id || product?.item?.goods_id || ''
  const mainGoodsId = productIntroData.value?.detail?.goods_id
  goodsId && QuickViewPopover.show({
    goodsId,
    mainGoodsId,
    analysis: {
      sa: {
        feed_type: '2'
      }
    },
    callback: {
      handleOpenLogin: (addToWish) => {
        QuickViewPopover.hide()
        vuexStore.commit('showLoginModal', {
          type: 'qv-add-wish',
          originRef: {
            addToWish
          }
        })
      },
      handleAddToCartDone(res){
        window.cart_module && window.cart_module.recart(res)
        vuexStore.dispatch('autoGetCouponAtAddBag', { needToast: res?.code == 0, isMainGoods: mainGoodsId == goodsId })
      },
    }
  })
}

function setListExpose() {
  const { type, id, name, designerId, scId } = storeInfo.value || {}
  const data = {
    brand_type: type,
    brand_code: id,
    name,
    designerId,
    scId,
  }
  goodsItem.value &&
  goodsItem.value.forEach(goodsItem => {
    goodsItem.customizeElAttr([
      { attr: 'data-abtest', value: 'poskey`abtexp`abtbranch' },
      { attr: 'data-brand_type', value: type },
      { attr: 'data-brand_code', value: id },
      { attr: 'data-brand_name', value: name },
      { attr: 'data-designerId', value: designerId },
      { attr: 'data-scId', value: scId },
      { attr: 'data-flash_sale', value: goodsItem?.item.is_flash_sale > 0 ? 'flash_sale' : '' },
    ])
    goodsItem.setElAttr()
    if (!initExpose.value) {
      initExpose.value = true
      handleExpose()
    } else {
      updateExpose()
    }
  })
}
// 商品项曝光
function handleExpose() {
  daEventExpose.subscribe({
    keycode: `${code.value}\`${productItemShowConfig.daEventExposeId}`,
    type: 'list',
    middleJudge: target => {
      const goods_id = target.getAttribute('data-id')
      if (exposeGoodsList[goods_id]) {
        return false
      } else {
        exposeGoodsList[goods_id] = true
        vuexStore.dispatch('updateExposedList', goods_id)
        return true
      }
    }
  })
}

function updateExpose() {
  daEventExpose.update(code.value)
}

function initSwiper() {
  const swiperEl = newSwiper.value || document?.querySelector('.store-swiper-remmendlist')
  if (!swiperEl) return

  const swiperParams = {
    modules: [Navigation],
    navigation: {
      prevEl: commonSwipervPrev.value,
      nextEl: commonSwipervNext.value,
    },
    slidesPerGroup: 3,
    observer: true,
    slidesPerView: 3,
    spaceBetween: 10,
    observeParents: true, // 修改swiper的父元素时，自动初始化swiper,
  }
  Object.assign(swiperEl, swiperParams)
  swiperEl.initialize()
  swiperInstance.value = swiperEl.swiper
}

function clickViewMore(){
  const { type, name, designerId, id, link, scId, storeBusinessType } = storeInfo.value || {}
  let url = ''
  const common_params = {
    src_module: 'DetailBrand',
    src_identifier: [
      `on=${type}`,
      `cn=${name}`,
      `hz=0`,
      `ps=1_1`,
      `jc=${
        designerId
          ? `sheinxDesignerInformation_${designerId}`
          : isStore.value
            ? `thirdPartyStoreHome_${id}`
            : `itemPicking_${scId}`
      }`
    ].join('`'),
    src_tab_page_id: getSaPageInfo.tab_page_id || ''
  }
  // if (storeBusinessType == 2 && storeTab?.value) {
  //   // 品牌店铺 2 才有Tab， 需要跳转到指定Tab
  //   common_params.tab = storeTab.value
  // }
  common_params.tab = 'items'
  const split = !!~link.indexOf('?') ? '&' : '?'
  if (isStore.value) {
    const store_params = stringifyQueryString({
      queryObj: {
        // store_score: storeRate.value || 0,
        ici: 'PageGoodsDetail',
        rule_poskey: 'DetailShopItemList',
        ...common_params
      }
    })
    url = `${link}${split}${store_params}`
  } else {
    const brand_params = stringifyQueryString({ queryObj: common_params })
    url = `${link}${split}${brand_params}`
  }
  location.href = url
}


function exposeViewMore() {
  const { type, id, name, designerId, scId } = storeInfo.value || {}
  const data = {
    brand_type: type,
    brand_code: id,
    page_nm: 'page_goods_detail',
    name,
    designerId,
    scId,
  }
  return {
    id: '1-8-6-173',
    data
  }
}
// 商品项的展示配置
function initItemAbt() {
  return new Promise(resolve => {
    // 如果已经请求过abt，直接返回不用再次请求
    if (productItemShowConfig.configReady) return resolve()

    getRecAbtResult.fetchAbt((config) => {
      const { showFollowLabel, showSellingPoint, showQuickShip: abtShowQuickShip, showBadge, showNewDiscountLabel, showGreySellingPoint, showHorseRaceLabel, addRankingLabelToSwiper } = config || {}
      const { showQuickShip: itemQuickShipShow } = productItemShowConfig
      const showQuickShip = typeof itemQuickShipShow === 'undefined' ? abtShowQuickShip : itemQuickShipShow
      // configReady 都已经取到最终值，控制内部计算底部角标时机
      
      isHitComplianceMode.value && extendsComplianceModeConfig(productItemShowConfig)

      const extendsConfig = { 
        showFollowLabel,
        showNewDiscountLabel, 
        showGreySellingPoint, 
        showBadge, 
        showQuickShip, 
        showSellingPoint,
        showHorseRaceLabel,
        addRankingLabelToSwiper,
        configReady: true
      }

      productItemShowConfig = Object.assign({}, productItemShowConfig, extendsConfig) // TODO Rambo / Alex 这里不会响应式丢失吗？

      resolve()
    }, 'recommend-store-brand-series-list')
  })
}

async function initItemInfo() {
  await initItemAbt() // abt
  const language = (await i18n.loadByQueue('item')) || {}
  goodsItemInfo.value = new GoodsItemInfo({
    listLanguage: language
  })
  const requestFields = {
    ...getAtomicRequestFileds(productItemShowConfig, ['prices', 'topPick', 'plusSize', 'locateLabels', 'mallInfo', 'stock', 'horseRaceLabel']),
    realTimeTspLabels: { label_cloud: ['600020741'], machine_label: ['3120'] }, // 全端泛列表场景支持替换营销图主图展示
    detailImage: true, // 全端泛列表场景支持替换营销图主图展示
  }

  await goodsItemInfo.value.getProductInfo({
    goods: products.value,
    requestFields
  })
  setListExpose()
}

onMounted(() => {
  initItemInfo()
  nextTick(() => {
    initSwiper()
  })
})

onBeforeUnmount(() => {
  // 解除引用
  unref(newSwiper)
  unref(commonSwipervPrev)
  unref(commonSwipervNext)
})
</script>

<style lang="less">
.store-recommend-list{
  margin-top: 12px;
  .common-swiperv2__prev,
  .common-swiperv2__next {
    top: calc(50% - 10px);
  }
  .s-swiper-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    box-sizing: content-box;
  }
  .s-swiper-slide{
    flex-shrink: 0;
    margin-right: 10px
  }
  &__list{
    position: relative;
    overflow: hidden;
  }
  &__list &__list-item {
    width: 150px;
    position: relative;

    /* stylelint-disable-line */
    &.size_4_5 {
      width: 114px;
    } /* stylelint-disable-line */

    /* stylelint-disable-line */
    & > img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    // 老商卡组件内忽略了反语
    .S-product-item__wrapper-upper-left {
      left: 0;
    }
  }
  &__last-cover{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%);
    z-index: 1;
    top: 0;
    left: 0;
    .cover-btn-wrap{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: -32px;
      position: absolute;
      top: 100px;
      i{
        font-size: 40px;
      }
      span{
        margin-top: 4px;
        font-size: 12px;
        color: #222;
        font-weight: 700;
      }
    }
  }
}


</style>
