<template>
  <div class="product-intro__img-attr-group">
    <div class="product-intro__img-attr-title">
      {{ featureUnit.name }}
    </div>
    <!-- 属性图标集合 -->
    <div
      class="horizon-scroll-box"
      :style="{
        width: featureUnit.scrollWidth ? `${featureUnit.scrollWidth}px` : 'unset',
        transform: `matrix(1, 0, 0, 1, ${rtl ? '' : '-'}${attrBarXPos}, 0)`
      }"
    >
      <div
        v-for="(line, lineIndex) in featureUnit.lines"
        :key="lineIndex"
        class="product-intro__img-attr-list"
      >
        <div
          v-for="icon in line"
          :key="icon.attr_value_id"
          class="product-intro__img-attr-li"
        >
          <img :src="icon.attr_image" />
          <p v-if="icon.attr_value">
            {{ icon.attr_value }}
          </p>
          <p
            v-if="showAttrDesc && icon.attr_desc"
            class="desc"
          >
            {{ icon.attr_desc }}
          </p>
        </div>
      </div>
    </div>

    <!-- 左右滑动按钮 -->
    <template v-if="featureUnit.mode === 'scroll'">
      <div
        v-show="scrollState"
        class="product-intro__scroll-arrow left"
        @click="() => onAttrScrollBtnClick(false)"
      >
        <Icon
          name="sui_icon_more_left_12px_1"
          size="12px"
          :is-rotate="rtl"
        />
      </div>
      <div
        v-show="!scrollState"
        class="product-intro__scroll-arrow right"
        @click="() => onAttrScrollBtnClick(true)"
      >
        <Icon
          name="sui_icon_more_right_12px_1"
          size="12px"
          :is-rotate="rtl"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { matchSpecialFeatures } from '../utils/productDetail'
import { Icon } from '@shein-aidc/icon-vue3'

const { GB_cssRight } = gbCommonInfo

export default {
  name: 'PrdImageAttrGroup',
  components: { Icon },
  props: {
    group: {
      type: Object,
      default() {
        return {}
      },
    },
    parentBoxWidth: {
      type: Number,
      default: 420,
    }
  },
  data() {
    return {
      scrollState: false,
      rtl: !!GB_cssRight,
    }
  },
  computed: {
    // 是否展示属性描述
    showAttrDesc() {
      const { featureUnit } = this
      return matchSpecialFeatures(featureUnit.nameId)
    },
    // 转化的属性单元
    featureUnit() {
      const { group } = this
      const unit = {
        name: group[0]?.attr_name || '',
        nameEn: group[0]?.attr_name_en || '',
        nameId: group[0]?.attr_id || '',
        lines: [],
        mode: 'normal',
        scrollWidth: 0,
      }
      if (!group?.length) {
        return unit
      }

      const attrIconLength = group?.length || 0
      // 长度3以内，单行
      if (attrIconLength <= 3) {
        unit.lines.push([...group])
      } else if (attrIconLength < 6) {
        // 3 < length <= 6，第一行排3个
        unit.lines.push(
          group.slice(0, 3),
          group.slice(3),
        )
      } else {
        // 超过6，两行滚动
        const midCount = Math.ceil(attrIconLength / 2)
        unit.lines.push(
          group.slice(0, midCount),
          group.slice(midCount),
        )

        if (attrIconLength > 6) {
          unit.mode = 'scroll'
          unit.scrollWidth = midCount * (116 + 8) + 2
        }

      }
      return unit
    },
    // 属性栏左右滚动距离
    attrBarXPos() {
      const { featureUnit, scrollState, parentBoxWidth } = this
      const { mode = '', scrollWidth = 0 } = featureUnit
      if (mode === 'scroll' && scrollWidth) {
        return scrollState ? scrollWidth - parentBoxWidth - 8 : 0
      }

      return 0
    },
  },
  methods: {
    // 属性栏左右滚动按钮点击
    onAttrScrollBtnClick(toRight) {
      this.scrollState = !!toRight
      this.$forceUpdate()
    },
  }
}
</script>

<style lang="less" scoped>
  .product-intro {
    &__img-attr-group {
      min-width: 380px;
      max-width: 420px;
      font-size: 12px;
      line-height: 14px;
      color: #222;
      position: relative;
      overflow-x: hidden;
    }
  
    &__img-attr-title {
      margin: 0 0 10px;
      font-weight: 600;
    }
    &__img-attr-list {
      .flexbox;
      flex-wrap: wrap;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 16px;
      }
    }
    &__img-attr-li {
      box-sizing: border-box;
      width: 116px;
      padding: 2px 8px 8px;
      border: 0.5px solid @sui_color_gray_weak1;
      margin-right: 8px;

      &:first-child {
        margin-left: 0;
      }

      img {
        display: block;
        margin: 0 auto 2px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }
      p {
        text-align: center;
        .clamp(2);
      }

      & .desc {
        color: #767676;
        margin-top: 2px;
      }
    }

    .horizon-scroll-box {
      transition: all 0.2s;
    }

    &__scroll-arrow {
      position: absolute;
      top: calc(50% - 14px);
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.08);

      &.left {
        left: 8px;
      }
      &.right {
        right: 8px;
      }
    }
  }
</style>
