/**
 * 碰撞检测 & 修正原坐标
 * @param {*} rects
 * @param {*} points
 * @param {*} center
 * @param {*} collisionDistance
 * @returns
 */
const checkCollision = (
  rects = [],
  points = [],
  collisionDistance,
  center,
  lastPosition
) => {
  rects?.forEach((item, index, arr) => {
    points[index] = checkCollisionAction(
      item,
      index,
      arr,
      points,
      collisionDistance,
      center,
      lastPosition
    )
  })
  return points
}

const checkCollisionAction = (
  rectA,
  i,
  arr,
  points,
  collisionDistance,
  center,
  lastPosition
) => {
  let collision = false,
      position = null
  for (let j = i + 1; j < arr.length; j++) {
    const rectB = arr[j]
    const status = isCollision(rectA, rectB)
    if (status) {
      let overlapping = 0,
          distance = 0
      if (lastPosition === 'up' || rectB.y > center) {
        // 在下面 要上升
        // 谁在上，谁移动
        if (rectA.y < rectB.y) {
          // A 移动
          overlapping = lastPosition
            ? 0
            : Math.abs(rectA.y + rectA.height - rectB.y)
          distance = collisionDistance + overlapping
          rectA.y = rectA.y - distance
          points[i].y = points[i].y - distance
        } else {
          // B 移动
          overlapping = lastPosition
            ? 0
            : Math.abs(rectB.y + rectB.height - rectA.y)
          distance = collisionDistance + overlapping
          rectB.y = rectB.y - distance
          points[j].y = points[j].y - distance
        }
        position = 'up'
      } else if (lastPosition === 'down' || rectB.y <= center) {
        // 在上面 要下降
        // 谁在下，谁移动
        if (rectA.y > rectB.y) {
          overlapping = lastPosition
            ? 0
            : Math.abs(rectB.y + rectB.height - rectA.y)
          distance = collisionDistance + overlapping
          rectA.y = rectA.y + distance
          points[i].y = points[i].y + distance
        } else {
          overlapping = lastPosition
            ? 0
            : Math.abs(rectA.y + rectA.height - rectB.y)
          distance = collisionDistance + overlapping
          rectB.y = rectB.y + distance
          points[j].y = points[j].y + distance
        }
        position = 'down'
      }
      collision = true
    }
  }
  if (collision) {
    // 只要存在碰撞 就要重排
    checkCollision(arr, points, collisionDistance, center, position)
  }

  return points[i]
}

const isCollision = (rectA, rectB) => {
  return !(
    rectA.x + rectA.width < rectB.x ||
    rectB.x + rectB.width < rectA.x ||
    rectA.y + rectA.height < rectB.y ||
    rectB.y + rectB.height < rectA.y
  )
}

/**
 * 边界检测
 */
const checkBoundary = (rects, points, boundary) => {
  rects?.forEach((item, index) => {
    points[index] = checkBoundaryAction(item, index, points, boundary)
  })
  return points
}

const checkBoundaryAction = (react, index, points, boundary) => {
  const { x, y, width, height } = react
  const { top, right, bottom, left } = boundary
  let pointX = 0,
      pointY = 0
  if (x <= left) {
    pointX = left + width
  }
  if (x + width >= right) {
    pointX = right - width
  }
  if (y <= top) {
    pointY = top + height / 2
  }
  if (y + height >= bottom) {
    pointY = bottom - height
  }
  pointX && (points[index].x = pointX)
  pointY && (points[index].y = pointY)
  return points[index]
}

// 获取点击坐标
const getClickPosition = (canvas, evt) => {
  // 影响有：画布相对于视窗的位置，画布的margin，padding，border-width以及缩放
  let style = window.getComputedStyle(canvas, null)
  //宽高
  let cssWidth = parseFloat(style['width'])
  let cssHeight = parseFloat(style['height'])
  //各个方向的边框长度
  let borderLeft = parseFloat(style['border-left-width'])
  let borderTop = parseFloat(style['border-top-width'])
  let paddingLeft = parseFloat(style['padding-left'])
  let paddingTop = parseFloat(style['padding-top'])

  let scaleX = canvas.width / cssWidth // 水平方向的缩放因子
  let scaleY = canvas.height / cssHeight // 垂直方向的缩放因子

  let x = evt.clientX
  let y = evt.clientY

  let rect = canvas.getBoundingClientRect()
  x -= rect.left + borderLeft + paddingLeft // 去除 borderLeft paddingLeft 后的坐标
  y -= rect.top + borderTop + paddingTop // 去除 borderLeft paddingLeft 后的坐标

  x *= scaleX // 修正水平方向的坐标
  y *= scaleY // 修正垂直方向的坐标

  return { x, y }
}

/**
 * 获取曝光埋点
 * @param {*} canvas
 * @param {*} ratio
 * @param {*} points
 */
const getExposePoints = (canvas, points, rate, ratio) => {
  const { top: cTop, left: cLeft } = canvas.getBoundingClientRect()
  const exposePoints = []
  points.forEach((point) => {
    const rect = point.getTipsPositionPoint()
    const top = cTop + rect.y / ratio
    const left = cLeft + rect.x / ratio
    const right = cLeft + rect.x / ratio + rect.width / ratio
    const bottom = cTop + rect.y / ratio + rect.height / ratio
    const viewStatus = getPointInView({ top, left, right, bottom })
    if (viewStatus) {
      const { width, height } = getExposeArea(
        { top, left, right, bottom },
        { width: rect.width / ratio, height: rect.height / ratio }
      )
      const isExpose =
        (width * height * ratio) / ((rect.width * rect.height) / ratio) >= rate // 曝光有效性判断
      isExpose && exposePoints.push(point.key)
    }
  })
  // 有效曝光集合
  return exposePoints
}

/**
 * 判断元素是否在可视区域内
 * @param {*} element
 * @returns
 */
const getPointInView = (rect) => {
  const clientWidth = window.innerWidth || document.documentElement.clientWidth
  const clientHeight =
    window.innerHeight || document.documentElement.clientHeight
  const yInView = rect.top < clientHeight && rect.bottom > 0
  const xInView = rect.left < clientWidth && rect.right > 0
  return yInView && xInView
}

/**
 * 获取曝光面积
 * @param {Object} rect
 */
const getExposeArea = (rect, { width, height }) => {
  const { left, top, right, bottom } = rect
  const clientWidth = window.innerWidth || document.documentElement.clientWidth
  const clientHeight =
    window.innerHeight || document.documentElement.clientHeight
  const expose = { width: 0, height: 0 }

  if (right < width) {
    expose.width = right
  } else if (right > clientWidth) {
    expose.width = clientWidth - left
  } else {
    expose.width = right - left
  }

  if (bottom < height) {
    expose.height = bottom
  } else if (bottom > clientHeight) {
    expose.height = clientHeight - top
  } else {
    expose.height = bottom - top
  }

  return { ...expose }
}

const getPointRect = ({ x, y, width }) => {
  const distance = width / 2
  return {
    x: x - distance,
    y: y - distance,
    width: width,
    height: width,
  }
}

/**
 * 返回触摸边界
 * @param {*} x
 * @param {*} y
 * @param {*} offsetX
 * @param {*} offsetY
 * @param {*} containerWidth
 * @param {*} containerHeight
 * @returns
 */
const checkClose = (
  x,
  y,
  offsetX,
  offsetY,
  containerWidth,
  containerHeight
) => {
  const direction = []
  if (x - offsetX < 0) {
    direction.push('left')
  }
  if (x + offsetX > containerWidth) {
    direction.push('right')
  }
  if (y - offsetY < 0) {
    direction.push('top')
  }
  if (y + offsetY > containerHeight) {
    direction.push('bottom')
  }
  return direction
}

export {
  checkCollision,
  isCollision,
  checkBoundary,
  getClickPosition,
  getExposePoints,
  getPointRect,
  checkClose,
}
