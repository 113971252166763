<template>
  <div class="product-intro__description">
    <div
      class="product-intro__description-head"
      da-event-click="1-8-6-17"
      @click="handleFold"
    >
      <span>{{ language.SHEIN_KEY_PC_17998 }}</span>
      <Icon
        v-if="unfold.includes('Material')"
        name="sui_icon_min_16px"
        size="16px"
        class="head-icon"
      />
      <Icon
        v-else
        name="sui_icon_add_16px"
        size="16px"
        class="head-icon"
      />
    </div>
    <div
      v-show="unfold.includes('Material')"
      ref="descriptionPanel"
      class="product-intro__description-panel"
    >
      <div class="product-intro__description-table">
        <template v-for="(item, index) in materialConf.materialList">
          <div 
            v-if="item"
            :key="index"
            class="product-intro__description-table-item"
          >
            <div class="key">
              {{ item.name }}:
            </div>
            <div class="val">
              {{ item.attr_value }}
              <span dir="ltr">{{ calcValue(item) }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Icon } from '@shein-aidc/icon-vue3'


daEventCenter.addSubscriber({ modulecode: '1-8-6' })

export default {
  name: 'ProductIntroMaterial',
  components: { Icon },
  computed: {
    ...mapState(['productIntroData', 'unfold', 'language', 'showHeelHeight', 'cmInchInfo' ]),
    ...mapGetters(['pageComponents']),
    materialConf() {
      return this.pageComponents.materialConf
    },
    calcValue() {
      return (item) => {
        if(this.showHeelHeight) {
          let dimensionAttrInfo = this.productIntroData?.sizeInfoDes?.dimensionAttrInfo ?? []
          const heelHeightMap = dimensionAttrInfo.find(item => { // {attr_name:"Heel Height"attr_name_en:"Heel Height"},通过attr_name的值（多语言）去尺码表拿数据
            return item?.attr_name_en?.includes('Heel Height')
          })
          if(heelHeightMap?.attr_name?.includes(item.name)) {
            return  this.cmInchInfo
          }
        }
        return ''
      }
    }
  },
  mounted() {
    if (this.materialConf.materialList.length) {
      daEventCenter.triggerNotice({
        daId: '1-8-6-69',
      })
    }
  },
  methods: {
    ...mapMutations(['assignState']),
    /**
     * 收起展开
     */
    handleFold() {
      let updatedArray = []
      if (this.unfold.includes('Material')) {
        updatedArray = this.unfold.filter(item => item !== 'Material')
      } else {
        updatedArray = [...this.unfold, 'Material']
      }
      this.assignState({
        unfold: updatedArray
      })
    },
  },
}
</script>
